import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {formatDate} from '@angular/common';
import { Router } from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss']
})
export class DomainsComponent implements OnInit {
  device = 0;
  show_alert = 0;
  isNavbarCollapsed=true;
  has_domains = true;
  new_domains: any = [];

  @Input() domains:any = [];
  @Input() managed_domains:any = [];
  @Input() deputy_domains:any = [];
  @Output() selectDomain = new EventEmitter();
  @Output() createDomain = new EventEmitter();
  @Output() selectConsent = new EventEmitter();
  @Output() selectThird = new EventEmitter();
  @Output() selectPay = new EventEmitter();
  @Output() selectKeys = new EventEmitter();
  @Output() selectConsentForm = new EventEmitter();
  @Output() quickDomainCreated = new EventEmitter();

  currentDate: any;
  closeModal: any;
  failed = false;
  domain:any;
  domain_manage_requests:any;
  data_officer_requests:any;
  deputy_domain_manage_requests:any;
  err = false;
  domain_price:any;
  referred: any;
  profile_id: any;
  show_default_message = false;
  dfc: any;
  op: any;
  cfc: any;
  sd: any;
  
  addDomainForm = new FormGroup({
    company_name: new FormControl('', Validators.required),
    company_domain: new FormControl('', Validators.required),
  });
  @ViewChild('modalDataPay') modalDataPay: any;
  @ViewChild('closeModals') closeModals: any;
  modalReference : any;

  constructor(private router: Router, private apiService: ApiService, private modalService: NgbModal, private cookieService: CookieService) { }

  ngOnInit() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.apiService.getInitialMessage().subscribe(
      (result: any) => {
        if(result && result.length > 0){
          this.profile_id = result[0].id;
          if(result[0].show_default_message == 1){
            this.show_default_message = true;
          }
        }
      }, 
      error => console.log(error)
    );
    this.apiService.getDomains().subscribe(
      (result: any) => {
        if(result == ''){
          this.has_domains = false;
        }

      }, 
      error => console.log(error)
    );
    this.apiService.getMyEditorRequests().subscribe(
      (result: any) => {
        this.domain_manage_requests = result;
      },
      error => console.log(error)
    );
    this.apiService.getMyDeputyOfficerRequests().subscribe(
      (result: any) => {
        this.deputy_domain_manage_requests = result; 
      },
      error => console.log(error)
    );
    this.apiService.getMyDataOfficerRequests().subscribe(
      (result: any) => {
        this.data_officer_requests = result;
      },
      error => console.log(error)
    );
  }
  
  manageClick(domain: any){
    this.selectDomain.emit(domain);
  }
  consentClick(domain: any){
    this.selectConsent.emit(domain);
  }
  thirdClick(domain: any){
    this.selectThird.emit(domain);
  }
  payClick(domain: any){
    this.selectPay.emit(domain);
  }
  newDomain(){
    this.createDomain.emit();
  }
  keysClick(content:any, domain: any){
    if(!domain.domain_form_complete || !domain.other_parties || !domain.consent_form_complete){
      this.dfc = domain.domain_form_complete;
      this.op = domain.other_parties;
      this.cfc = domain.consent_form_complete;
      this.sd = domain;

      this.modalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'})
      this.modalReference.result.then((res:any) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res:any) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      });
    } else {
      this.selectKeys.emit(domain);
    }
  }
  consentFormClick(domain: any){
    this.router.navigate(['/consent_form/'+domain.id]);
  }
  addEditorClick(domain: any){
    this.router.navigate(['/add_editor/'+domain.id]);
  }
  triggerModal(content:any) {
    this.modalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'})
    this.modalReference.result.then((res:any) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res:any) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  save(){
    if(this.addDomainForm.invalid){
      this.failed = true;
    } else {
      if(this.addDomainForm.value.company_domain.includes('https://')){
        this.domain = this.addDomainForm.value.company_domain;
      } else if(this.addDomainForm.value.company_domain.includes('http://')){
        this.domain = this.addDomainForm.value.company_domain;
      } else{
        this.domain = 'https://' + this.addDomainForm.value.company_domain;
      }
      this.apiService.getRefferedUser().subscribe(
        (result: any) => {
          if(result[0].referred_code){            
            const main_code = result[0].referred_code;
            this.referred = main_code[0] + main_code[1] + main_code[2] + main_code[3] + main_code[4] + main_code[5];
            this.apiService.getResellersPrices(this.referred).subscribe(
              (result: any) => {
                this.domain_price = result[0].popia_cust_price;
                this.update2();
              },
              error => {
                this.err = true;
              }
            )
          } else {
            this.apiService.getPrice().subscribe(
              (result: any) => {
                for(let child of result){
                  this.domain_price = child.value;
                  this.update2();
                }
              },
              error => {
                this.err = true;
              }
            )
          }
        },
        error => {
          this.err = true;
        }
      )
      
    }
  }
  update2(){
    this.apiService.addDomain(this.addDomainForm.value.company_name, this.domain, this.domain_price).subscribe(
      (result: any) => {
        this.quickDomainCreated.emit(result),
        this.err = false;
        this.new_domains.push(result.id);
        this.addDomainForm.reset();
      },
      error => {
        this.err = true;
      }
    )
  }
  finish(){
    if(this.addDomainForm.invalid){
      this.failed = true;
    } else {
      if(this.addDomainForm.value.company_domain.includes('https://')){
        this.domain = this.addDomainForm.value.company_domain;
      } else if(this.addDomainForm.value.company_domain.includes('http://')){
        this.domain = this.addDomainForm.value.company_domain;
      } else{
        this.domain = 'https://' + this.addDomainForm.value.company_domain;
      }
            
      this.apiService.getRefferedUser().subscribe(
        (result: any) => {
          if(result[0].referred_code != null){            
            const main_code = result[0].referred_code;
            this.referred = main_code[0] + main_code[1] + main_code[2] + main_code[3] + main_code[4] + main_code[5];
            this.apiService.getResellersPrices(this.referred).subscribe(
              (result: any) => {
                this.domain_price = result[0].popia_cust_price;
                this.update1();
              },
              error => {
                this.err = true;
              }
            )
          } else {
            this.apiService.getPrice().subscribe(
              (result: any) => {
                for(let child of result){
                  this.domain_price = child.value;
                  this.update1();
                }
              },
              error => {
                this.err = true;
              }
            )
          }
        },
        error => {
          this.err = true;
        }
      )
    }
  }
  update1(){
    this.apiService.addDomain(this.addDomainForm.value.company_name, this.domain, this.domain_price).subscribe(
      (result: any) => {
        this.quickDomainCreated.emit(result),
        this.err = false;
        this.new_domains.push(result.id);
        this.modalReference.close();
        this.modalService.open(this.modalDataPay);
        this.addDomainForm.reset();
      },
      error => {
        this.err = true;
      }
    )
  }
  response(id: any, val: any){
    let active;
    if(val == 0){
      active = 0;
    } else {
      active = 1;
    }
    this.apiService.editorRequestResponse(id, val, active).subscribe(
      () => {
        this.apiService.getMyEditorRequests().subscribe(
          (result: any) => {
            this.domain_manage_requests = result;
          },
          error => console.log(error)
        );
        this.managed_domains = [];
        this.apiService.getMyManageableDomains().subscribe(
          (data : any) => {
            for(let child of data){
              this.apiService.getDomain(child.domain_id).subscribe(
                res => {
                  this.managed_domains.push(res);
                },
                error => console.log(error)
              )
            }
          },
          error => console.log(error)
        )
      },
      error => {
        this.err = true;
      }
    )
  }
  responseDataOfficer(id: any, val: any, domain: any){
    let active;
    if(val == 0){
      active = 0;
    } else {
      active = 1;
      this.apiService.changeDomainOwnerID(domain).subscribe(
        (result: any) => {
          
        },
        error => console.log(error)
      );
    }
    this.apiService.dataOfficerRequestResponse(id, val, active).subscribe(
      () => {   
        const email = this.cookieService.get('bpc-email');     
        this.apiService.getEditorPK(domain, email).subscribe(
          (res: any) => {
            if(res.length > 0){
              const id = res[0].id;    
              this.apiService.editorRequestResponse(id, val, 0).subscribe(
                () => {
                },
                error => console.log(error)
              )
            }
          },
          error => console.log(error)
        ) 
        this.apiService.getMyDataOfficerRequests().subscribe(
          (result: any) => {
            this.data_officer_requests = result;
          },
          error => console.log(error)
        );
        this.apiService.getDomains().subscribe(
          data => {
            this.domains = data;
          },
          error => console.log(error)
        )
      },
      error => {
        this.err = true;
      }
    )
  }
  responseDeputyOfficer(id: any, val: any, domain: any){
    let active;
    if(val == 0){
      active = 0;
    } else {
      active = 1;  
      const id = this.cookieService.get('bpc-id');
      this.apiService.getDomainDeputyOfficers(domain).subscribe(
        (result: any) => {
          let officers;
          if(!result.deputy_officers){
            officers = result.deputy_officers + id;
          } else {
            officers = result.deputy_officers + ',' + id;
          }
          this.apiService.updateCompanyDeputyOfficers(domain, officers).subscribe(
            (result: any) => {
              this.deputy_domain_manage_requests = result;
            },
            error => console.log(error)
          );
        },
        error => console.log(error)
      );  
    }
    this.apiService.deputyOfficerRequestResponse(id, val, active).subscribe(
      () => {
        const email = this.cookieService.get('bpc-email');     
        this.apiService.getEditorPK(domain, email).subscribe(
          (res: any) => {
            if(res.length > 0){
              const id = res[0].id;    
              this.apiService.editorRequestResponse(id, val, 0).subscribe(
                () => {
                },
                error => console.log(error)
              )
            }
          },
          error => console.log(error)
        ) 
        this.apiService.getMyDeputyOfficerRequests().subscribe(
          (result: any) => {
            this.deputy_domain_manage_requests = result;
          },
          error => console.log(error)
        );
        this.managed_domains = [];
        this.apiService.getDomains().subscribe(
          data => {
            this.domains = data;
            this.apiService.getDeputyDomains().subscribe(
              (data: any) => {
                const id = this.cookieService.get('bpc-id');
                for(let child of data){
                  if(child.deputy_officers != null && child.deputy_officers.includes(id) && child.user != id){
                    this.deputy_domains.push(child);
                  }
                }
              },
              error => console.log(error)
            )
          },
          error => console.log(error)
        )
      },
      error => {
        this.err = true;
      }
    )
  }
  removeInitialMessage(){
    this.show_default_message = false;
    this.apiService.changeInitialMessage(this.profile_id).subscribe(
      () => {
      },
      error => console.log(error)
    )
  }
}
