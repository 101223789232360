<div [ngClass]="this.device == 1 ? 'layout_mobile' : ''">
    <button type="button" class="btn btn-danger" (click)="goBack()">Back</button>

    <div class="centerText"><br>
        <div class="alert alert-success centerText" id="success" *ngIf="this.success">
            Editor has been successfully invited.
        </div>
        <div class="alert alert-success centerText" id="success" *ngIf="this.editorRemoved">
            Editor has been successfully removed.
        </div>
        <h1>MANAGE ADDITIONAL EDITORS</h1>
        <p>
            Invite editors to <strong *ngIf="company">{{company.company_name}}</strong>.<br>
            Editors will have full access to <strong *ngIf="company">{{company.company_name}}</strong> apart from inviting more editors.
        </p>
        <p>
            By adding editors, you make it possible for them to:<br>
            - Make payments for <strong *ngIf="company">{{company.company_name}}</strong><br>
            - Edit or update information for <strong *ngIf="company">{{company.company_name}}</strong><br>
            - To gain access to the keys in order to setup WP Plugin on <strong *ngIf="company">{{company.company_domain}}</strong><br>
        </p>

        <button class="btn btn-danger" (click)="triggerModal(modalData)">INVITE</button><br><br><br>  

        <table class="table table-striped" *ngIf="this.editors.length > 0" [ngClass]="this.device == 0 ? '' : 'mobileTable'">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Date Added</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let editor of editors; index as i">
                    <td>
                        {{editor.name}} {{editor.surname}}
                    </td>
                    <td>
                        {{editor.email}}
                    </td>
                    <td>
                        {{editor.added}}
                    </td>
                    <td>
                        <span *ngIf="editor.status == 1" style="color: green;">Accepted</span>
                        <span *ngIf="editor.status == 0" style="color: #B61F21;">Pending</span>
                    </td>
                    <td>
                        <button class="btn btn-danger" (click)="triggerModal2(modalData2, editor)"><span *ngIf="this.device == 1">X</span><span *ngIf="this.device == 0">Remove Editor</span></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #modalData let-modal>
	<div class="modal-body" style="padding: 1rem 3rem 3rem 3rem!important;">
        <button type="button" style="float: right;" class="close" #closeModals aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button><br><br><br>
        <h3 class="centerText">Invite Person as Editor</h3>
        <p class="centerText">(This can be anyone you trust to perform actions on <strong *ngIf="company">{{company.company_name}}</strong> on your behalf)</p>
        
		<div class="alert alert-danger centerText" id="failed" *ngIf="this.errors">
			There has been an error inviting this editor. Please ensure you fill in the correct information and try again.
		</div>
        <form [formGroup]="inviteForm">
            <div>
                <label>Name</label>
                <input type="text" formControlName="name" class="roundedInputs">
            </div>
            <div>
                <label>Surname</label>
                <input type="text" formControlName="surname" class="roundedInputs">
            </div>
            <div>
                <label>Email</label>
                <input type="text" formControlName="email" class="roundedInputs">
            </div><br>
            <button class="btn btn-danger" style="float: right;" (click)="saveForm()">INVITE</button>
        </form>
	</div>
</ng-template>

<ng-template #modalData2 let-modal>
	<div class="modal-body" style="padding: 1rem 3rem 3rem 3rem!important;">
        <button type="button" style="float: right;" class="close" #closeModals aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button><br><br><br>
        <h3 class="centerText">Are you sure you want to remove <span style="color: #B61F21;">{{modalContent.name}} {{modalContent.surname}}</span> as a editor on <span style="color: #B61F21;">{{company.company_name}}</span></h3>
        <p class="centerText">This editor will no longer be able to make payments, edit, update, or gain access to the keys for the WP Plugin</p>
        <div class="centerText">
            <button class="btn btn-danger" style="background: #B61F21!important;border-color: #B61F21!important;" (click)="removeEditor(modalContent.id)">Remove Editor</button>
        </div>
	</div>
</ng-template>