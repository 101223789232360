<div [ngClass]="this.device == 1 ? 'layout_mobile' : ''">
    <h1 class="centerText">Referrals</h1>
    
    <div class="alert alert-danger centerText" id="failed" *ngIf="this.email_used">
        <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.email_used = false"></i>
        You have already sent a referral code to this email address. Please enter a different email address to send a new referral code.
    </div>
    <div class="alert alert-danger centerText" id="failed" *ngIf="this.empty_email">
        <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.empty_email = false"></i>
        Please enter a valid email address.
    </div>
    <div class="alert alert-success centerText" id="failed" *ngIf="this.successfully_sent">
        <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.successfully_sent = false"></i>
        A referral code has been sent successfully.
    </div>
    <div style="width: 30%;margin: auto;">
        <div class="keys">
            <label style="margin-left: 0px;">Tell a friend how Be POPIA Compliant is helping you, and how easy it is to set things up.<br><br>Send to their Email</label>
            <div>
                <form [formGroup]="referForm">
                    <input type="email" class="roundedInputs" formControlName="email" placeholder="email@example.com">
                    <button class="btn btn-danger" (click)="sendReferral()">Send</button>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="formatted_referrals && formatted_referrals.length > 0">
        <table class="table table-striped" [ngClass]="this.device == 0 ? '' : 'mobileTable'" style="margin-top: 50px;">
            <thead>
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Date Referred</th>
                    <th scope="col">Status</th>
                    <!-- <th scope="col">Amount</th> -->
                    <!-- <th scope="col"></th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let referral of formatted_referrals">
                    <td>
                        {{referral.referred}}
                    </td>
                    <td>
                        {{referral.time}}
                    </td>
                    <td>
                        <span *ngIf="referral.status == 3" style="color: red;">Suspended</span>
                        <span *ngIf="referral.status == 2" style="color: green;">Active</span>
                        <span *ngIf="referral.status == 1" style="color: green;">Registered</span>
                        <span *ngIf="referral.status == 0" style="color: blue;">Allocated</span>
                    </td>
                    <!-- <td>
                        <span *ngIf="referral.payment">
                            R{{referral.payment}}
                        </span>
                    </td> -->
                    <!-- <td>
                        <button class="btn btn-danger"><span *ngIf="this.device == 1">X</span><span *ngIf="this.device == 0">Remove Editor</span></button>
                    </td> -->
                </tr>
            </tbody>
        </table>
    
        <div style="width: 33%;float: right;">
            <p>
                <span style="color: blue;font-weight: 600;">Allocated:</span> 
                This indicated that the user used your referral, and visited the site, but no subscription is active yet.
            </p>
            <p>
                <span style="color: green;font-weight: 600;">Active:</span> 
                This indicates that the user now also have an active subscription with BPC.
            </p>
            <p>
                <span style="color: red;font-weight: 600;">Suspended:</span> 
                This indicates that a prior subscription that this user had has expired.
            </p>
        </div>
    </div>
</div>