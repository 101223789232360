<div [ngClass]="this.device == 1 ? 'layout_mobile' : ''">
    
    <button type="button" class="btn btn-danger" (click)="goBack()" *ngIf="!domain">Back</button>
    <h1 class="centerText">Company Keys</h1>
	<div class="alert alert-warning centerText">
		You will have to add these keys into your Be POPIA Compliant WordPress Plugin.<br>
        This can be found to the bottom left of your Admin Panel Menu.<br>
        This will activate the Pro version of our plugin.
	</div>
    <div class="keys">
        <label>
            Your API Key is :
        </label>
        <div>
            <input [disabled]="disabled" value="{{api_key}}" class="roundedInputs" [ngClass]="this.device == 1 ? 'keys_mobile' : ''">
            <br *ngIf="this.device == 1">
            <button class="btn btn-danger" [ngClass]="this.device == 1 ? 'buttons_mobile' : ''" (click)="copyApiKey()">Copy Key</button>
        </div>
    </div>
    <div class="keys">
        <label>
            Your Domain Key is :
        </label>
        <div>
            <input [disabled]="disabled" value="{{company_key}}" class="roundedInputs" [ngClass]="this.device == 1 ? 'keys_mobile' : ''">
            <br *ngIf="this.device == 1">
            <button class="btn btn-danger" [ngClass]="this.device == 1 ? 'buttons_mobile' : ''" (click)="copyCompanyKey()">Copy Key</button>
        </div>
    </div><br><br>
    <div class="centerText">
        <a href="{{company_domain}}/wp-admin/admin.php?page=be_popia_compliant" target="_blank"><button class="btn btn-danger">Insert Your Keys Here</button></a>
    </div>
</div>