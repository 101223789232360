import { ViewConsentComponent } from './view_consent/view_consent.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


import { Routes, RouterModule } from '@angular/router';
import { FaqComponent } from './faq/faq.component';
import { FooterComponent } from './footer/footer.component';
import { WordpressComponent } from './wordpress/wordpress.component';
import { CustomDevComponent } from './custom-dev/custom-dev.component';
import { PopiActComponent } from './popi-act/popi-act.component';
import { ContactComponent } from './contact/contact.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ShowStatsComponent } from './show-stats/show-stats.component';


const routes: Routes = [
  {path: 'main', component: MainComponent},
  {path: 'custom_dev', component: CustomDevComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'wordpress', component: WordpressComponent},
  {path: 'popi_act', component: PopiActComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'view_consent/:domain/:email/:secret', component: ViewConsentComponent},
  {path: 'asobpc2022', component: ShowStatsComponent},
];

@NgModule({
  declarations: [
    MainComponent,
    FaqComponent,
    WordpressComponent,
    CustomDevComponent,
    PopiActComponent,
    FooterComponent,
    ContactComponent,
    ViewConsentComponent,
    ShowStatsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    
    RouterModule.forChild(routes),
    
    GooglePlaceModule
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
})
export class MainModule { }
