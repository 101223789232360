import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-quick-pay',
  templateUrl: './quick_pay.component.html',
  styleUrls: ['./quick_pay.component.scss']
})
export class QuickPayComponent implements OnInit {
  yearly_security:any;
  monthly_security:any;
  formVaild = true;
  name_first: any;
  name_last: any;
  email:any;
  cell: any;
  id:any;
  price:any = 200;
  device = 0;
  domain_count: any;
  referred: any;
  domain_price: any;
  url = 'https://www.payfast.co.za/eng/process';
  
  @Input() domains: any;

  default_monthlyCost: any = 200;
  default_yearlyCost: any = 2000;

  discount: any = 0;

  monthlyCost: any = 200;
  yearlyCost: any = 2000;

  constructor(private apiService: ApiService, private cookieService: CookieService) { }

  ngOnInit(): void {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    
    this.apiService.getRefferedUser().subscribe(
      (result: any) => {
        if(result[0].referred_code){            
          const main_code = result[0].referred_code;
          this.referred = main_code[0] + main_code[1] + main_code[2] + main_code[3] + main_code[4] + main_code[5];
          this.apiService.getResellersPrices(this.referred).subscribe(
            (result: any) => {
              this.price = result[0].popia_cust_price;
              this.values();
            },
            error => console.log(error)
          )
        } else {
          this.apiService.getPrice().subscribe(
            (result: any) => {
              for(let child of result){
                this.price = child.value;
                this.values();
              }
            },
            error => console.log(error)
          )
        }
      },
      error => console.log(error)
    )

  }
  values(){
    this.domain_count = this.domains.length;
    this.id = this.domains.toString();

    if(this.price != this.default_monthlyCost){
      this.discount = (this.price / 200) * 100; 
      this.discount = 100 - this.discount;
    }
    
    this.default_monthlyCost = this.default_monthlyCost * this.domains.length;
    this.default_yearlyCost = this.default_yearlyCost * this.domains.length;

    this.monthlyCost = this.price * this.domain_count;
    this.yearlyCost = (this.price * 10) * this.domain_count;

    this.apiService.getYearlySubID(this.id, this.yearlyCost).subscribe(
      (result: any) => {
        this.yearly_security = result.hash;
      },
      error => console.log(error)
    )
    this.apiService.getMonthlySubID(this.id, this.monthlyCost).subscribe(
      (result: any) => {
        this.monthly_security = result.hash;
      },
      error => console.log(error)
    )
    this.apiService.getProfile().subscribe(
      (res: any) => {
        const result = res[0];
        this.name_first = result.data_officer_first_name;
        this.name_last = result.data_officer_surname;
        this.email = result.data_officer_direct_email;
        this.cell = result.data_officer_cell;
      },
      error => console.log(error)
    )
  }

}
