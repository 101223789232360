import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuickPayComponent } from './quick_pay/quick_pay.component';

import { CompanyKeysComponent } from './company_keys/company_keys.component';

import { SuccessComponent } from './payment/success/success.component';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalComponent } from './portal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProfileComponent } from './profile/profile.component';
import { DomainsComponent } from './domains/domains.component';
import { ManageComponent } from './manage/manage.component';
import { ConsentComponent } from './consent/consent.component';
import { ThirdPartiesComponent } from './third_parties/third_parties.component';
import { PaymentComponent } from './payment/payment.component';
import { PayMoreComponent } from './paymore/paymore.component';
import { Routes, RouterModule } from '@angular/router';
import { ApiService } from '../api.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddEditorComponent } from './add-editor/add-editor.component';
import { AdminComponent } from './admin/admin.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { BulkComponent } from './bulk/bulk.component';

const routes: Routes = [
  {path: 'portal', component: PortalComponent},
  {path: 'portal/profile', component: ProfileComponent},
  {path: 'portal/referrals', component: ReferralsComponent},
  {path: 'portal/manage', component: ManageComponent},
  {path: 'portal/manage/:id', component: ManageComponent},
  {path: 'portal/consent', component: ConsentComponent},
  {path: 'portal/consent/:id', component: ConsentComponent},
  {path: 'add_editor/:id', component: AddEditorComponent},
  {path: 'portal/third_parties', component: ThirdPartiesComponent},
  {path: 'portal/third_parties/:id', component: ThirdPartiesComponent},
  {path: 'portal/paymore', component: PayMoreComponent},
  {path: 'portal/payment', component: PaymentComponent},
  {path: 'portal/payment/success', component: SuccessComponent},
  {path: 'portal/keys', component: CompanyKeysComponent},
  {path: 'portal/admin', component: AdminComponent},
  {path: 'portal/bulk', component: BulkComponent},
  {path: 'payment', pathMatch: 'full', redirectTo: 'payment'},
];

@NgModule({
  declarations: [
    PortalComponent,
    ProfileComponent,
    DomainsComponent,
    ManageComponent,
    ConsentComponent,
    PaymentComponent,
    SuccessComponent,
    PayMoreComponent,
    QuickPayComponent,
    CompanyKeysComponent,
    ThirdPartiesComponent,
    AddEditorComponent,
    AdminComponent,
    ReferralsComponent,
    BulkComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    ApiService,
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class PortalModule { }
