<div *ngIf="message != ''"  class="centerText">
    <h3>{{message}}</h3>
    <i class="icon-check-circle-o" [ngClass]="this.device == 1 ? 'success_mobile' : 'success'"></i>
</div>
<div class="layout" *ngIf="message == ''">
    <button id="printBtn" class="btn btn-danger" style="float: right;" (click)="printPage()">print</button>
    <form [formGroup]="consentForm" id="htmltable" class="consentForm" [ngClass]="this.device == 1 ? 'layout_mobile' : 'layout'" *ngFor="let choice of consentDetails" #htmltable>
        <h1 class="centerText" style="margin-bottom: 20px;">Consent form for {{choice.trading_name}}</h1>
        <div class="divs">
            <span class="formHeadings">
                A. PROCESSING OF PERSONAL INFORMATION
            </span>
            <ol>
                <li>We are required by law to obtain your consent if we want/need to process your personal information</li>
                <li>We need to process your info so that we can <span class="data">{{ choice.services }}</span></li>
                <li>Therefore, without your consent <span class="data">{{ consequences[choice.no_consent_consequences - 1].name }}</span></li>
                <li>None of the parties concerned shall store your information for the purposes of marketing and as such you will not receive marketing material from us.</li>
                <li>Any of the parties above shall only be allowed to market through the channels you select in section D.15</li>
            </ol>
        </div>
        <div class="divs">
            <span class="formHeadings">
                B. RESPONSIBLE PARTIES THAT IS REQUESTING CONSENT
            </span>
            <ol>
                <li><span class="data">{{ choice.trading_name }}</span></li>
                <li><span class="data">{{ other_parties_names }}</span><br>
                    that is contracted to perform the required request/s being any of the services listed in section A.2 above.
                </li>
                <li>Furthermore Be POPIA COMPLIANT ( also known as "Manage Consent") herewith reserve the right to manage your data on your behalf. The services offered by Be POPIA Compliant, manage Consent or any related services as listed on our <a href="https://manageconsent.co.za/#/main">website</a> is brought to you for your convenience. If you withdraw consent from our services, you will need to manually provide your consent to <span class="data">{{choice.company_domain}}</span> if they facilitate it.</li>
            </ol>
        </div>
        <div class="divs">
            <span class="formHeadings">
                C. WE ARE COMMITTED TO PROTECTING YOUR PRIVACY
                <ol>
                    The responsible parties mentioned in section (B) above is committed to protecting your privacy and recognises that it needs to comply with statutory requirements in collecting, processing, and distributing personal information. The Constitution of the Republic of South Africa provides that everyone has the right to privacy and the Protection of Personal Information Act 4 of 2013 (“POPIA”) includes the right to protection against unlawful collection, retention, dissemination and use of personal information. In terms of section 18 of POPIA, if personal information is collected, the above parties, as responsible parties, must take reasonably practicable steps to ensure that the data subject is made aware of the information being collected.
                </ol>
            </span>
        </div>
        <div class="divs">
            <span class="formHeadings">
                D. IN ACCORDANCE WITH POPIA, THE RESPONSIBLE PARTIES HEREWITH PROCESS THE FOLLOWING INFORMATION
            </span>
            <ol>
                <li>TYPE OF INFORMATION: <span class="data">{{ choice.information_types }}</span></li>
                <li>NATURE OF INFORMATION: Personal Information to process any of the services mentioned in section A.2.</li>
                <li>PURPOSE: Required for the purposes of performing a task as requested by the data subject.</li>
                <li>SOURCE: <span class="data">{{ data_obtained[choice.data_obtained - 1].name }}</span></li>
                <li>VOLUNTARILY/MANDATORY: 
                    <span class="data" *ngIf="choice.man_vol == 'vol'">The data is voluntarily provided to aid us with fullfilling our services.</span>
                    <span class="data" *ngIf="choice.man_vol == 'man'">The data is mandatory since we are unable to fullfill our services without it.</span>
                    <span class="data"> {{ choice.man_vol_reason }}</span>
                </li>
                <li>LEGAL REQUIREMENT: Protection of Personal Information Act 4 of 2013 | POPIA  -  https://manageconsent.co.za/popia.</li>
                <li>CONTRACTUAL REQUIREMENT: <span class="data">{{ required_reason[choice.required_reason - 1].name }}</span></li>
                <li>
                    CONSEQUENCES OF FAILURE TO PROVIDE: According to POPIA, no one is allowed to hold the data of any DATA SUBJECT without their consent, and that when held it should be held only for as long as it is required.
                    <span class="data">{{ consequences[choice.no_consent_consequences - 1].name }}</span>
                </li>
                <li>
                    CROSS BORDER TRANSFER: <span class="data">{{ choice.cross_border | titlecase }}</span> <span class="data" *ngIf="choice.cross_border == 'yes'">{{ choice.cross_border_reason }}</span>
                </li>
                <li>RECIPIENTS OF PERSONAL INFORMATION: Any of the parties mentioned in Section B, their employees or contractors may have access to the information you provide.</li>
                <li>ACCESS AND RIGHT TO AMEND: The data subject has the right to access information about them, held by <span class="data">{{ choice.trading_name }}</span>. The data subject also has the right to request that their data or for the data to be updated. Click on the link at the bottom of our website page to request your data.</li>
                <li>RIGHTS TO OBJECT: The data subject has the right to object to the use of his/her/its data. Please take note that if we do delete your data/account that you will no longer be able to engage with our company unless registering a new profile and providing consent again. Click on the link at the bottom of our website page to request the deletion of your data.</li>
                <li class="dontSplit">
                    COMPLAINTS: Complaints regarding the use of Personal Information may be directed to the <a href="https://bepopiacompliant.co.za/#/regulator/{{this.consentId}}">INFORMATION REGULATOR</a>. Follow  <a href="https://bepopiacompliant.co.za/#/regulator/{{this.consentId}}">this link</a> for details. 
                </li>
                <li *ngIf="choice.service_level_communication == 'yes'">
                    SERVICE LEVEL COMMUNICATION: In order to provide our services, we might need to get into contact with you. Please select all the methods you will allow us to contact you by<br>
                    <div>
                        <button class="btn left-btn" id="btn3" *ngIf="choice.mediums_of_communication.includes('SMS')" [ngClass]="this.slc.includes('SMS') ? 'btn-consent-clicked' : 'btn-consent'">SMS</button>
                    
                        <button class="btn left-btn" id="btn4" *ngIf="choice.mediums_of_communication.includes('Telephone')" [ngClass]="this.slc.includes('Telephone') ? 'btn-consent-clicked' : 'btn-consent'">Telephone</button>
                                          
                        <button class="btn left-btn" id="btn5" *ngIf="choice.mediums_of_communication.includes('Email')" [ngClass]="this.slc.includes('Email') ? 'btn-consent-clicked' : 'btn-consent'">Email</button>
                    
                        <button class="btn left-btn" id="btn6" *ngIf="choice.mediums_of_communication.includes('Whatsapp')" [ngClass]="this.slc.includes('Whatsapp') ? 'btn-consent-clicked' : 'btn-consent'">Whatsapp</button>
                    
                        <button class="btn left-btn" id="btn7" *ngIf="choice.mediums_of_communication.includes('Telegram')" [ngClass]="this.slc.includes('Telegram') ? 'btn-consent-clicked' : 'btn-consent'">Telegram</button>
                   
                        <button class="btn left-btn" id="btn8" *ngIf="choice.mediums_of_communication.includes('Messenger')" [ngClass]="this.slc.includes('Messenger') ? 'btn-consent-clicked' : 'btn-consent'">Messenger</button>
                        
                        <div class="checkboxes" *ngIf="choice.mediums_of_communication_other && this.slc.includes(choice.mediums_of_communication_other)">
                            <button class="btn left-btn" id="btn8" [ngClass]="this.slc.includes(choice.mediums_of_communication_other) ? 'btn-consent-clicked' : 'btn-consent'">{{choice.mediums_of_communication_other | titlecase}}</button>
                        </div>
                    </div>
                </li>
                <li *ngIf="choice.marketing_communication == 'yes'">
                    MARKETING COMMUNICATION: We would like to send you updates on specials and promotions or just general news. Please select all the methods you will allow us to forward this to you<br>
                    <div>
                        <button class="btn left-btn" id="btn3" *ngIf="choice.mediums_of_marketing.includes('SMS')" [ngClass]="this.mc.includes('SMS') ? 'btn-consent-clicked' : 'btn-consent'">SMS</button>
                    
                        <button class="btn left-btn" id="btn4" *ngIf="choice.mediums_of_marketing.includes('Telephone')" [ngClass]="this.mc.includes('Telephone') ? 'btn-consent-clicked' : 'btn-consent'">Telephone</button>
                                          
                        <button class="btn left-btn" id="btn5" *ngIf="choice.mediums_of_marketing.includes('Email')" [ngClass]="this.mc.includes('Email') ? 'btn-consent-clicked' : 'btn-consent'">Email</button>
                    
                        <button class="btn left-btn" id="btn6" *ngIf="choice.mediums_of_marketing.includes('Whatsapp')" [ngClass]="this.mc.includes('Whatsapp') ? 'btn-consent-clicked' : 'btn-consent'">Whatsapp</button>
                    
                        <button class="btn left-btn" id="btn7" *ngIf="choice.mediums_of_marketing.includes('Telegram')" [ngClass]="this.mc.includes('Telegram') ? 'btn-consent-clicked' : 'btn-consent'">Telegram</button>
                   
                        <button class="btn left-btn" id="btn8" *ngIf="choice.mediums_of_marketing.includes('Messenger')" [ngClass]="this.mc.includes('Messenger') ? 'btn-consent-clicked' : 'btn-consent'">Messenger</button>
                        
                        <div class="checkboxes" *ngIf="choice.mediums_of_marketing_other && this.slc.includes(choice.mediums_of_marketing_other)">
                            <button class="btn left-btn" id="btn8" [ngClass]="this.mc.includes(choice.mediums_of_marketing_other) ? 'btn-consent-clicked' : 'btn-consent'">{{choice.mediums_of_marketing_other | titlecase}}</button>
                        </div>
                    </div>
                </li>
            </ol>
            
            <div class="break" style="break-after:always!important"></div>
            <div style="margin-top: -30px;" *ngIf="choice.consent_extras">
                {{choice.consent_extras}}
            </div>
            <div style="margin-top: 10px;" *ngIf="choice.social_media_tools == 'yes'">
                Since we make use of some social Media tools, the authors of the tools may or may not also track your actions on our website.
            </div>
            <div style="margin-top: 10px;">
                By default, WordPress uses cookies to manage logged-in user sessions and authentication. It also uses cookies to remember a user's name and email address if they fill out a comment form. However, many WordPress plugins on your website may also set their own cookies.
            </div>
            <div style="margin-top: 30px;">
                <span class="dontPrint"><br><br></span>
                <div class="signature-image2">
                    I <span class="date" >{{consentForm.value.name}}</span>
                    <span *ngIf="consentForm.value.name == ''" class="longLine"></span> 
                    with ID <span class="date" >{{consentForm.value.id_number}}</span>
                    <span *ngIf="consentForm.value.id_number == ''" class="mediumLine"></span> 
                    herewith give my consent to responsible parties mentioned above.
    
                    <br><br><br>Signed at <span class="date" >{{consentForm.value.signed_at}}</span>
                    <span class="mediumLine" *ngIf="consentForm.value.signed_at == ''"></span> 
                    on the 
                    <span class="date" >{{signed_date}}</span>
                    <span class="mediumLine" *ngIf="signed_date == ''"></span> .
                    <span class="line-break" *ngIf="this.device == 1"><br><br><br></span> 
                    <span style="margin-left: 20px;">Signature: 
                        <span style="border-bottom: 1px solid black!important;"><img style="margin-top: -35px;" class="signature_print" width="100" src='{{ signatureImg2 }}' /></span>
                        <span *ngIf="!signatureImg2" class="mediumLine"></span>
                    </span>
                </div>
            </div>
        </div>
    </form>
    <div class="centerText">
        <div *ngIf="this.slc.length == 0 && errors" class="alert alert-danger">
            You need to provide consent for one of the Service level communications.
        </div>
    </div>
</div>
