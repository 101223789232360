import { ApiService } from 'src/app/api.service';
import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-wordpress',
  templateUrl: './wordpress.component.html',
  styleUrls: ['./wordpress.component.scss']
})
export class WordpressComponent implements OnInit {
  wpf: any;
  wpfa_audio: any;
  wpp: any;
  wppa_audio: any;
  device = 0;
  form = "wp";
  
  active_plugins: any;
  active_pro_users: any;
  active_consents: any;
  active_requests: any;
  
  constructor(private apiService: ApiService, private _vps: ViewportScroller) { }

  ngOnInit(): void {    
    this.apiService.getStats().subscribe(
      (res: any) => {
        this.active_plugins = Number(res.active_plugins) + 63;
        this.active_pro_users = Number(res.active_pro_users) + 24;
        this.active_consents = Number(res.active_consents) + 227;
        this.active_requests = Number(res.active_requests) + 6;
      },
      error => console.log(error)
    );
    this._vps.scrollToPosition([0, 0]);
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.apiService.getWpFree().subscribe(
      (result: any) => {
        this.wpf = result;
        this.getFreeAudio();
      },
      error => console.log(error)
    )
    this.apiService.getWpPro().subscribe(
      (result: any) => {
        this.wpp = result;
        this.getProAudio();
      },
      error => console.log(error)
    )
  }
  getFreeAudio(){
    this.apiService.getWpFreeAudio().subscribe(
      (result: any) => {
        this.wpfa_audio = result
      },
      error => console.log(error)
    )
  }
  getProAudio(){
    this.apiService.getWpProAudio().subscribe(
      (result: any) => {
        this.wppa_audio = result
      },
      error => console.log(error)
    )
  }
  playAudio(wp_audio:any){
    var audio = new Audio();
    audio.src = 'data:audio/ogg;base64,' + wp_audio;
    audio.load();
    audio.play();
  }
  private currentPlayedElem: any = null;

  onPlay(elm: HTMLAudioElement) {
    if (this.currentPlayedElem && this.currentPlayedElem !== elm ) {
      this.currentPlayedElem.pause();
    }

    this.currentPlayedElem = elm;
  }
}
