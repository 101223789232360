import { ApiService } from 'src/app/api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm = new FormGroup({
    password_one: new FormControl('', Validators.required),
    password_two: new FormControl('', Validators.required)
  });
  passwords_not_matching = false;
  token: any;
  successfully_reset_password = false;
  unsuccessfully_reset_password = false;
  device = 0;
  fieldTextType: any;

  constructor(private route: ActivatedRoute, private apiService: ApiService) { }

  ngOnInit(): void { 
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.route.params.subscribe(event => {
      this.token = event.token;
    });
  }
  saveForm(){
    if(this.resetPasswordForm.value.password_one == this.resetPasswordForm.value.password_two){
      this.apiService.forgotPasswordNewPassword(this.resetPasswordForm.value.password_one, this.token).subscribe(
        (result: any) => {
          this.successfully_reset_password = true;
        },
        error => {
          this.unsuccessfully_reset_password = true;
        }
      );
    } else {
      this.passwords_not_matching = true;
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
