import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})

export class FaqComponent implements OnInit {
  device: any = 0;

  faqs: any;
  faqs_audio:any = null;
  chosenBtn = 'all'

  constructor(private apiService: ApiService, private _vps: ViewportScroller) { }

  ngOnInit() {
    this._vps.scrollToPosition([0, 0]);
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.apiService.getFaqs().subscribe(
      (result: any) => {
        this.faqs = result,
        this.getAudio()
      },
      error => console.log(error)
    )
    
  }
  changeContent(contentChosen: any){
    this.chosenBtn = contentChosen;
    if(contentChosen == 'all'){
      this.apiService.getFaqs().subscribe(
        (result: any) => {
          this.faqs = result,
          this.getAudio()
        },
        error => console.log(error)
      )
    } else if(contentChosen == 'popia'){
      this.apiService.getFaqsType(0).subscribe(
        (result: any) => {
          this.faqs = result,
          this.getAudioType(0)
        },
        error => console.log(error)
      )
    } else if(contentChosen == 'bpc'){
      this.apiService.getFaqsType(1).subscribe(
        (result: any) => {
          this.faqs = result,
          this.getAudioType(1)
        },
        error => console.log(error)
      )
    }

  }
  playAudio(faq_audio:any){
    var audio = new Audio();
    audio.src = 'data:audio/ogg;base64,' + faq_audio;
    audio.load();
    audio.play();
  }
  getAudio(){
    this.apiService.getFaqsAudio().subscribe(
      (result: any) => {
        this.faqs_audio = result
      },
      error => console.log(error)
    )
  }
  getAudioType(type: any){
    this.apiService.getFaqsAudioType(type).subscribe(
      (result: any) => {
        this.faqs_audio = result
      },
      error => console.log(error)
    )
  }
  private currentPlayedElem: any = null;

  onPlay(elm: HTMLAudioElement) {
    if (this.currentPlayedElem && this.currentPlayedElem !== elm ) {
      this.currentPlayedElem.pause();
    }

    this.currentPlayedElem = elm;
  }
  

}
