<div [ngClass]="this.device == 1 ? 'layout_mobile' : ''">
    <button type="button" class="btn btn-danger" id="goBackButton" (click)="goBack()" style="margin-top:-60px;">Back</button>
    <h1 style="text-align:center;">COMPANY DETAILS</h1>
    <div id="manage-domain">
        <form [formGroup]="domainForm">
            <div>
                <label>Company Logo</label><br>
                <img alt="" id="img" width="200" src={{domainForm.value.company_logo}}>
                <input type="file" name="company_logo" id="company_logo" (change)="onUploadChange($event)" accept=".png, .jpg, .jpeg, .pdf"  />
                <div *ngIf="domainForm.value.company_logo == '' && errors == 1" class="alert alert-danger">
                        Company logo is required.
                </div>
            </div>
            <div [ngClass]="this.device == 1 ? 'manageWrap_mobile' : 'manageWrap'">
                <div>
                    <label>Full registered name of Body <span style="color: red;">*</span></label><br>
                    <input type="text" formControlName="company_name"  class="roundedInputs"/><br>
                    <div *ngIf="!domainForm.value.company_name && errors == 1" class="alert alert-danger">
                            Company name is required.
                    </div>
                </div>
                <div>
                    <label>Trading name of Body <span style="color: red;">*</span></label><br>
                    <input type="text" formControlName="trading_name" class="roundedInputs"/><br>
                    <div *ngIf="!domainForm.value.trading_name && errors == 1" class="alert alert-danger">
                            Trading name is required.
                    </div>
                </div>
                <div>
                    <label>Company Domain <span style="color: red;">*</span></label><br>
                    <input type="text" formControlName="company_domain" class="roundedInputs"/><br>
                    <div *ngIf="!domainForm.value.company_domain && errors == 1" class="alert alert-danger">
                            Company domain is required.
                    </div>
                </div>
                <div>
                    <label>Company Registration Number <span style="color: red;">*</span></label><br>
                    <input type="text" formControlName="company_reg" class="roundedInputs"/><br>
                    <div *ngIf="!domainForm.value.company_reg && errors == 1" class="alert alert-danger">
                            Company registration number is required.
                    </div>
                </div>
                <div>
                    <label>Tax Number</label><br>
                    <input type="text" formControlName="tax_number" class="roundedInputs"/><br>   
                </div>
                <div>
                    <label>Office Number <span style="color: red;">*</span></label><br>
                    <input type="text" formControlName="office_number" class="roundedInputs"/><br>
                    <div *ngIf="!domainForm.value.office_number && errors == 1" class="alert alert-danger">
                        Office number is required.
                    </div>                    
                </div>
                <div>
                    <label>Direct Email <span style="color: red;">*</span></label><br>
                    <input type="text" formControlName="direct_email" class="roundedInputs"/><br>
                    <div *ngIf="!domainForm.value.direct_email && errors == 1" class="alert alert-danger">
                        Email is required.
                    </div>
                </div>
                <div>
                    <label>Type Of Body <span style="color: red;">*</span></label>
                    <select formControlName="type_of_body" class="roundedInputs selects" style="height: 45px;font-size: 15px;padding-left: 5px;width: 100%;" [ngClass]="this.domainForm.value.type_of_body == '' ? 'colorGrey' : 'colorBlack'">
                        <option hidden value='' disabled selected>Select Body Type</option>
                        <option *ngFor="let choice of body_type_options" [ngValue]="choice.id" class="colorBlack">{{choice.name}}</option>
                    </select> 
                    <div *ngIf="!domainForm.value.type_of_body && errors == 1" class="alert alert-danger">
                        Type of body is required.
                    </div>
                </div>
                <div>
                    <label>Entity Type <span style="color: red;">*</span></label>
                    <select formControlName="entity_type" class="roundedInputs selects" style="height: 45px;font-size: 15px;padding-left: 5px;width: 100%;" [ngClass]="this.domainForm.value.type_of_body == '' ? 'colorGrey' : 'colorBlack'">
                        <option hidden value='' disabled selected>Select Entity Type</option>
                        <option *ngFor="let choice of entity_type_options" [ngValue]="choice.id" class="colorBlack">{{choice.name}}</option>
                    </select> 
                    <div *ngIf="!domainForm.value.type_of_body && errors == 1" class="alert alert-danger">
                        Entity type is required.
                    </div>
                </div>
                
                
                <div>
                    <label>Is your company required by law to retain records? <span style="color: red;">*</span></label><br>
                    
                    <button class="btn left-btn" id="btn1" (click)="retainRecordsChange('yes')" [ngClass]="this.domainForm.value.retain_records == 'yes' ? 'btn-selected' : 'btn-not-selected'">Yes</button>
                    <button class="btn right-btn" id="btn2" (click)="retainRecordsChange('no')" [ngClass]="this.domainForm.value.retain_records == 'no' ? 'btn-selected' : 'btn-not-selected'">No</button>
                </div>
                <div *ngIf="this.domainForm.value.retain_records == 'yes'">
                    <label>Provide the reason for the retention of the data.</label><br>
                    <input type="text" class="roundedInputs" formControlName="retain_records_reason" placeholder="This is because the FIC Act, Act 38 of 2001 requires us to retain data for a 5 year period">
                </div>
                <div *ngIf="this.domainForm.value.retain_records == 'yes'">
                    <label>Should the data be retained for a fixed term or until a specific date?<span style="color: red;">*</span></label><br>
                    
                    <button class="btn left-btn" id="btn1" style="width: 125px;" (click)="retainPeriodChange('fixed')" [ngClass]="this.domainForm.value.retain_period == 'fixed' ? 'btn-selected' : 'btn-not-selected'">Fixed Term</button>
                    <button class="btn right-btn" id="btn2" style="width: 125px;" (click)="retainPeriodChange('specific')" [ngClass]="this.domainForm.value.retain_period == 'specific' ? 'btn-selected' : 'btn-not-selected'">Specific Date</button>
                </div>
                <div *ngIf="this.domainForm.value.retain_period == 'fixed' && this.domainForm.value.retain_records == 'yes'">
                    <label>Retain data for a specific period</label><br>
                    <div style="display: inline-flex;">
                        <div>
                            <label>Years</label><br>
                            <input type="number" class="roundedInputs" style="width: 70%;" placeholder="0" formControlName="retain_years">
                        </div>
                        <div>
                            <label>Months</label><br>
                            <input type="number" class="roundedInputs" style="width: 70%;" placeholder="0" formControlName="retain_months">
                        </div>
                    </div>
                </div>
                <div *ngIf="this.domainForm.value.retain_period == 'specific' && this.domainForm.value.retain_records == 'yes'">
                    <label>Retain data until a specific date</label><br>
                    <input type="date" class="roundedInputs" style="width: 58%;" formControlName="retain_date">
                </div>
            </div><br>
            <div [ngClass]="this.device == 1 ? 'manageWrap_mobile' : 'manageWrap'">
                <div *ngIf="showOwnerButtons">   
                    <label>Data Officer<br><span style="font-size: 16px;font-weight: 400;">(By default the Owner or CEO)</span></label>         
                    <button class="btn btn-danger" style="float: right;margin: 10px 30px 10px 10px;" (click)="openHosts($event, mymodal)">Add Data Officer</button><br><br>
                    <input type="text" value="{{def_name}} {{def_surname}}" disabled class="roundedInputs"><br>
                </div>
                <div *ngIf="showDIOButtons">
                    <label>Deputy Information Officer<br><span style="font-size: 16px;font-weight: 400;">(Any appointed or deliciated person)</span></label>         
                    
                    <button class="btn btn-danger" style="float: right;margin: 10px 30px 10px 10px;" (click)="openHosts($event, mymodal2)">Add Deputy Information Officer</button><br><br>
                    <div *ngIf="deputy_officers.length > 0" class="wrapper">
                        <h6 class="centerText">Current Deputy Information Officers:</h6>
                        <ul class="ulWrap">
                            <li *ngFor="let item of deputy_officers">
                                {{item.data_officer_first_name}} {{item.data_officer_surname}} 
                                <button class="btn btn-danger removeBtns" (click)="openHosts2($event, mymodal3, item.user, item.data_officer_first_name, item.data_officer_surname)">Remove</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div><br>
            <div *ngIf="data_officer_success" class="alert alert-success">
                Data Officer successfully invited.
            </div>
            <div *ngIf="deputy_officer_success" class="alert alert-success">
                Deputy Information Officer successfully invited.
            </div>
            
            <h3 style="margin-top: 40px;">Physical Address</h3>
            <div [ngClass]="this.device == 1 ? 'manageWrap_mobile' : 'manageWrap'">
                <div>
                    <label>Street Number</label><br>
                    <input type="text" formControlName="ph_street_number" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Street Name</label><br>
                    <input type="text" formControlName="ph_street_name" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Suburb</label><br>
                    <input type="text" formControlName="ph_suburb" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Town/City</label><br>
                    <input type="text" formControlName="ph_town_city" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Province</label><br>
                    <input type="text" formControlName="ph_province" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Postal Code</label><br>
                    <input type="text" formControlName="ph_postal_code" class="roundedInputs"/><br>
                </div>
            </div><br>
            <div style="width: 100%;display: inline-flex;margin: 20px 0px;">
                <input type="checkbox" formControlName="postal_same_as_physical" style="width: 20px;"/><br>
                <label style="margin-top: -7px;">Postal Address Same As Physical Address</label>
            </div><br>
            <div *ngIf="!this.domainForm.value.postal_same_as_physical">
                <h3>Postal Address</h3>
                <div [ngClass]="this.device == 1 ? 'manageWrap_mobile' : 'manageWrap'">
                    <div>
                        <label>Street Number</label><br>
                        <input type="text" formControlName="po_street_number" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Street Name</label><br>
                        <input type="text" formControlName="po_street_name" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Suburb</label><br>
                        <input type="text" formControlName="po_suburb" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Town/City</label><br>
                        <input type="text" formControlName="po_town_city" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Province</label><br>
                        <input type="text" formControlName="po_province" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Postal Code</label><br>
                        <input type="text" formControlName="po_postal_code" class="roundedInputs"/><br>
                    </div>
                </div>
            </div>
            <br>      
            <div class="submitButton">
                <button type="button" class="btn btn-danger mr20bbtn" (click)="goBack()">Back</button>
                <button type="button" class="btn btn-danger snbtnrbgd" (click)="goNext()" *ngIf="!showSaveButton && external == 0" ng-disabled="domainForm.$invalid">Next</button>
                <button type="button" class="btn btn-danger snbtnrbgd" (click)="goSave()" *ngIf="showSaveButton || external == 1" ng-disabled="domainForm.$invalid">Save</button>
            </div>  
        </form>
    </div>
</div>

            
<ng-template #mymodal let-modal>
    <div class="modal-body" style="padding: 1rem 3rem 3rem 3rem!important;">
        <button type="button" style="float: right;" class="close" #closeModals aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button><br><br><br>
        <h3 class="centerText">Invite Person as Data Officer</h3>
        <p class="centerText">(This must be the Owner or CEO of this company)</p>
        
        <form [formGroup]="dataOfficerForm">
            <div>
                <label>Name</label>
                <input type="text" formControlName="name" class="roundedInputs">
            </div>
            <div>
                <label>Surname</label>
                <input type="text" formControlName="surname" class="roundedInputs">
            </div>
            <div>
                <label>Email</label>
                <input type="text" formControlName="email" class="roundedInputs">
            </div>
            <!-- <div>
                <label>Transfer full control to the new data officer ( Leave unchecked if you wish to stay an editor )</label>
                <input type="checkbox" formControlName="full_control">
            </div> -->
            <br>
            <button class="btn btn-danger" style="float: right;" (click)="saveDataOfficer()">INVITE</button>
        </form>                    
    </div>
</ng-template>

<ng-template #mymodal2 let-modal>
    <div class="modal-body" style="padding: 1rem 3rem 3rem 3rem!important;">
        <button type="button" style="float: right;" class="close" #closeModals aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button><br><br><br>
        <h3 class="centerText">Invite Person as Deputy Information Officer</h3>
        
        <form [formGroup]="deputyInformationOfficerForm">
            <div>
                <label>Name</label>
                <input type="text" formControlName="name" class="roundedInputs">
            </div>
            <div>
                <label>Surname</label>
                <input type="text" formControlName="surname" class="roundedInputs">
            </div>
            <div>
                <label>Email</label>
                <input type="text" formControlName="email" class="roundedInputs">
            </div><br>
            <button class="btn btn-danger" style="float: right;" (click)="addDeputyOfficer()">INVITE</button>
        </form>
    </div>
</ng-template>

<ng-template #mymodal3 let-modal>
    <div class="modal-body" style="padding: 1rem 3rem 3rem 3rem!important;">
        <button type="button" style="float: right;" class="close" #closeModals aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button><br><br><br>
        <h3 class="centerText">You are about to delete {{first_name}} {{surname}} as a Deputy Information Officer for {{domainForm.value.trading_name}}</h3>
        
        <div class="centerText"><br>
            <h4>Are you sure?</h4>
            <button class="btn btn-success modalButtons" (click)="removeDIO(chosen_user)">Yes</button>
            <button class="btn btn-warning modalButtons" (click)="dontRemoveDIO()">No</button>
        </div>
    </div>
</ng-template>
