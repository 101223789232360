import { formatDate } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-paymore',
  templateUrl: './paymore.component.html',
  styleUrls: ['./paymore.component.scss']
})
export class PayMoreComponent implements OnInit {
  domainId: any;
  all_selected = false;
  device = 0;

  @Input() set domain(val: any){
    this.domainId = val.id;
  }
  @Output() goPayDomains = new EventEmitter();
  @Output() goBackEm = new EventEmitter();
  
  domainArray:any = [];
  domains:any;
  managed_domains:any;
  currentDate:any;
  renew_domains:any = [];
  renew_manage_domains:any = [];
  isChecked: any;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.domainArray.push(this.domainId);
    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.apiService.getDomains().subscribe(
      data => {
        this.domains = data;
        for(let child of this.domains){
          if(child.renew_date < this.currentDate || child.renew_date == null){
            this.renew_domains.push(child);
          }
        }
      },
      error => console.log(error)
    )
    this.apiService.getMyManageableDomains().subscribe(
      (data : any) => {
        for(let child of data){
          this.apiService.getDomain(child.domain_id).subscribe(
            (res: any) => {
              this.domains.push(res);
              if(res.renew_date == null || res.renew_date < this.currentDate){
                this.renew_domains.push(res);
              }
            },
            error => console.log(error)
          )
        }
      },
      error => console.log(error)
    )
    this.apiService.getDeputyDomains().subscribe(
      (data: any) => {
        for(let child of data){
          this.domains.push(child);
          if(child.renew_date == null || child.renew_date < this.currentDate){
            this.renew_domains.push(child);
          }
        }
      },
      error => console.log(error)
    )
  }

  onChange(id:any) {
    if(this.domainArray.includes(id)){
      this.domainArray = this.domainArray.filter((item:any) => item !== id);
    } else {
      this.domainArray.push(id);
    }
  }
  checkAll() {
    if(this.domainArray.length < 2){
      for(let child of this.domains){
        if(child.id == this.domainId && this.domainArray.includes(this.domainId)){
        } else {
          if(child.renew_date < this.currentDate || child.renew_date == null){
            this.domainArray.push(child.id);
            this.all_selected = true;
          }
        }
      }
    } else {
      this.domainArray = [];
    }
  }

  toggleCheckBox(id:any){
    return (this.domainArray.indexOf(id) != -1) ? true : false;
  }
    
  goPay(){
    this.goPayDomains.emit(this.domainArray)
  }
  goBack(){
    this.goBackEm.emit("back");
  }
}
