import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {Router} from '@angular/router'; 
import { CookieService } from 'ngx-cookie-service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {

  domains: any = [];
  managed_domains: any = [];
  deputy_domains: any = [];
  selectedDomain: any = null;
  selectedConsent: any = null;
  selectedThird: any = null;
  selectedKeys: any = null;
  selectedConsentForm: any = null;
  needsToPay: any = null;
  isSelected = 0;
  needsToPayMore: any = 0;
  needsToPayMoreManage: any = 0;
  currentDate: any;
  needsToPayMoreShow = null;
  domainsWanted: any;


  constructor(private apiService: ApiService, private cookieService: CookieService, private router:Router) { }

  ngOnInit(): void {
    this.apiService.test().subscribe(
      (res: any) => {
        console.log(res)
      },
      err => console.log(err)
    );



    const user_email = this.cookieService.get('bpc-email');
    this.apiService.checkConsent(80, user_email).subscribe(
      (res: any) => {
        if(res.length > 0){
          this.cookieService.set('bpc-consent', '1');
        } else {
          this.router.navigate(['/portal/profile']);
        }
      },
      err => console.log(err)
    );
    this.apiService.getProfile().subscribe(
      (res: any) => {
        if(res[0].profile_completed == 0){
          this.router.navigate(['/portal/profile']);
        }
      },
      error => console.log(error)
    )
    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    const bpcToken = this.cookieService.get('bpc-token');
    if (!bpcToken) {
      this.router.navigate(['/auth']);
    } else {
      this.apiService.getDomains().subscribe(
        data => {
          this.domains = data;       
          for(let child of this.domains){
            if(child.renew_date == null || child.renew_date < this.currentDate){
              if(child.is_subscribed != 0){
                var not_subbed = 0
                this.apiService.updateSubscription(child.id, not_subbed).subscribe(
                  () => {
                  },
                  error => console.log(error)
                )
              }
              this.needsToPayMore += 1;
            }
          }
        },
        error => console.log(error)
      )
      this.apiService.getDeputyDomains().subscribe(
        (data: any) => {
          const id = this.cookieService.get('bpc-id');
          for(let child of data){
            this.apiService.getDomain(child.id).subscribe(
              (res: any) => {
                if(child.deputy_officers != null && child.deputy_officers.includes(id) && child.user != id){
                  this.deputy_domains.push(res);
                }
                if(res.renew_date == null || res.renew_date < this.currentDate){
                  if(res.is_subscribed != 0){
                    var not_subbed = 0
                    this.apiService.updateSubscription(res.id, not_subbed).subscribe(
                      () => {
                      },
                      error => console.log(error)
                    )
                  }
                  this.needsToPayMore += 1;
                }
              },
              error => console.log(error)
            )
          }
        },
        error => console.log(error)
      )
      this.apiService.getMyManageableDomains().subscribe(
        (data : any) => {
          for(let child of data){
            this.apiService.getDomain(child.domain_id).subscribe(
              (res: any) => {
                this.managed_domains.push(res);
                if(res.renew_date == null || res.renew_date < this.currentDate){
                  if(res.is_subscribed != 0){
                    var not_subbed = 0
                    this.apiService.updateSubscription(res.id, not_subbed).subscribe(
                      () => {
                      },
                      error => console.log(error)
                    )
                  }
                  this.needsToPayMore += 1;
                }
              },
              error => console.log(error)
            )
          }
        },
        error => console.log(error)
      )
    }
  }

  goNextEm(next: any){
    this.selectedThird = this.selectedDomain;
    this.selectedConsent = null;
    this.selectedDomain = null;
    this.selectedConsentForm = null;
  }
  goNextThirdEm(next: any){
    this.selectedConsent = this.selectedThird;
    this.selectedDomain = null;
    this.selectedThird = null;
    this.selectedConsentForm = null;
  }
  goPay(next: any){
    this.needsToPay = this.selectedConsent;
    this.selectedConsent = null;
    this.selectedDomain = null;
    this.selectedThird = null;
    this.selectedConsentForm = null;
  }
  goBackEm(back: any){
    this.selectedDomain = null;
    this.selectedConsent = null;
    this.selectedThird = null;
    this.needsToPay = null;
    this.needsToPayMoreShow = null;
    this.selectedKeys = null;
    this.selectedConsentForm = null;
  }
  selectPay(domain: any){
    if(this.needsToPayMore > 1){
      this.needsToPayMoreShow = domain;
    } else {
      this.needsToPay = domain;
    }
  }
  selectConsent(domain: any){
    this.selectedConsent = domain;
    this.isSelected = 1;
  }
  selectThird(domain: any){
    this.selectedThird = domain;
    this.isSelected = 1;
  }
  selectDomain(domain: any){
    this.selectedDomain = domain;
  }
  selectKeys(domain: any){
    this.selectedKeys = domain;
  }
  selectConsentForm(domain: any){
    this.selectedConsentForm = domain;
  }
  createDomain(){
    this.selectedDomain = {company_name: '', company_logo: '', company_domain: '', company_reg: '', responsible_person_name: '', responsible_person_surname: '', responsible_person_email: '', responsible_person_cell: '', other_parties: '', renew_date: '', subscription_length: ''};
  }
  domainCreated(domain: any){
    this.domains.push(domain);
    this.selectedDomain = domain;
  }
  
  quickDomainCreated(domain: any){
    this.domains.push(domain);
  }
  domainUpdated(domain: any){
    const indx = this.domains.findIndex((dom:any) => dom.id === domain.id);
    if(indx >= 0){
      this.domains[indx] = domain;
    }
  }
  goPayDomains(domains: any){
    this.domainsWanted = domains;
    this.needsToPay = domains;
    this.selectedConsent = null;
    this.selectedDomain = null;
    this.selectedThird = null;
    this.needsToPayMoreShow = null;
    this.selectedConsentForm = null;
  }
}
