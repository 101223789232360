import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})


export class PaymentComponent implements OnInit {
  yearly_security:any;
  monthly_security:any;
  formVaild = true;
  id:any;
  device = 0;
  url = 'https://www.payfast.co.za/eng/process';

  @Output() goBackEm = new EventEmitter();

  @Input() set domain(val: any){
    this.id = val.id;
  };
  @Input()  domainsWanted: any;
  monthlyCost: any = 200;
  yearlyCost: any = 2000;
  original_cost_monthly = 200;
  original_cost_yearly = 2000;
  main_monthly = 200;
  main_yearly = 2000;
  saving_percentage = 0;

  name_first: any;
  name_last: any;
  email:any;
  cell: any;

  constructor(private apiService: ApiService, private cookieService: CookieService) { }

  ngOnInit(): void {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    if(!this.domainsWanted){
      this.apiService.getDomainPrice(this.id).subscribe(
        (result: any) => {
          for(let child of result){
            this.monthlyCost = child.req_payment,
            this.yearlyCost = child.req_payment * 10
            this.main_monthly = child.req_payment,
            this.main_yearly = child.req_payment * 10
            this.saving_percentage = 1.00 - (this.monthlyCost/this.original_cost_monthly);
            this.saving_percentage = (this.saving_percentage*100);
          }
        },
      )
      this.saving_percentage = 1.00 - (this.monthlyCost/this.original_cost_monthly);
      this.saving_percentage = (this.saving_percentage*100);
    } else {
      this.original_cost_monthly = this.original_cost_monthly * this.domainsWanted.length;
      this.original_cost_yearly = this.original_cost_yearly * this.domainsWanted.length;
      if( this.domainsWanted.length > 1){
        this.monthlyCost = 0;
        this.yearlyCost = 0;
  
        for(let domain of this.domainsWanted){
          this.apiService.getDomainPrice(domain).subscribe(
            (result: any) => {
              for(let child of result){
                this.monthlyCost += child.req_payment,
                this.yearlyCost += child.req_payment * 10
                this.main_monthly = child.req_payment,
                this.main_yearly = child.req_payment * 10
                this.saving_percentage = 1.00 - (this.monthlyCost/this.original_cost_monthly);
                this.saving_percentage = (this.saving_percentage*100);
              }
            },
            error => console.log(error)
          )
        }
      } else if( this.domainsWanted.length == 1){
        for(let domain of this.domainsWanted){
          this.apiService.getDomainPrice(domain).subscribe(
            (result: any) => {
              for(let child of result){
                this.monthlyCost = child.req_payment,
                this.yearlyCost = child.req_payment * 10
                this.main_monthly = child.req_payment,
                this.main_yearly = child.req_payment * 10
                this.saving_percentage = 1.00 - (this.monthlyCost/this.original_cost_monthly);
                this.saving_percentage = (this.saving_percentage*100);
              }
      
            },
            error => console.log(error)
          )
        }
      }
    }
    this.email = this.cookieService.get('bpc-email');
    if(this.domainsWanted){
      if( this.domainsWanted.length > 0){
        this.id = "" + this.domainsWanted;
      }
    }
    this.apiService.getYearlySubID(this.id, this.yearlyCost).subscribe(
      (result: any) => {
        this.yearly_security = result.hash
      },
      error => console.log(error)
    )
    this.apiService.getMonthlySubID(this.id, this.monthlyCost).subscribe(
      (result: any) => {
        this.monthly_security = result.hash
      },
      error => console.log(error)
    )
    this.apiService.getProfile().subscribe(
      (res: any) => {
        const result = res[0];
        this.name_first = result.data_officer_first_name;
        this.name_last = result.data_officer_surname;
        this.email = result.data_officer_direct_email;
        this.cell = result.data_officer_cell;
      },
      error => console.log(error)
    )
  }
  

  goBack(){
    this.goBackEm.emit("back");
  }


}
