import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';


@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})

export class ConsentComponent implements OnInit {
  dropdownListMarketing: any = [];
  dropdownListCommunicating: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};

  entity_type = 5;
  closeModal: any;
  id = null;
  new_res: string = '';
  showSaveButton = false;
  device = 0;
  timeout: any = null;

  @Output() domainCreated = new EventEmitter();
  @Output() domainUpdated = new EventEmitter();
  @Output() goNextEm = new EventEmitter();
  @Output() goBackEm = new EventEmitter();
  @Output() goPay = new EventEmitter();
 

  shortLink: string = "";
  loading: boolean = false; // Flag variable
  base64textString = Array();
  isSelected = 0;
  company_domain: any;
  marketing_mediums:any = [];
  communication_mediums:any = [];
  selectedRequiredCommunicationMediums:any = [];
  marketing_mediums_other:any;
  communication_mediums_other:any;
  consent_form_complete:any = 0;
  hide = 0;
  consentForm = new FormGroup({

    communication_way: new FormControl(''),      
    service_level_communication: new FormControl('', Validators.required),

    mediums_of_communication: new FormControl(''),
    mediums_of_communication_other: new FormControl(''),
    mediums_of_communication_required: new FormControl(''),

    marketing_communication: new FormControl(''),

    mediums_of_marketing: new FormControl(''),
    mediums_of_marketing_other: new FormControl(''),
    mediums_of_marketing_required: new FormControl(''),

    no_consent_consequences: new FormControl('', Validators.required),
    information_types: new FormControl('', Validators.required),
    data_obtained: new FormControl('', Validators.required),
    man_vol: new FormControl('', Validators.required),
    man_vol_reason: new FormControl(''),
    cross_border: new FormControl('', Validators.required),
    cross_border_reason: new FormControl(''),
    required_reason: new FormControl('', Validators.required),
    privacy_policy: new FormControl('', Validators.required),
    services: new FormControl('', Validators.required),
    consent_extras: new FormControl(''),
    social_media_tools: new FormControl('', Validators.required),
    wordpress_website: new FormControl('', Validators.required),

  });
  primary_key: any;
  @Input() set domain(val: any){
    this.id = val.id;
    this.company_domain = val.company_domain
    
    this.consentForm = new FormGroup({
      communication_way: new FormControl(val.communication_way),      
      service_level_communication: new FormControl(val.service_level_communication, Validators.required),
      mediums_of_communication: new FormControl(val.mediums_of_communication),
      mediums_of_communication_other: new FormControl(val.mediums_of_communication_other),
      marketing_communication: new FormControl(val.marketing_communication),
      mediums_of_marketing: new FormControl(val.mediums_of_marketing),
      mediums_of_communication_required: new FormControl(''),
      mediums_of_marketing_other: new FormControl(val.mediums_of_marketing_other),
      no_consent_consequences: new FormControl(val.no_consent_consequences, Validators.required),
      information_types: new FormControl(val.information_types, Validators.required),
      data_obtained: new FormControl(val.data_obtained, Validators.required),
      man_vol: new FormControl(val.man_vol, Validators.required),
      man_vol_reason: new FormControl(val.man_vol_reason),
      cross_border: new FormControl(val.cross_border, Validators.required),
      cross_border_reason: new FormControl(val.cross_border_reason),
      required_reason: new FormControl(val.required_reason, Validators.required),
      privacy_policy: new FormControl(val.privacy_policy, Validators.required),
      services: new FormControl(val.services, Validators.required),
      consent_extras: new FormControl(val.consent_extras),
      social_media_tools: new FormControl(val.social_media_tools, Validators.required),
      wordpress_website: new FormControl(val.wordpress_website, Validators.required)
    });
    if(this.consentForm.value.mediums_of_communication != '' && this.consentForm.value.mediums_of_communication != null){
      try{
        this.communication_mediums = this.consentForm.value.mediums_of_communication.split(',');
        this.consentForm.value.mediums_of_communication = this.consentForm.value.mediums_of_communication.split(',');
        for(let child of this.communication_mediums){
          this.dropdownListCommunicating.push(child)
        }
      } catch {
        this.communication_mediums = this.consentForm.value.mediums_of_communication;
        this.consentForm.value.mediums_of_communication = this.consentForm.value.mediums_of_communication;
        for(let child of this.communication_mediums){
          this.dropdownListCommunicating.push(child)
        }
      }
    }
    if(this.consentForm.value.mediums_of_marketing != '' && this.consentForm.value.mediums_of_marketing != null){
      try{
        this.marketing_mediums = this.consentForm.value.mediums_of_marketing.split(',');
        this.consentForm.value.mediums_of_marketing = this.consentForm.value.mediums_of_marketing.split(','); 
        for(let child of this.marketing_mediums){
          this.dropdownListMarketing.push(child)
        }
      } catch(err) {
        this.marketing_mediums = this.consentForm.value.mediums_of_marketing;
        this.consentForm.value.mediums_of_marketing = this.consentForm.value.mediums_of_marketing;
        for(let child of this.marketing_mediums){
          this.dropdownListMarketing.push(child)
        }
      }
    }
  };
  
  @Input() set selected(val: any){
    this.isSelected = val;
  };
  
  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    if(!this.id){
      this.route.params.subscribe(event => {
        this.primary_key = event.id;
        this.id = event.id;
        this.apiService.getConsentForm(event.id).subscribe(
          (result: any) => {
            for(let child of result){
              this.hide = 1;
              this.consentForm = new FormGroup({
                communication_way: new FormControl(child.communication_way),      
                service_level_communication: new FormControl(child.service_level_communication, Validators.required),
                mediums_of_communication: new FormControl(child.mediums_of_communication),
                mediums_of_communication_other: new FormControl(child.mediums_of_communication_other),
                marketing_communication: new FormControl(child.marketing_communication),
                mediums_of_communication_required: new FormControl(''),
                mediums_of_marketing: new FormControl(child.mediums_of_marketing),
                mediums_of_marketing_other: new FormControl(child.mediums_of_marketing_other),
                no_consent_consequences: new FormControl(child.no_consent_consequences, Validators.required),
                information_types: new FormControl(child.information_types, Validators.required),
                data_obtained: new FormControl(child.data_obtained, Validators.required),
                man_vol: new FormControl(child.man_vol, Validators.required),
                man_vol_reason: new FormControl(child.man_vol_reason),
                cross_border: new FormControl(child.cross_border, Validators.required),
                cross_border_reason: new FormControl(child.cross_border_reason),
                required_reason: new FormControl(child.required_reason, Validators.required),
                privacy_policy: new FormControl(child.privacy_policy, Validators.required),
                services: new FormControl(child.services, Validators.required),
                consent_extras: new FormControl(child.consent_extras),
                social_media_tools: new FormControl(child.social_media_tools, Validators.required),
                wordpress_website: new FormControl(child.wordpress_website, Validators.required),          
              });
              if(this.consentForm.value.mediums_of_communication != '' && this.consentForm.value.mediums_of_communication != null){
                try{
                  this.communication_mediums = this.consentForm.value.mediums_of_communication.split(',');
                  this.consentForm.value.mediums_of_communication = this.consentForm.value.mediums_of_communication.split(',');
                  for(let child of this.communication_mediums){
                    this.dropdownListCommunicating.push(child)
                  }
                } catch {
                  this.communication_mediums = this.consentForm.value.mediums_of_communication;
                  this.consentForm.value.mediums_of_communication = this.consentForm.value.mediums_of_communication;
                  for(let child of this.communication_mediums){
                    this.dropdownListCommunicating.push(child)
                  }
                }
              }
              if(this.consentForm.value.mediums_of_marketing != '' && this.consentForm.value.mediums_of_marketing != null){
                try{
                  this.marketing_mediums = this.consentForm.value.mediums_of_marketing.split(',');
                  this.consentForm.value.mediums_of_marketing = this.consentForm.value.mediums_of_marketing.split(',');
                  for(let child of this.marketing_mediums){
                    this.dropdownListMarketing.push(child)
                  }
                } catch(err) {
                  this.marketing_mediums = this.consentForm.value.mediums_of_marketing;
                  this.consentForm.value.mediums_of_marketing = this.consentForm.value.mediums_of_marketing;for(let child of this.marketing_mediums){
                    this.dropdownListMarketing.push(child)
                  }
                }
              }
            }
          },
          error => console.log(error)
        );
      });
    }
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }   
    if(this.isSelected == 1){
      this.showSaveButton = true;
    } 
    for(let child of this.communication_mediums){
      if(child.includes('_req')){
        this.selectedRequiredCommunicationMediums.push(child.replace('_req', ''))
      }
    }
    
    this.consentForm.patchValue({
      mediums_of_communication_required: this.selectedRequiredCommunicationMediums
    });
    
    this.dropdownListMarketing = ['SMS', 'Telephone', 'Email', 'Whatsapp', 'Telegram', 'Messenger', 'Other']; 
    
    this.dropdownListCommunicating = ['SMS', 'Telephone', 'Email', 'Whatsapp', 'Telegram', 'Messenger', 'Other'];
    
    for(let child of this.communication_mediums){
      if(child.includes('_req')){
        this.communication_mediums = this.communication_mediums.filter((item:any) => item !== child);
      }
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      limitSelection: 3
    };
  }
  onItemSelectMarketing(val: any) {
    var value = val + '_req';
    this.marketing_mediums.push(value);
  }
  onItemDeSelectMarketing(val: any) {
    var value = val + '_req';
    this.marketing_mediums = this.marketing_mediums.filter((item:any) => item !== value);
  }
  onItemSelectCommunications(val: any) {
    var value = val + '_req';
    this.communication_mediums.push(value);
  }
  onItemDeSelectCommunications(val: any) {
    var value = val + '_req';
    this.communication_mediums = this.communication_mediums.filter((item:any) => item !== value);
  }


  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e: any) {
    this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
  }
  
  saveForm(){
    if(!this.marketing_mediums_other){
      var req_val = 'mediums_of_marketing_other_req';
      this.marketing_mediums = this.marketing_mediums.filter((item:any) => item !== req_val);
    }
    if(!this.communication_mediums_other){
      var req_val = 'mediums_of_communication_other_req';
      this.communication_mediums = this.communication_mediums.filter((item:any) => item !== req_val);
    }
    for(let child of this.consentForm.value.mediums_of_communication_required){
      this.communication_mediums.push(child + '_req');
    }
    this.marketing_mediums = this.marketing_mediums.toString()
    this.communication_mediums = this.communication_mediums.toString()
    var privacy_policy = window.location.origin + '/#/privacy/' + this.id;

    if(this.consentForm.invalid){
    }else{
      this.consent_form_complete = 1;
    }
    if(this.id){
      this.apiService.updateConsentForm(this.id, this.consentForm.value.services, this.consentForm.value.communication_way, this.consentForm.value.service_level_communication, this.communication_mediums, this.communication_mediums_other, this.consentForm.value.marketing_communication, this.marketing_mediums, this.marketing_mediums_other, this.consentForm.value.no_consent_consequences, this.consentForm.value.information_types, this.consentForm.value.data_obtained, this.consentForm.value.man_vol, this.consentForm.value.man_vol_reason, this.consentForm.value.cross_border, this.consentForm.value.cross_border_reason, this.consentForm.value.required_reason, privacy_policy, this.consent_form_complete, this.consentForm.value.consent_extras, this.consentForm.value.social_media_tools, this.consentForm.value.wordpress_website).subscribe(
        () => {
          if(this.hide == 0){
            location.reload()
          } else {
            this.router.navigate(['/consent_form/' + this.primary_key]);
          }
        },
        error => console.log(error)
      );
    }
  }
  goNext(){
    if(!this.marketing_mediums_other){
      var req_val = 'mediums_of_marketing_other_req';
      this.marketing_mediums = this.marketing_mediums.filter((item:any) => item !== req_val);
    }
    if(!this.communication_mediums_other){
      var req_val = 'mediums_of_communication_other_req';
      this.communication_mediums = this.communication_mediums.filter((item:any) => item !== req_val);
    }
    if(this.consentForm.invalid){
    }else{
      this.consent_form_complete = 1;
    }
    for(let child of this.consentForm.value.mediums_of_communication_required){
      this.communication_mediums.push(child + '_req');
    }
    this.marketing_mediums = this.marketing_mediums.toString()
    this.communication_mediums = this.communication_mediums.toString()

    var privacy_policy = window.location.origin + '/#/consent_form/' + this.id;
    this.apiService.updateConsentForm(this.id, this.consentForm.value.services, this.consentForm.value.communication_way, this.consentForm.value.service_level_communication, this.communication_mediums, this.communication_mediums_other, this.consentForm.value.marketing_communication, this.marketing_mediums, this.marketing_mediums_other, this.consentForm.value.no_consent_consequences, this.consentForm.value.information_types, this.consentForm.value.data_obtained, this.consentForm.value.man_vol, this.consentForm.value.man_vol_reason, this.consentForm.value.cross_border, this.consentForm.value.cross_border_reason, this.consentForm.value.required_reason, privacy_policy, this.consent_form_complete, this.consentForm.value.consent_extras, this.consentForm.value.social_media_tools, this.consentForm.value.wordpress_website).subscribe(
      () => {
        this.goPay.emit("next");
      },
      error => console.log(error)
    );
  }
  
  goBack(){
    if(this.hide == 0){
      this.goBackEm.emit("back");
    } else {
      this.router.navigate(['/consent_form/' + this.primary_key]);
    }
  }

  isValidInput(fieldName: any) {
    return this.consentForm.controls[fieldName].invalid &&
      (this.consentForm.controls[fieldName].dirty || this.consentForm.controls[fieldName].touched);
  }
  addMarketingMediums(val: any){
    if(this.marketing_mediums.includes(val)){
      this.marketing_mediums = this.marketing_mediums.filter((item:any) => item !== val);
      this.dropdownListMarketing = this.dropdownListMarketing.filter((item:any) => item !== val);
    } else {
      this.marketing_mediums.push(val);
      this.dropdownListMarketing.push(val);
    }
  }
  addCommunicationMediums(val: any){
    if(this.communication_mediums.includes(val)){
      this.communication_mediums = this.communication_mediums.filter((item:any) => item !== val);
      this.dropdownListCommunicating = this.dropdownListCommunicating.filter((item:any) => item !== val);
      
    } else {
      this.communication_mediums.push(val);
      this.dropdownListCommunicating.push(val);
    }
    this.consentForm.patchValue({
      service_level_communication: 'no',
    })
    
    for(let child of this.consentForm.value.mediums_of_communication_required){
      if(child.includes(val)){
        this.consentForm.patchValue({
          mediums_of_communication_required: this.consentForm.value.mediums_of_communication_required.filter((item: any) => item !== val)
        });
      }
    }
    
    setInterval(() => {
      this.consentForm.patchValue({
        service_level_communication: 'yes',
      })
    },1)
  }
  onKeyUpMarketing(event: any) {
    clearTimeout(this.timeout);    
    if(this.dropdownListMarketing.includes('OTHER')){
      this.dropdownListMarketing = this.dropdownListMarketing.filter((item:any) => item !== 'OTHER');
    } else {
      this.dropdownListMarketing.push('OTHER');
    }
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.marketing_mediums_other = event.target.value
      }
    }, 1000);
  }
  onKeyUpCommunication(event: any) {
    clearTimeout(this.timeout);    
    if(this.dropdownListCommunicating.includes('OTHER')){
      this.dropdownListCommunicating = this.dropdownListCommunicating.filter((item:any) => item !== 'OTHER');
    } else {
      this.dropdownListCommunicating.push('OTHER');
    }
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.communication_mediums_other = event.target.value
      }
    }, 1000);
  }
  serviceLevelCommunicationChange(val: any){    
    this.consentForm.patchValue({
      service_level_communication: val,
    })
  }
  marketingCommunicationChange(val: any){
    this.consentForm.patchValue({
      marketing_communication: val,
    })
  }
  crossBorderChange(val: any){
    this.consentForm.patchValue({
      cross_border: val,
    })
  }
  socialMediaToolsChange(val: any){
    this.consentForm.patchValue({
      social_media_tools: val,
    })
  }
  wordpressWebsiteChange(val: any){
    this.consentForm.patchValue({
      wordpress_website: val,
    })
  }
}