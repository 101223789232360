<div [ngClass]="this.device == 1 ? 'layout_mobile' : 'layout'">
    <div class="alert alert-success centerText" id="success" *ngIf="successful.length > 0">
        The following domains has been processed successfully :
        <div *ngFor="let success of successful">
        <strong>{{ success.company_name }}</strong>
        </div>
    </div>
    <div class="alert alert-danger centerText" id="failed" *ngIf="unsuccessful.length > 0">
        There has been an issue processing your payment for the following domains :
        <div *ngFor="let unsuccess of unsuccessful">
            <strong>{{ unsuccess.company_name }}</strong>
        </div>
    </div>
    <button class="btn btn-danger" (click)="goToDomains()">Return to domains</button>
</div>

