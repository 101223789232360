import { ApiService } from './../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {
  company_id: any;
  type: any;
  id: any;
  username: any;
  choice: any;
  active: any = 1;

  constructor(private route: ActivatedRoute, private apiService : ApiService, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(event => {
      this.type = event.type;
      this.id = event.id;
      this.company_id = event.company_id;
      if(event.choice == 'accept'){
        this.choice = 1;
      }
      if(event.choice == 'decline'){
        this.choice = 0;
        this.active = 0;
      }
      this.apiService.getUsernameFromID(this.id).subscribe(
        (res: any) => {
          this.username = res[0].username;
          if(this.type == 'do'){
            this.apiService.getDataOfficerPK(this.company_id, this.username).subscribe(
              (res: any) => {
                const id = res[0].id;
                this.apiService.dataOfficerRequestResponse(id, this.choice, this.active).subscribe(
                  () => {
                    if(this.choice == 1){
                      this.apiService.updateCompanyDataOfficer(this.company_id, this.id).subscribe(
                        () => {
                          this.apiService.getEditorPK(this.company_id, this.username).subscribe(
                            (res: any) => {
                              if(res.length > 0){
                                const id = res[0].id;    
                                this.apiService.editorRequestResponse(id, this.choice, 0).subscribe(
                                  () => {
                                    this.router.navigate(['/auth']);
                                  },
                                  error => console.log(error)
                                )
                              } else {
                                this.router.navigate(['/auth']);
                              }
                            },
                            error => console.log(error)
                          ) 
                        },
                        error => console.log(error)
                      )
                    }
                  },
                  error => console.log(error)
                )
              },
              error => console.log(error)
            )
          } else if(this.type == 'dio'){
            this.apiService.getDeputyInformationOfficerPK(this.company_id, this.username).subscribe(
              (res: any) => {
                const id = res[0].id;    
                this.apiService.deputyOfficerRequestResponse(id, this.choice, this.active).subscribe(
                  () => {
                    this.apiService.getDomainDeputyOfficers(this.company_id).subscribe(
                      (result: any) => {
                        let officers;
                        if(!result.deputy_officers){
                          officers = result.deputy_officers + this.id;
                        } else {
                          officers = result.deputy_officers + ',' + this.id;
                        }
                        if(this.choice == 1){
                          this.apiService.updateCompanyDeputyOfficers(this.company_id, officers).subscribe(
                            () => {
                              this.apiService.getEditorPK(this.company_id, this.username).subscribe(
                                (res: any) => {
                                  if(res.length > 0){
                                    const id = res[0].id;    
                                    this.apiService.editorRequestResponse(id, this.choice, 0).subscribe(
                                      () => {
                                        this.router.navigate(['/auth']);
                                      },
                                      error => console.log(error)
                                    )
                                  } else {
                                    this.router.navigate(['/auth']);
                                  }
                                },
                                error => console.log(error)
                              ) 
                            },
                            error => console.log(error)
                          );
                        }
                      },
                      error => console.log(error)
                    );  
                  },
                  error => console.log(error)
                )
              },
              error => console.log(error)
            )
          } else if(this.type == 'ed'){
            this.apiService.getEditorPK(this.company_id, this.username).subscribe(
              (res: any) => {
                const id = res[0].id;    
                this.apiService.editorRequestResponse(id, this.choice, this.active).subscribe(
                  () => {
                    this.router.navigate(['/auth']);
                  },
                  error => console.log(error)
                )
              },
              error => console.log(error)
            ) 
          }
        },
        error => console.log(error)
      )
    });
  }
}
