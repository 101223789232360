import { PrivacyPolicyComponent } from './privacy_policy.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';


import { Routes, RouterModule } from '@angular/router';



const routes: Routes = [
  {path: 'privacy/:id', component: PrivacyPolicyComponent},
  {path: 'privacy/', component: PrivacyPolicyComponent},
];

@NgModule({
  declarations: [
    PrivacyPolicyComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    
    RouterModule.forChild(routes),
    
  ],
  exports: [
    RouterModule,
  ],
  providers: [],
})
export class PrivacyPolicyModule { }
