import { ApiService } from './../../api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-custom-dev',
  templateUrl: './custom-dev.component.html',
  styleUrls: ['./custom-dev.component.scss']
})
export class CustomDevComponent implements OnInit {
  device = 0;
  title = 'google-places-autocomplete';
  userAddress: string = ''
  ir_success = false;
  form = "cd";
  urgency_fin: any;
  marketing_fin: any;
  address: any;
  active_plugins: any;
  active_pro_users: any;
  active_consents: any;
  active_requests: any;
  formIncomplete = false;

  integrationForm = new FormGroup({
    com_name: new FormControl('', Validators.required),
    domain: new FormControl('', Validators.required),
    co_name: new FormControl('', Validators.required),
    co_surname: new FormControl('', Validators.required),
    co_delegation: new FormControl('', Validators.required),
    co_cell: new FormControl('', Validators.required),
    co_email: new FormControl('', Validators.required),
    street_number: new FormControl('', Validators.required),
    street_name: new FormControl('', Validators.required),
    suburb: new FormControl('', Validators.required),
    town_city: new FormControl('', Validators.required),
    province: new FormControl('', Validators.required),
    postal_code: new FormControl('', Validators.required),
    urgency: new FormControl('', Validators.required),
    languages: new FormControl('', Validators.required),
    marketing: new FormControl('', Validators.required),
    business_desc: new FormControl('', Validators.required),
    system_desc: new FormControl('', Validators.required),
  })

  urgency = [
    { id: 1, name: "Deadline is past due" },
    { id: 2, name: "As soon as reasonably possible" },
    { id: 3, name: "We are not in a hurry" }
  ];

  constructor(private _vps: ViewportScroller, private apiService: ApiService) { }

  ngOnInit(): void {  
     
    this.apiService.getStats().subscribe(
      (res: any) => {
        this.active_plugins = Number(res.active_plugins) + 63;
        this.active_pro_users = Number(res.active_pro_users) + 24;
        this.active_consents = Number(res.active_consents) + 227;
        this.active_requests = Number(res.active_requests) + 6;
      },
      error => console.log(error)
    );
    this._vps.scrollToPosition([0, 0]);
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    
  }

  submitRequest(){
    if(this.integrationForm.invalid){
      this.formIncomplete = true;
    } else {
      this.address = this.integrationForm.value.street_number + ', ' + this.integrationForm.value.street_name + ', ' + this.integrationForm.value.suburb + ', ' + this.integrationForm.value.town_city + ', ' + this.integrationForm.value.province + ', ' + this.integrationForm.value.postal_code;
  
      this.urgency_fin = this.urgency[this.integrationForm.value.urgency - 1].name;
      if(this.integrationForm.value.marketing == 1){
        this.marketing_fin = 'Yes';
      } else if(this.integrationForm.value.marketing == 2) {
        this.marketing_fin = 'No';
      }
      this.apiService.newIR(this.integrationForm.value.com_name, this.integrationForm.value.domain, this.integrationForm.value.co_name, this.integrationForm.value.co_surname, this.integrationForm.value.co_delegation, this.integrationForm.value.co_cell, this.integrationForm.value.co_email, this.address, this.urgency_fin, this.integrationForm.value.languages, this.marketing_fin, this.integrationForm.value.business_desc, this.integrationForm.value.system_desc).subscribe(
        () => {
          this.ir_success = true;
          this._vps.scrollToAnchor('ir_form_anchor');
        },
        error => console.log(error)
      )
    }
  }

  
}
