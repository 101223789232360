<div class="alert alert-danger centerText" id="failed" *ngIf="this.failed">
  <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.failed = false"></i>
  There was an error trying to login, ensure that your <strong>email</strong> and <strong>password</strong> is correct and try again.
</div>
<div class="alert alert-danger centerText" id="failed" *ngIf="this.username_exists && registerMode">
  <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.username_exists = false"></i>
  That <strong>email</strong> already exists, please try signing in.
</div>
<div class="alert alert-danger centerText" id="failed" *ngIf="this.username_exists && !registerMode">
  <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.username_exists = false"></i>
  <strong>Password</strong> incorrect please try again.
</div>
<div class="alert alert-danger centerText" id="failed" *ngIf="this.username_error">
  <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.username_error = false"></i>
  Ensure that your <strong>email</strong> is correct.
</div>
<div class="alert alert-danger centerText" id="failed" *ngIf="this.password_error && !this.username_exists">
  <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.password_error = false"></i>
  Ensure that your <strong>password</strong> is correct.
</div>
<div class="alert alert-success centerText" id="failed" *ngIf="this.show_sent_email">
  <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.show_sent_email = false"></i>
  We have sent you an email, please click the link to reset your password.
</div>
<div class="alert alert-danger centerText" id="failed" *ngIf="this.email_not_set">
  <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.email_not_set = false"></i>
  Please provide your email to reset your password.
</div>
<div class="containerDiv">
  <h1 class="centerText" *ngIf="registerMode">Register</h1>
  <h1 class="centerText" *ngIf="!registerMode">Login</h1><br>
  <form [formGroup]="authForm" (ngSubmit)="saveForm()">
    <div style="margin-bottom: 10px;">
      <label>Email</label><br>
      <input type="email" formControlName="email" email placeholder="email@example.com" class="roundedInputs"/><br>
    </div>
    <div style="margin-bottom: 10px;">
      <label>Password</label><br>
      <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password" class="roundedInputs"/>
      <i style="margin-left: -25px!important;font-size: 1.2rem;" [ngClass]="{'icon-eye-disabled': !fieldTextType, 'icon-eye': fieldTextType}" (click)="toggleFieldTextType()"></i><br>
    </div>
    <div *ngIf="registerMode" style="margin-bottom: 10px;">
      <label>Affiliate Code</label><br>
      <input type="text" formControlName="ref_code" [value]="ref" class="roundedInputs" placeholder="Optional"/>
    </div>
    
    <ul *ngIf="registerMode">
      <li>Password must be atleast 8 characters long.</li>
      <li>Password must not be common.</li>
      <li>Password must contain letters.</li>
    </ul>
    <div style="text-align: center;">
      <span *ngIf="!registerMode">Forgot password? Click <span (click)="forgotPassword()" class="hereBtn">here</span></span>
    </div>
    <br>
    <button type="submit" class="btn btn-danger" style="width: 100%;">
      <span *ngIf="!registerMode">Login</span>
      <span *ngIf="registerMode">Register</span>
    </button>
    <br>
    <hr>
    <button type="submit" class="btn btn-warning" style="width: 100%;" *ngIf="!registerMode" (click)="registerMode = true; this.username_exists = false">
      Create New Account      
    </button>
    <button type="submit" class="btn btn-warning" style="width: 100%;" *ngIf="registerMode" (click)="registerMode = false; this.username_exists = false">
      Login To Existing Account      
    </button>
  </form>
  <br>
</div>