import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss']
})
export class ReferralsComponent implements OnInit {
  device = 0;
  referrals: any = [];
  currentDate = formatDate(new Date(), 'dd-MM-yyyy', 'en-ZA');
  currentDateLogic = new Date().getTime();
  referral_code: any = this.cookieService.get('bpc-ref');
  is_reseller: any = this.cookieService.get('bpc-reseller');
  generated_code: any = '';
  email_used = false;
  empty_email = false;
  successfully_sent = false;
  formatted_referrals: any[] = [];
  resi_id: any;
  status: any;
  payment: any;
  payout: any = 0;
  popia_cost: any = 200;
  
  referForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
  });

  constructor(private apiService: ApiService, private cookieService: CookieService, private router: Router) { }

  ngOnInit(): void {    
    const user_email = this.cookieService.get('bpc-email');
    this.apiService.checkConsent(80, user_email).subscribe(
      (res: any) => {
        if(res.length > 0){
          this.cookieService.set('bpc-consent', '1');
        } else {
          this.router.navigate(['/portal/profile']);
        }
      },
      err => console.log(err)
    );
    this.apiService.getProfile().subscribe(
      (res: any) => {
        if(res[0].profile_completed == 0){
          this.router.navigate(['/portal/profile']);
        }
      },
      error => console.log(error)
    )
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.apiService.getMyRefCode().subscribe(
      (res: any) => {
      },
      error => console.log(error)
    );
    if(this.referral_code){
      this.apiService.getResellersPrices(this.referral_code).subscribe(
        (result: any) => {
          if(result.length > 0){
            this.popia_cost = result[0].popia_cost;
          }
        },
        error => console.log(error)
      )
    } else {
      this.apiService.getMyRefCode().subscribe(
        (res: any) => {
          const code = res[0];
          this.cookieService.set('bpc-ref', code.ref_code);
          if(isNaN(code.ref_code[0])){
            this.cookieService.set('bpc-reseller', '1');
          }
        },
        error => console.log(error)
      );
    }
    this.apiService.getMyReferrals().subscribe(
      (res: any) => {
        this.referrals = res;
        for(let child of this.referrals){
          this.apiService.getUserID(child.referred).subscribe(
            (result: any) => {
              if(result.length > 0){
                this.apiService.listCompaniesWithID(result[0].id).subscribe(
                  (result: any) => {
                    if(result && result.length > 0){
                      for(let res of result){
                        this.payment = Number(res.req_payment) - Number(this.popia_cost);
                        if(this.payment > 0 && res.is_subscribed == 1){
                          this.payout = this.payout + this.payment;
                        }
                        const date = new Date(res.renew_date).getTime();
                        if(this.status != 2){
                          if((date) && (date > this.currentDateLogic) && (res.is_subscribed == 1)){
                            this.status = 2;
                          } else if (date < this.currentDateLogic || res.is_subscribed == 0){
                            this.status = 3;
                          }
                        }
                      }
                      this.formatted_referrals.push({referred: child.referred, time: child.time, status: this.status, userid: result[0].id, payment: this.payout})
                      this.status = 0;
                      this.payout = 0;
                    } else {
                      this.formatted_referrals.push({referred: child.referred, time: child.time, status: child.status, userid: '', payment: 0})
                    }
                  },
                  error => console.log(error)
                );
              } else {
                this.formatted_referrals.push({referred: child.referred, time: child.time, status: child.status, userid: '', payment: 0})
              }
            },
            error => console.log(error)
            );
          }
      },
      error => console.log(error)
    );
  }

  sendReferral(){
    for(let child of this.referrals){
      if(child.referred == this.referForm.value.email){
        this.email_used = true;
      }
    }
    if(this.referForm.invalid){
      if(this.referForm.value.email == ''){
        this.empty_email = true;
      }
    } else {
      if(!this.email_used){
        this.apiService.listOfReferrals().subscribe(
          (res: any) => {
            if(res && res.length > 0){
              const ref: any = res[res.length-1].code;
      
              if(isNaN(ref)){
                this.generated_code = ref.replace(this.referral_code,'');
                const calc = Number(this.generated_code) + 1;
                this.generated_code = this.referral_code + calc;
              } else{
                this.generated_code = this.referral_code;
              }
            } else {
              if(isNaN(this.referral_code[0])){
                this.generated_code = this.referral_code + '1';
              } else {
                this.generated_code = this.referral_code;
              }
            }
            this.apiService.newReferral(this.referForm.value.email, this.currentDate, this.generated_code).subscribe(
              (res: any) => {
                this.successfully_sent = true;
                this.referForm.patchValue({
                  email: '',
                })
                this.apiService.getMyReferrals().subscribe(
                  (res: any) => {
                    this.referrals = res.reverse();
                  },
                  error => console.log(error)
                );
              },
              error => console.log(error)
            );
          },
          error => console.log(error)
        );
      }      
    }
  }

}
