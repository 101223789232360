import { ConsentFormComponent } from './consent_form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';


import { Routes, RouterModule } from '@angular/router';



const routes: Routes = [
  {path: 'consent_form/:id', component: ConsentFormComponent},
  {path: 'consent_form/', component: ConsentFormComponent},
];

@NgModule({
  declarations: [
    ConsentFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    
    RouterModule.forChild(routes),
    
  ],
  exports: [
    RouterModule,
  ],
  providers: [],
})
export class ConsentFormModule { }
