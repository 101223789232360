<div class="main">
    <div [ngClass]="this.device == 1 ? 'statsDiv_offwhite_mobile' : 'statsDiv_offwhite'">
        <div class="stats">
            <p class="statsText">Active Plugin Installations</p>
            <p class="stat">{{active_plugins}}</p>
        </div>
        <div class="stats">
            <p class="statsText">Active Pro Users</p>
            <p class="stat">{{active_pro_users}}</p>
        </div>
        <div class="stats">
            <p class="statsText">Active Consents</p>
            <p class="stat">{{active_consents}}</p>
        </div>
        <div class="stats">
            <p class="statsText">Data Requests</p>
            <p class="stat">{{active_requests}}</p>
        </div>
    </div>
</div>