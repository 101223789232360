import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { SignaturePad } from 'angular2-signaturepad';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-view-consent',
  templateUrl: './view_consent.component.html',
  styleUrls: ['./view_consent.component.scss']
})
export class ViewConsentComponent implements OnInit {
  device = 0;
  consentDetails:any;
  consentId:any;
  page_id:any;
  form_details: any;
  title = 'Angular signature example';
  signatureImg: any;
  signatureImg2: any;
  id_number: any;
  name: any;
  base64: any;
  secret: any;


  @Input() domain: any;
  @Output() goBackEm = new EventEmitter();

  consequences = [
    { id: 1, name: "We will not be able to fullfill your order unless consent is provided." },
    { id: 2, name: "We will not be able to process your request unless consent is provided" },
    { id: 2, name: "We shall fullfill your order and therafter delete your account from our system, unless consent is provided." },
    { id: 3, name: "We shall retain your data indefinitely, and you shall not be able to request data from our site due to authentication issues unless you first  provide consent." }
  ]
  data_obtained = [
    { id: 1, name: "Voluntarily supplied by the DATA SUBJECT." },
    { id: 2, name: "Provided by third parties" },
    { id: 2, name: "Both supplied by DATA SUBJECT and third parties." }
  ]
  required_reason = [
    { id: 1, name: "Without this information we are not able to fullfill your request." },
    { id: 2, name: "With out this information we are not able to process your order" }
  ]
  other_parties:any;
  other_parties_names:any = '';
  complete:any = 0;
  url: any;
  currentDate: any;
  user_id: any;
  user_email: any;
  consent_id: any = 0;
  slc: any = '';
  mc: any = '';
  message: any = '';
  signed_date: any = '';
  other = true;

  consentForm = new FormGroup({
    name: new FormControl('', Validators.required),
    id_number: new FormControl('', Validators.required),
    signed_at: new FormControl('', Validators.required),
    first_signature: new FormControl(''),
    second_signature: new FormControl(''),

    slc_telephone: new FormControl(''),
    slc_sms: new FormControl(''),
    slc_email: new FormControl(''),
    slc_whatsapp: new FormControl(''),
    slc_telegram: new FormControl(''),
    slc_messenger: new FormControl(''),
    slc_other: new FormControl(''),

    mc_telephone: new FormControl(''),
    mc_sms: new FormControl(''),
    mc_email: new FormControl(''),
    mc_whatsapp: new FormControl(''),
    mc_telegram: new FormControl(''),
    mc_messenger: new FormControl(''),
    mc_other: new FormControl(''),

  })
  errors = false;
  do_not_allow = false;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void { 
    this.currentDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }  
    
    this.route.params.subscribe(event => {
      this.consentId = event.domain;
      if(event.email && event.secret){
        this.user_email = event.email;
        this.secret = event.secret;
      }
    });
    this.getConsentForm(this.consentId)

  }
 

  getConsentForm(domain: any){
    this.apiService.getConsent(this.user_email, this.secret).subscribe(
      (result: any) => {
        for(let child of result){
          this.signatureImg2 = child.signature
          this.signed_date = child.uploaded_date
          this.slc = child.service_comm
          this.mc = child.marketing_comm
          this.consentForm.patchValue({
            name: child.consent_full_name,
            id_number: child.consent_id_number,
            signed_at: child.signed_at
          })
        }
      },
      error => console.log(error)
    );
    this.apiService.getConsentFormFromDomain(domain).subscribe(
      (result: any) => {
        this.consentDetails = result;
        for(let child of this.consentDetails){
          this.page_id = child.id;
          this.form_details = child;
          this.apiService.checkConsent(this.page_id, this.user_email).subscribe(
            (result: any) => {
              for(let child of result){
                if(child){
                  this.consent_id = child.id
                }
              }
            },
            error => console.log(error)
          );
          if(child.consent_form_complete == 1){
            this.complete += 1;
          }
          if(child.other_parties != null){
            this.complete += 1;
          }
          if(child.domain_form_complete == 1){
            this.complete += 1;
          }
          if(child.other_parties != null && child.other_parties != ''){
            this.other_parties = child.other_parties.split(',')
            for(let party of this.other_parties){
              this.apiService.getThirdPartiesNames(party).subscribe(
                (res: any) => {
                  for(let result of res){
                    let res = result.trading_name + ', '
                    this.other_parties_names += res
                  }
                },
                error => console.log(error)
              );
            }
          }
        }
      },
      error => console.log(error)
    );
  }
  printPage() {
    window.print();
  }
}
