<div [ngClass]="this.device == 1 ? 'layout_mobile' : 'layout'">
    <h1 id="profileHeading">PROFILE</h1>
    <div class="alert alert-success" id="success" *ngIf="this.success">
        Your profile has been<strong> successfully </strong>updated!
    </div>
    <div class="alert alert-danger" id="failed" *ngIf="this.failed">
        There was an <strong>error </strong>trying to update your profile, please try again.
    </div>
    <div class="alert alert-warning" id="failed">
        By default the head or CEO of an entity is deemed the data officer. A deputy officer may be appointed to handle the day to day operations and duties on behalf of the data officer. Note however that the head of the entity remains liable for the actions of the deputy information officer.
    </div>
    <div class="centerText" *ngIf="consent_required">
        <a href="https://manageconsent.co.za/#/consent/bepopiacompliant.co.za/{{email}}" target="_blank"><button class="btn btn-danger" style="float: none!important;background: #B61F21!important;border-color: #B61F21!important;">Provide consent to Be POPIA Compliant</button></a>
    </div><br><br>
    <form [formGroup]="profileForm" (ngSubmit)="saveForm()">
        <h4 class="centerText">Data Officer</h4>
        <div [ngClass]="this.device == 1 ? 'profileWrap_mobile' : 'profileWrap'">
            <div>
                <label>First Name</label>
                <input type="text" formControlName="do_first_name" class="roundedInputs"/><br> 
            </div>
            <div>
                <label>Middle Name</label>
                <input type="text" formControlName="do_middle_name" class="roundedInputs"/><br>
            </div>
            <div>
                <label>Surname</label>
                <input type="text" formControlName="do_surname" class="roundedInputs"/><br>
            </div>
            <div>
                <label>Nickname</label>
                <input type="text" formControlName="do_nickname" class="roundedInputs"/><br>
            </div>
            <div>
                <label>Designation In Organisation</label>
                <input type="text" formControlName="do_designation_in_org" class="roundedInputs"/><br>
            </div>
            <div>
                <label>Cell</label>
                <input type="text" formControlName="do_cell" class="roundedInputs"/><br>
            </div>
            <div>
                <label>Email</label>
                <input type="text" formControlName="do_direct_email" class="roundedInputs"/><br>
            </div>            
        </div><br><br>
        <br><br>
        <button class="btn btn-danger" type="submit">SAVE</button>
    </form><br><br>
    <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
        <h1 class="centerText">Change Password</h1>
        
        <div class="alert alert-success centerText" id="failed" *ngIf="this.password_changed">
            <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.password_changed = false"></i> 
            Your password has been changed successfully.
         </div>
        <div [ngClass]="this.device == 1 ? 'profileWrap_mobile' : 'profileWrap'">
            <div>
                <label>Old Password</label>
                <input type="text" formControlName="old_password" class="roundedInputs"/><br> 
            </div>
            <div>
                <label>New Password</label>
                <input type="text" formControlName="new_password" class="roundedInputs"/><br>
            </div>
        </div><br>
            
        <button class="btn btn-danger" type="submit">SAVE</button>
    </form>    
</div>