import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-consent-form',
  templateUrl: './consent_form.component.html',
  styleUrls: ['./consent_form.component.scss']
})
export class ConsentFormComponent implements OnInit {
  device = 0;
  consentDetails:any;
  consentId:any;
  page_id:any;
  form_details: any;

  @Input() domain: any;
  @Output() goBackEm = new EventEmitter();

  consequences = [
    { id: 1, name: "We will not be able to fullfill your order unless consent is provided." },
    { id: 2, name: "We will not be able to process your request unless consent is provided" },
    { id: 2, name: "We shall fullfill your order and therafter delete your account from our system, unless consent is provided." },
    { id: 3, name: "We shall retain your data indefinitely, and you shall not be able to request data from our site due to authentication issues unless you first  provide consent." }
  ]
  data_obtained = [
    { id: 1, name: "Voluntarily supplied by the DATA SUBJECT." },
    { id: 2, name: "Provided by third parties" },
    { id: 2, name: "Both supplied by DATA SUBJECT and third parties." }
  ]
  required_reason = [
    { id: 1, name: "Without this information we are not able to fullfill your request." },
    { id: 2, name: "With out this information we are not able to process your order" }
  ]
  other_parties:any;
  other_parties_names:any = '';
  complete:any = 0;
  url: any;
  retain_date: any;
  marketing_clicked: any = [];
  comms_clicked: any = [];

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void { 
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }  
    this.url = window.location.origin;
    if(this.domain){
      this.consentId = this.domain.id
    } else {
      this.route.params.subscribe(event => {
        this.consentId = event.id;
      });
    }
    this.getConsentForm(this.consentId)
  }
  getConsentForm(id: any){
    this.apiService.getConsentForm(id).subscribe(
      (result: any) => {
        this.consentDetails = result;
        if(this.consentDetails[0].retain_date){
          var theDate = new Date(this.consentDetails[0].retain_date * 1000);
          let retain_date_format = theDate.toUTCString();
          this.retain_date = formatDate(retain_date_format, 'MMMM d, y', 'en');
        }
        for(let child of this.consentDetails){
          this.page_id = child.id;
          this.form_details = child;
          if(child.consent_form_complete == 1){
            this.complete += 1;
          }
          if(child.other_parties != null){
            this.complete += 1;
          }
          if(child.domain_form_complete == 1){
            this.complete += 1;
          }

          if(child.other_parties != null && child.other_parties != ''){
            this.other_parties = child.other_parties.split(',')
            for(let party of this.other_parties){
              this.apiService.getThirdPartiesNames(party).subscribe(
                (res: any) => {
                  for(let result of res){
                    let res = result.trading_name + ', '
                    this.other_parties_names += res
                  }
                },
                error => console.log(error)
              );
            }
          }
        }
      },
      error => console.log(error)
    );
  }
  printPage() {
    window.print();
  }
  goBack(){
    this.router.navigate(['/portal']);
  }
  addCommunicationMediums(val: any){    
    if(this.comms_clicked.includes(val)){
      this.comms_clicked = this.comms_clicked.filter((item:any) => item !== val);      
    } else {
      this.comms_clicked.push(val);
    }
  }
  addMarketingMediums(val: any){    
    if(this.marketing_clicked.includes(val)){
      this.marketing_clicked = this.marketing_clicked.filter((item:any) => item !== val);      
    } else {
      this.marketing_clicked.push(val);
    }
  }
}
