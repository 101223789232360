import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-company-keys',
  templateUrl: './company_keys.component.html',
  styleUrls: ['./company_keys.component.scss']
})
export class CompanyKeysComponent implements OnInit {
  device = 0;
  id = null;
  company_key:any;
  api_key:any;
  disabled = true;
  company_domain = true;

  @Input() set domain(val: any){
    this.id = val.id;
    this.company_domain = val.company_domain;
  };

  @Output() goBackEm = new EventEmitter();
  
  constructor(private apiService: ApiService, private cookieService: CookieService, private clipboardService: ClipboardService) { }

  ngOnInit(): void {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.apiService.getKeys(this.id).subscribe(
      (result:any) => {
        this.company_key = result.company_api_key;
        this.apiService.getApiKey(result.user).subscribe(
          (res:any) => {
            this.api_key = res[0].key;
          },
          error => console.log(error)
        );
      },
      error => console.log(error)
    );
  }
  copyCompanyKey() {
    this.clipboardService.copyFromContent(this.company_key)
  }
  copyApiKey() {
    this.clipboardService.copyFromContent(this.api_key)
  }
  goBack(){
    this.goBackEm.emit("back");
  }
}
