import { PrivacyPolicyModule } from './privacy_policy/privacy_policy.module';
import { ConsentFormModule } from './consent_form/consent_form.module';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AuthModule } from './auth/auth.module';
import { MainModule } from './main/main.module';
import { PortalModule } from './portal/portal.module';
import { DetailsModule } from './details/details.module';
import { RegulatorModule } from './regulator/regulator.module';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'main'},
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    AuthModule,
    MainModule,
    DetailsModule,
    RegulatorModule,
    PortalModule,
    ConsentFormModule,
    PrivacyPolicyModule,
    HttpClientModule,
    NgbModule,
    ClipboardModule,
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [
    RouterModule,
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
