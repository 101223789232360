<div class="centerText" *ngIf="successfully_reset_password">
  <i class="icon-check-circle-o" [ngClass]="this.device == 1 ? 'success_mobile' : 'success'"></i>
  <h5>Your password has been reset Successfully, you can now login to your account<br><br>
    <button routerLink="/auth/" class="btn btn-danger">Login</button>
  </h5>
</div>
<div  *ngIf="!successfully_reset_password">
  <h1 class="centerText">Reset Password</h1><br>
  <form [formGroup]="resetPasswordForm" (ngSubmit)="saveForm()">
    
    <div class="alert alert-danger centerText" id="failed" *ngIf="this.passwords_not_matching">
      <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.passwords_not_matching = false"></i><br> 
      Please make sure that both your passwords match.
    </div>
    <div class="alert alert-danger centerText" id="failed" *ngIf="this.unsuccessfully_reset_password">
      <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.unsuccessfully_reset_password = false"></i><br> 
      <ul>
        <li>Please make sure that both your passwords match.</li>
        <li>Password must be atleast 8 characters long.</li>
        <li>Password must not be common.</li>
        <li>Password must contain letters.</li>
      </ul>
    </div>
    <div>
      <label>New Password</label><br>
      <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password_one" class="roundedInputs"/>
      <i style="margin-left: -25px!important;font-size: 1.2rem;" [ngClass]="{'icon-eye-disabled': !fieldTextType, 'icon-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
    </div>
  
    <div>
      <label>Confirm New Password</label><br>
      <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password_two" class="roundedInputs"/>
      <i style="margin-left: -25px!important;font-size: 1.2rem;" [ngClass]="{'icon-eye-disabled': !fieldTextType, 'icon-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
    </div>
    <ul>
      <li>Password must be atleast 8 characters long.</li>
      <li>Password must not be common.</li>
      <li>Password must contain letters.</li>
    </ul>
      
    <button type="submit" class="btn btn-danger cta">
      Reset Password
    </button>
  </form>
</div>  