<h1 class="centerText">Responsible Parties</h1>
<h6 class="centerText">These parties may have access to your information via <span *ngIf="domain">{{domain}}</span><span *ngIf="!domain">bepopiacompliant.co.za</span></h6>

<div class="companyDetailsWrap">
    <div class="detailWrap" *ngIf="domainDetails == '' && bepopiacomplaint != ''">
        <div>
            <img width="100" height="100" src="{{bepopiacomplaint.company_logo}}"  class="images">
        </div>
        <h5>Company Details</h5>
        <div class="detailsWrap" >
            <div>
                <span class="title">Name:</span> {{bepopiacomplaint.company_name}}
                <span *ngIf="bepopiacomplaint.company_name == '' || bepopiacomplaint.company_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Reg:</span> {{bepopiacomplaint.company_reg}}
                <span *ngIf="bepopiacomplaint.company_reg == '' || bepopiacomplaint.company_reg == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Domain:</span> {{bepopiacomplaint.company_domain}}
                <span *ngIf="bepopiacomplaint.company_domain == '' || bepopiacomplaint.company_domain == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Trading Name:</span> {{bepopiacomplaint.trading_name}}
                <span *ngIf="bepopiacomplaint.trading_name == '' || bepopiacomplaint.trading_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Tax Number:</span> {{bepopiacomplaint.tax_number}}
                <span *ngIf="bepopiacomplaint.tax_number == '' || bepopiacomplaint.tax_number == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Type Of Body:</span> 
                <span *ngIf="bepopiacomplaint.type_of_body == 1 || bepopiacomplaint.type_of_body == 'Public Body'"> Public Body</span>
                <span *ngIf="bepopiacomplaint.type_of_body == 2 || bepopiacomplaint.type_of_body == 'Private Body'"> Private Body</span>
            </div>
            <div>
                <span class="title">Entity Type:</span> 
                <span *ngIf="bepopiacomplaint.entity_type == 1 || bepopiacomplaint.entity_type == 'Sole Proprietorship'"> Sole Proprietorship</span>
                <span *ngIf="bepopiacomplaint.entity_type == 2 || bepopiacomplaint.entity_type == 'Partnership'"> Partnership</span>
                <span *ngIf="bepopiacomplaint.entity_type == 3 || bepopiacomplaint.entity_type == '(PTY) Ltd'"> (PTY) Ltd</span>
                <span *ngIf="bepopiacomplaint.entity_type == 4 || bepopiacomplaint.entity_type == 'Public Company'"> Public Company</span>
                <span *ngIf="bepopiacomplaint.entity_type == 5 || bepopiacomplaint.entity_type == 'Non-Profit'"> Non-Profit</span>
            </div>
            <div *ngIf="bepopiacomplaint.office_number || bepopiacomplaint.direct_email"><br>
                <div style="text-align: center;">
                    <button class="btn btn-danger" style="line-height:12px;" (click)="show(bepopiacomplaint.id)">
                        <span *ngIf="!showContactDetails.includes(bepopiacomplaint.id)">
                            Show Contact Details
                        </span>
                        <span *ngIf="showContactDetails.includes(bepopiacomplaint.id)">
                            Hide Contact Details
                        </span>
                    </button>
                </div>
                <div *ngIf="showContactDetails.includes(bepopiacomplaint.id)"><br>
                    <span *ngIf="bepopiacomplaint.office_number" class="title">Contact Number:</span>&nbsp;
                    <a href="tel:{{bepopiacomplaint.office_number}}" *ngIf="bepopiacomplaint.office_number">{{bepopiacomplaint.office_number}}</a><br>
                    <span *ngIf="bepopiacomplaint.direct_email" class="title">Contact Email:</span>&nbsp;
                    <a href="mailto:{{bepopiacomplaint.direct_email}}" *ngIf="bepopiacomplaint.direct_email">{{bepopiacomplaint.direct_email}}</a>             
                </div>
            </div>
        </div>
        <br>
        <h5>Physical Address</h5>
        <div class="detailsWrap">
            <span class="title">Street Number:</span> {{bepopiacomplaint.ph_street_number}}
            <span *ngIf="bepopiacomplaint.ph_street_number == '' || bepopiacomplaint.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{bepopiacomplaint.ph_street_name}}
            <span *ngIf="bepopiacomplaint.ph_street_name == '' || bepopiacomplaint.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{bepopiacomplaint.ph_suburb}}
            <span *ngIf="bepopiacomplaint.ph_suburb == '' || bepopiacomplaint.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{bepopiacomplaint.ph_town_city}}
            <span *ngIf="bepopiacomplaint.ph_town_city == '' || bepopiacomplaint.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{bepopiacomplaint.ph_province}}
            <span *ngIf="bepopiacomplaint.ph_province == '' || bepopiacomplaint.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{bepopiacomplaint.ph_postal_code}}
            <span *ngIf="bepopiacomplaint.ph_postal_code == '' || bepopiacomplaint.ph_postal_code == null">Unavailable</span><br>
        </div>
        <br>
        <h5>Postal Address</h5>
        <div class="detailsWrap" *ngIf="bepopiacomplaint.postal_same_as_physical == 0">
            <span class="title">Street Number:</span> {{bepopiacomplaint.po_street_number}}
            <span *ngIf="bepopiacomplaint.po_street_number == '' || bepopiacomplaint.po_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{bepopiacomplaint.po_street_name}}
            <span *ngIf="bepopiacomplaint.po_street_name == '' || bepopiacomplaint.po_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{bepopiacomplaint.po_suburb}}
            <span *ngIf="bepopiacomplaint.po_suburb == '' || bepopiacomplaint.po_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{bepopiacomplaint.po_town_city}}
            <span *ngIf="bepopiacomplaint.po_town_city == '' || bepopiacomplaint.po_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{bepopiacomplaint.po_province}}
            <span *ngIf="bepopiacomplaint.po_province == '' || bepopiacomplaint.po_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{bepopiacomplaint.po_postal_code}}
            <span *ngIf="bepopiacomplaint.po_postal_code == '' || bepopiacomplaint.po_postal_code == null">Unavailable</span><br>
        </div>
        <div class="detailsWrap" *ngIf="bepopiacomplaint.postal_same_as_physical == 1">
            <span class="title">Street Number:</span> {{bepopiacomplaint.ph_street_number}}
            <span *ngIf="bepopiacomplaint.ph_street_number == '' || bepopiacomplaint.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{bepopiacomplaint.ph_street_name}}
            <span *ngIf="bepopiacomplaint.ph_street_name == '' || bepopiacomplaint.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{bepopiacomplaint.ph_suburb}}
            <span *ngIf="bepopiacomplaint.ph_suburb == '' || bepopiacomplaint.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{bepopiacomplaint.ph_town_city}}
            <span *ngIf="bepopiacomplaint.ph_town_city == '' || bepopiacomplaint.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{bepopiacomplaint.ph_province}}
            <span *ngIf="bepopiacomplaint.ph_province == '' || bepopiacomplaint.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{bepopiacomplaint.ph_postal_code}}
            <span *ngIf="bepopiacomplaint.ph_postal_code == '' || bepopiacomplaint.ph_postal_code == null">Unavailable</span><br>
        </div>
    </div>
    <div class="detailWrap" *ngIf="domainDetails == '' && bepopiacomplainthosting != ''">
        <div>
            <img width="100" height="100" src="{{bepopiacomplainthosting.company_logo}}"  class="images">
        </div>
        <h5>Company Details</h5>
        <div class="detailsWrap">
            <div>
                <span class="title">Name:</span> {{bepopiacomplainthosting.company_name}}
                <span *ngIf="bepopiacomplainthosting.company_name == '' || bepopiacomplainthosting.company_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Reg:</span> {{bepopiacomplainthosting.company_reg}}
                <span *ngIf="bepopiacomplainthosting.company_reg == '' || bepopiacomplainthosting.company_reg == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Domain:</span> {{bepopiacomplainthosting.company_domain}}
                <span *ngIf="bepopiacomplainthosting.company_domain == '' || bepopiacomplainthosting.company_domain == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Trading Name:</span> {{bepopiacomplainthosting.trading_name}}
                <span *ngIf="bepopiacomplainthosting.trading_name == '' || bepopiacomplainthosting.trading_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Tax Number:</span> {{bepopiacomplainthosting.tax_number}}
                <span *ngIf="bepopiacomplainthosting.tax_number == '' || bepopiacomplainthosting.tax_number == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Type Of Body:</span> 
                <span *ngIf="bepopiacomplainthosting.type_of_body == 1 || bepopiacomplainthosting.type_of_body == 'Public Body'"> Public Body</span>
                <span *ngIf="bepopiacomplainthosting.type_of_body == 2 || bepopiacomplainthosting.type_of_body == 'Private Body'"> Private Body</span>
            </div>
            <div>
                <span class="title">Entity Type:</span> 
                <span *ngIf="bepopiacomplainthosting.entity_type == 1 || bepopiacomplainthosting.entity_type == 'Sole Proprietorship'"> Sole Proprietorship</span>
                <span *ngIf="bepopiacomplainthosting.entity_type == 2 || bepopiacomplainthosting.entity_type == 'Partnership'"> Partnership</span>
                <span *ngIf="bepopiacomplainthosting.entity_type == 3 || bepopiacomplainthosting.entity_type == '(PTY) Ltd'"> (PTY) Ltd</span>
                <span *ngIf="bepopiacomplainthosting.entity_type == 4 || bepopiacomplainthosting.entity_type == 'Public Company'"> Public Company</span>
                <span *ngIf="bepopiacomplainthosting.entity_type == 5 || bepopiacomplainthosting.entity_type == 'Non-Profit'"> Non-Profit</span>
            </div>
            <div *ngIf="bepopiacomplainthosting.office_number || bepopiacomplainthosting.direct_email"><br>
                <div style="text-align: center;">
                    <button class="btn btn-danger" style="line-height:12px;" (click)="show(bepopiacomplainthosting.id)">
                        <span *ngIf="!showContactDetails.includes(bepopiacomplainthosting.id)">
                            Show Contact Details
                        </span>
                        <span *ngIf="showContactDetails.includes(bepopiacomplainthosting.id)">
                            Hide Contact Details
                        </span>
                    </button>
                </div>
                <div *ngIf="showContactDetails.includes(bepopiacomplainthosting.id)"><br>
                    <span *ngIf="bepopiacomplainthosting.office_number" class="title">Contact Number:</span>&nbsp;
                    <a href="tel:{{bepopiacomplainthosting.office_number}}" *ngIf="bepopiacomplainthosting.office_number">{{bepopiacomplainthosting.office_number}}</a><br>
                    <span *ngIf="bepopiacomplainthosting.direct_email" class="title">Contact Email:</span>&nbsp;
                    <a href="mailto:{{bepopiacomplainthosting.direct_email}}" *ngIf="bepopiacomplainthosting.direct_email">{{bepopiacomplainthosting.direct_email}}</a>
                </div>
            </div>
        </div>
        <br>
        <h5>Physical Address</h5>
        <div class="detailsWrap">
            <span class="title">Street Number:</span> {{bepopiacomplainthosting.ph_street_number}}
            <span *ngIf="bepopiacomplainthosting.ph_street_number == '' || bepopiacomplainthosting.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{bepopiacomplainthosting.ph_street_name}}
            <span *ngIf="bepopiacomplainthosting.ph_street_name == '' || bepopiacomplainthosting.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{bepopiacomplainthosting.ph_suburb}}
            <span *ngIf="bepopiacomplainthosting.ph_suburb == '' || bepopiacomplainthosting.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{bepopiacomplainthosting.ph_town_city}}
            <span *ngIf="bepopiacomplainthosting.ph_town_city == '' || bepopiacomplainthosting.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{bepopiacomplainthosting.ph_province}}
            <span *ngIf="bepopiacomplainthosting.ph_province == '' || bepopiacomplainthosting.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{bepopiacomplainthosting.ph_postal_code}}
            <span *ngIf="bepopiacomplainthosting.ph_postal_code == '' || bepopiacomplainthosting.ph_postal_code == null">Unavailable</span><br>
        </div>
        <br>
        <h5>Postal Address</h5>
        <div class="detailsWrap" *ngIf="bepopiacomplainthosting.postal_same_as_physical == 0">
            <span class="title">Street Number:</span> {{bepopiacomplainthosting.po_street_number}}
            <span *ngIf="bepopiacomplainthosting.po_street_number == '' || bepopiacomplainthosting.po_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{bepopiacomplainthosting.po_street_name}}
            <span *ngIf="bepopiacomplainthosting.po_street_name == '' || bepopiacomplainthosting.po_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{bepopiacomplainthosting.po_suburb}}
            <span *ngIf="bepopiacomplainthosting.po_suburb == '' || bepopiacomplainthosting.po_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{bepopiacomplainthosting.po_town_city}}
            <span *ngIf="bepopiacomplainthosting.po_town_city == '' || bepopiacomplainthosting.po_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{bepopiacomplainthosting.po_province}}
            <span *ngIf="bepopiacomplainthosting.po_province == '' || bepopiacomplainthosting.po_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{bepopiacomplainthosting.po_postal_code}}
            <span *ngIf="bepopiacomplainthosting.po_postal_code == '' || bepopiacomplainthosting.po_postal_code == null">Unavailable</span><br>
        </div>
        <div class="detailsWrap" *ngIf="bepopiacomplainthosting.postal_same_as_physical == 1">
            <span class="title">Street Number:</span> {{bepopiacomplainthosting.ph_street_number}}
            <span *ngIf="bepopiacomplainthosting.ph_street_number == '' || bepopiacomplainthosting.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{bepopiacomplainthosting.ph_street_name}}
            <span *ngIf="bepopiacomplainthosting.ph_street_name == '' || bepopiacomplainthosting.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{bepopiacomplainthosting.ph_suburb}}
            <span *ngIf="bepopiacomplainthosting.ph_suburb == '' || bepopiacomplainthosting.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{bepopiacomplainthosting.ph_town_city}}
            <span *ngIf="bepopiacomplainthosting.ph_town_city == '' || bepopiacomplainthosting.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{bepopiacomplainthosting.ph_province}}
            <span *ngIf="bepopiacomplainthosting.ph_province == '' || bepopiacomplainthosting.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{bepopiacomplainthosting.ph_postal_code}}
            <span *ngIf="bepopiacomplainthosting.ph_postal_code == '' || bepopiacomplainthosting.ph_postal_code == null">Unavailable</span><br>
        </div>
    </div>
    <div class="detailWrap" *ngIf="domainDetails == '' && bepopiacomplaintpayment != ''">
        <div>
            <img width="100" height="100" src="{{bepopiacomplaintpayment.company_logo}}"  class="images">
        </div>
        <h5>Company Details</h5>
        <div class="detailsWrap">
            <div>
                <span class="title">Name:</span> {{bepopiacomplaintpayment.company_name}}
                <span *ngIf="bepopiacomplaintpayment.company_name == '' || bepopiacomplaintpayment.company_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Reg:</span> {{bepopiacomplaintpayment.company_reg}}
                <span *ngIf="bepopiacomplaintpayment.company_reg == '' || bepopiacomplaintpayment.company_reg == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Domain:</span> {{bepopiacomplaintpayment.company_domain}}
                <span *ngIf="bepopiacomplaintpayment.company_domain == '' || bepopiacomplaintpayment.company_domain == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Trading Name:</span> {{bepopiacomplaintpayment.trading_name}}
                <span *ngIf="bepopiacomplaintpayment.trading_name == '' || bepopiacomplaintpayment.trading_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Tax Number:</span> {{bepopiacomplaintpayment.tax_number}}
                <span *ngIf="bepopiacomplaintpayment.tax_number == '' || bepopiacomplaintpayment.tax_number == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Type Of Body:</span> 
                <span *ngIf="bepopiacomplaintpayment.type_of_body == 1 || bepopiacomplaintpayment.type_of_body == 'Public Body'"> Public Body</span>
                <span *ngIf="bepopiacomplaintpayment.type_of_body == 2 || bepopiacomplaintpayment.type_of_body == 'Private Body'"> Private Body</span>
            </div>
            <div>
                <span class="title">Entity Type:</span> 
                <span *ngIf="bepopiacomplaintpayment.entity_type == 1 || bepopiacomplaintpayment.entity_type == 'Sole Proprietorship'"> Sole Proprietorship</span>
                <span *ngIf="bepopiacomplaintpayment.entity_type == 2 || bepopiacomplaintpayment.entity_type == 'Partnership'"> Partnership</span>
                <span *ngIf="bepopiacomplaintpayment.entity_type == 3 || bepopiacomplaintpayment.entity_type == '(PTY) Ltd'"> (PTY) Ltd</span>
                <span *ngIf="bepopiacomplaintpayment.entity_type == 4 || bepopiacomplaintpayment.entity_type == 'Public Company'"> Public Company</span>
                <span *ngIf="bepopiacomplaintpayment.entity_type == 5 || bepopiacomplaintpayment.entity_type == 'Non-Profit'"> Non-Profit</span>
            </div>
            <div *ngIf="bepopiacomplaintpayment.office_number || bepopiacomplaintpayment.direct_email"><br>
                <div style="text-align: center;">
                    <button class="btn btn-danger" style="line-height:12px;" (click)="show(bepopiacomplaintpayment.id)">
                        <span *ngIf="!showContactDetails.includes(bepopiacomplaintpayment.id)">
                            Show Contact Details
                        </span>
                        <span *ngIf="showContactDetails.includes(bepopiacomplaintpayment.id)">
                            Hide Contact Details
                        </span>
                    </button>
                </div>
                <div *ngIf="showContactDetails.includes(bepopiacomplaintpayment.id)"><br>
                    <span *ngIf="bepopiacomplaintpayment.office_number" class="title">Contact Number:</span>&nbsp;
                    <a href="tel:{{bepopiacomplaintpayment.office_number}}" *ngIf="bepopiacomplaintpayment.office_number">{{bepopiacomplaintpayment.office_number}}</a><br>
                    <span *ngIf="bepopiacomplaintpayment.direct_email" class="title">Contact Email:</span>&nbsp;
                    <a href="mailto:{{bepopiacomplaintpayment.direct_email}}" *ngIf="bepopiacomplaintpayment.direct_email">{{bepopiacomplaintpayment.direct_email}}</a>               
                </div>
            </div>
        </div>
        <br>
        <h5>Physical Address</h5>
        <div class="detailsWrap">
            <span class="title">Street Number:</span> {{bepopiacomplaintpayment.ph_street_number}}
            <span *ngIf="bepopiacomplaintpayment.ph_street_number == '' || bepopiacomplaintpayment.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{bepopiacomplaintpayment.ph_street_name}}
            <span *ngIf="bepopiacomplaintpayment.ph_street_name == '' || bepopiacomplaintpayment.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{bepopiacomplaintpayment.ph_suburb}}
            <span *ngIf="bepopiacomplaintpayment.ph_suburb == '' || bepopiacomplaintpayment.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{bepopiacomplaintpayment.ph_town_city}}
            <span *ngIf="bepopiacomplaintpayment.ph_town_city == '' || bepopiacomplaintpayment.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{bepopiacomplaintpayment.ph_province}}
            <span *ngIf="bepopiacomplaintpayment.ph_province == '' || bepopiacomplaintpayment.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{bepopiacomplaintpayment.ph_postal_code}}
            <span *ngIf="bepopiacomplaintpayment.ph_postal_code == '' || bepopiacomplaintpayment.ph_postal_code == null">Unavailable</span><br>
        </div>
        <br>
        <h5>Postal Address</h5>
        <div class="detailsWrap" *ngIf="bepopiacomplaintpayment.postal_same_as_physical == 0">
            <span class="title">Street Number:</span> {{bepopiacomplaintpayment.po_street_number}}
            <span *ngIf="bepopiacomplaintpayment.po_street_number == '' || bepopiacomplaintpayment.po_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{bepopiacomplaintpayment.po_street_name}}
            <span *ngIf="bepopiacomplaintpayment.po_street_name == '' || bepopiacomplaintpayment.po_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{bepopiacomplaintpayment.po_suburb}}
            <span *ngIf="bepopiacomplaintpayment.po_suburb == '' || bepopiacomplaintpayment.po_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{bepopiacomplaintpayment.po_town_city}}
            <span *ngIf="bepopiacomplaintpayment.po_town_city == '' || bepopiacomplaintpayment.po_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{bepopiacomplaintpayment.po_province}}
            <span *ngIf="bepopiacomplaintpayment.po_province == '' || bepopiacomplaintpayment.po_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{bepopiacomplaintpayment.po_postal_code}}
            <span *ngIf="bepopiacomplaintpayment.po_postal_code == '' || bepopiacomplaintpayment.po_postal_code == null">Unavailable</span><br>
        </div>
        <div class="detailsWrap" *ngIf="bepopiacomplaintpayment.postal_same_as_physical == 1">
            <span class="title">Street Number:</span> {{bepopiacomplaintpayment.ph_street_number}}
            <span *ngIf="bepopiacomplaintpayment.ph_street_number == '' || bepopiacomplaintpayment.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{bepopiacomplaintpayment.ph_street_name}}
            <span *ngIf="bepopiacomplaintpayment.ph_street_name == '' || bepopiacomplaintpayment.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{bepopiacomplaintpayment.ph_suburb}}
            <span *ngIf="bepopiacomplaintpayment.ph_suburb == '' || bepopiacomplaintpayment.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{bepopiacomplaintpayment.ph_town_city}}
            <span *ngIf="bepopiacomplaintpayment.ph_town_city == '' || bepopiacomplaintpayment.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{bepopiacomplaintpayment.ph_province}}
            <span *ngIf="bepopiacomplaintpayment.ph_province == '' || bepopiacomplaintpayment.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{bepopiacomplaintpayment.ph_postal_code}}
            <span *ngIf="bepopiacomplaintpayment.ph_postal_code == '' || bepopiacomplaintpayment.ph_postal_code == null">Unavailable</span><br>
        </div>
    </div>
    <div class="detailWrap" *ngIf="domainDetails != '' && domain != 'bepopiacompliant.co.za'">
        <div>
            <img width="100" height="100" src="{{domainDetails.company_logo}}"  class="images">
        </div>
        <h5>Company Details</h5>
        <div class="detailsWrap">
            <div>
                <span class="title">Name:</span> {{domainDetails.company_name}}
                <span *ngIf="domainDetails.company_name == '' || domainDetails.company_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Reg:</span> {{domainDetails.company_reg}}
                <span *ngIf="domainDetails.company_reg == '' || domainDetails.company_reg == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Domain:</span> {{domainDetails.company_domain}}
                <span *ngIf="domainDetails.company_domain == '' || domainDetails.company_domain == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Trading Name:</span> {{domainDetails.trading_name}}
                <span *ngIf="domainDetails.trading_name == '' || domainDetails.trading_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Tax Number:</span> {{domainDetails.tax_number}}
                <span *ngIf="domainDetails.tax_number == '' || domainDetails.tax_number == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Type Of Body:</span> 
                <span *ngIf="domainDetails.type_of_body == 1 || domainDetails.type_of_body == 'Public Body'"> Public Body</span>
                <span *ngIf="domainDetails.type_of_body == 2 || domainDetails.type_of_body == 'Private Body'"> Private Body</span>
            </div>
            <div>
                <span class="title">Entity Type:</span> 
                <span *ngIf="domainDetails.entity_type == 1 || domainDetails.entity_type == 'Sole Proprietorship'"> Sole Proprietorship</span>
                <span *ngIf="domainDetails.entity_type == 2 || domainDetails.entity_type == 'Partnership'"> Partnership</span>
                <span *ngIf="domainDetails.entity_type == 3 || domainDetails.entity_type == '(PTY) Ltd'"> (PTY) Ltd</span>
                <span *ngIf="domainDetails.entity_type == 4 || domainDetails.entity_type == 'Public Company'"> Public Company</span>
                <span *ngIf="domainDetails.entity_type == 5 || domainDetails.entity_type == 'Non-Profit'"> Non-Profit</span>
            </div>
            <div *ngIf="domainDetails.office_number || domainDetails.direct_email"><br>
                <div style="text-align: center;">
                    <button class="btn btn-danger" style="line-height:12px;" (click)="show(domainDetails.id)">
                        <span *ngIf="!showContactDetails.includes(domainDetails.id)">
                            Show Contact Details
                        </span>
                        <span *ngIf="showContactDetails.includes(domainDetails.id)">
                            Hide Contact Details
                        </span>
                    </button>
                </div>
                <div *ngIf="showContactDetails.includes(domainDetails.id)"><br>
                    <span *ngIf="domainDetails.office_number" class="title">Contact Number:</span>&nbsp;
                    <a href="tel:{{domainDetails.office_number}}" *ngIf="domainDetails.office_number">{{domainDetails.office_number}}</a><br>
                    <span *ngIf="domainDetails.direct_email" class="title">Contact Email:</span>&nbsp;
                    <a href="mailto:{{domainDetails.direct_email}}" *ngIf="domainDetails.direct_email">{{domainDetails.direct_email}}</a>                                
                </div>
            </div>
        </div>
        <br>
        <h5>Physical Address</h5>
        <div class="detailsWrap">
            <span class="title">Street Number:</span> {{domainDetails.ph_street_number}}
            <span *ngIf="domainDetails.ph_street_number == '' || domainDetails.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{domainDetails.ph_street_name}}
            <span *ngIf="domainDetails.ph_street_name == '' || domainDetails.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{domainDetails.ph_suburb}}
            <span *ngIf="domainDetails.ph_suburb == '' || domainDetails.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{domainDetails.ph_town_city}}
            <span *ngIf="domainDetails.ph_town_city == '' || domainDetails.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{domainDetails.ph_province}}
            <span *ngIf="domainDetails.ph_province == '' || domainDetails.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{domainDetails.ph_postal_code}}
            <span *ngIf="domainDetails.ph_postal_code == '' || domainDetails.ph_postal_code == null">Unavailable</span><br>
        </div>
        <br>
        <h5>Postal Address</h5>
        <div class="detailsWrap" *ngIf="domainDetails.postal_same_as_physical == 0">
            <span class="title">Street Number:</span> {{domainDetails.po_street_number}}
            <span *ngIf="domainDetails.po_street_number == '' || domainDetails.po_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{domainDetails.po_street_name}}
            <span *ngIf="domainDetails.po_street_name == '' || domainDetails.po_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{domainDetails.po_suburb}}
            <span *ngIf="domainDetails.po_suburb == '' || domainDetails.po_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{domainDetails.po_town_city}}
            <span *ngIf="domainDetails.po_town_city == '' || domainDetails.po_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{domainDetails.po_province}}
            <span *ngIf="domainDetails.po_province == '' || domainDetails.po_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{domainDetails.po_postal_code}}
            <span *ngIf="domainDetails.po_postal_code == '' || domainDetails.po_postal_code == null">Unavailable</span><br>
        </div>
        <div class="detailsWrap" *ngIf="domainDetails.postal_same_as_physical == 1">
            <span class="title">Street Number:</span> {{domainDetails.ph_street_number}}
            <span *ngIf="domainDetails.ph_street_number == '' || domainDetails.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{domainDetails.ph_street_name}}
            <span *ngIf="domainDetails.ph_street_name == '' || domainDetails.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{domainDetails.ph_suburb}}
            <span *ngIf="domainDetails.ph_suburb == '' || domainDetails.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{domainDetails.ph_town_city}}
            <span *ngIf="domainDetails.ph_town_city == '' || domainDetails.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{domainDetails.ph_province}}
            <span *ngIf="domainDetails.ph_province == '' || domainDetails.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{domainDetails.ph_postal_code}}
            <span *ngIf="domainDetails.ph_postal_code == '' || domainDetails.ph_postal_code == null">Unavailable</span><br>
        </div>
    </div>
    <div class="detailWrap" *ngFor="let party of thirdPartyDetails">
        <div>
            <img width="100" height="100" src="{{party.company_logo}}"  class="images">
        </div>
        <h5>Company Details</h5>
        <div class="detailsWrap">
            <div>
                <span class="title">Name:</span> {{party.company_name}}
                <span *ngIf="party.company_name == '' || party.company_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Reg:</span> {{party.company_reg}}
                <span *ngIf="party.company_reg == '' || party.company_reg == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Domain:</span> {{party.company_domain}}
                <span *ngIf="party.company_domain == '' || party.company_domain == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Trading Name:</span> {{party.trading_name}}
                <span *ngIf="party.trading_name == '' || party.trading_name == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Tax Number:</span> {{party.tax_number}}
                <span *ngIf="party.tax_number == '' || party.tax_number == null">Unavailable</span>
            </div>
            <div>
                <span class="title">Type Of Body:</span> 
                <span *ngIf="party.type_of_body == 1 || party.type_of_body == 'Public Body'"> Public Body</span>
                <span *ngIf="party.type_of_body == 2 || party.type_of_body == 'Private Body'"> Private Body</span>
            </div>
            <div>
                <span class="title">Entity Type:</span> 
                <span *ngIf="party.entity_type == 1 || party.entity_type == 'Sole Proprietorship'"> Sole Proprietorship</span>
                <span *ngIf="party.entity_type == 2 || party.entity_type == 'Partnership'"> Partnership</span>
                <span *ngIf="party.entity_type == 3 || party.entity_type == '(PTY) Ltd'"> (PTY) Ltd</span>
                <span *ngIf="party.entity_type == 4 || party.entity_type == 'Public Company'"> Public Company</span>
                <span *ngIf="party.entity_type == 5 || party.entity_type == 'Non-Profit'"> Non-Profit</span>
            </div>
            <div *ngIf="party.office_number || party.direct_email"><br>
                <div style="text-align: center;">
                    <button class="btn btn-danger" style="line-height:12px;" (click)="show(party.id)">
                        <span *ngIf="!showContactDetails.includes(party.id)">
                            Show Contact Details
                        </span>
                        <span *ngIf="showContactDetails.includes(party.id)">
                            Hide Contact Details
                        </span>
                    </button>
                </div>
                <div *ngIf="showContactDetails.includes(party.id)"><br>
                    <span *ngIf="party.office_number" class="title">Contact Number:</span>&nbsp;
                    <a href="tel:{{party.office_number}}" *ngIf="party.office_number">{{party.office_number}}</a><br>
                    <span *ngIf="party.direct_email" class="title">Contact Email:</span>&nbsp;
                    <a href="mailto:{{party.direct_email}}" *ngIf="party.direct_email">{{party.direct_email}}</a>                
                </div>
            </div>
        </div>
        <br>
        <h5>Physical Address</h5>
        <div class="detailsWrap">
            <span class="title">Street Number:</span> {{party.ph_street_number}}
            <span *ngIf="party.ph_street_number == '' || party.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{party.ph_street_name}}
            <span *ngIf="party.ph_street_name == '' || party.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{party.ph_suburb}}
            <span *ngIf="party.ph_suburb == '' || party.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{party.ph_town_city}}
            <span *ngIf="party.ph_town_city == '' || party.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{party.ph_province}}
            <span *ngIf="party.ph_province == '' || party.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{party.ph_postal_code}}
            <span *ngIf="party.ph_postal_code == '' || party.ph_postal_code == null">Unavailable</span><br>
        </div>
        <br>
        <h5>Postal Address</h5>
        <div class="detailsWrap" *ngIf="party.postal_same_as_physical == 0">
            <span class="title">Street Number:</span> {{party.po_street_number}}
            <span *ngIf="party.po_street_number == '' || party.po_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{party.po_street_name}}
            <span *ngIf="party.po_street_name == '' || party.po_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{party.po_suburb}}
            <span *ngIf="party.po_suburb == '' || party.po_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{party.po_town_city}}
            <span *ngIf="party.po_town_city == '' || party.po_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{party.po_province}}
            <span *ngIf="party.po_province == '' || party.po_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{party.po_postal_code}}
            <span *ngIf="party.po_postal_code == '' || party.po_postal_code == null ">Unavailable</span><br>
        </div>
        <div class="detailsWrap" *ngIf="party.postal_same_as_physical == 1">
            <span class="title">Street Number:</span> {{party.ph_street_number}}
            <span *ngIf="party.ph_street_number == '' || party.ph_street_number == null">Unavailable</span><br>
            <span class="title">Street Name:</span> {{party.ph_street_name}}
            <span *ngIf="party.ph_street_name == '' || party.ph_street_name == null">Unavailable</span><br>
            <span class="title">Suburb:</span> {{party.ph_suburb}}
            <span *ngIf="party.ph_suburb == '' || party.ph_suburb == null">Unavailable</span><br>
            <span class="title">Town/City:</span> {{party.ph_town_city}}
            <span *ngIf="party.ph_town_city == '' || party.ph_town_city == null">Unavailable</span><br>
            <span class="title">Province:</span> {{party.ph_province}}
            <span *ngIf="party.ph_province == '' || party.ph_province == null">Unavailable</span><br>
            <span class="title">Postal Code:</span> {{party.ph_postal_code}}
            <span *ngIf="party.ph_postal_code == '' || party.ph_postal_code == null">Unavailable</span><br>
        </div>
    </div>
</div>