import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-regulator',
  templateUrl: './regulator.component.html',
  styleUrls: ['./regulator.component.scss']
})
export class RegulatorComponent implements OnInit {
  company_name = '';
  errors = 0;
  success = 0;
  device = 0;

  regulatorForm = new FormGroup({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    cell: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    dispute: new FormControl('', Validators.required),
    domain: new FormControl('')
  });

  constructor(private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.route.params.subscribe(event => {
      if(event['company_name']){
        this.company_name = event.company_name;
      } else{
        this.company_name = '';
      }
    });
  }
  submitForm(){
    if(this.company_name != ''){
      this.regulatorForm.patchValue({
        domain: this.company_name
      })
    } else{
      this.regulatorForm.patchValue({
        domain: 'bepopiacompliant.co.za'
      })
    }
    
    if(this.regulatorForm.invalid){
      this.errors = 1
    } else {
      var regVal = this.regulatorForm.value
      this.apiService.newDispute(regVal.name, regVal.surname, regVal.cell, regVal.email, regVal.dispute, regVal.domain).subscribe(
        res => {
          this.success = 1;
        },
        error => {
          this.errors = 1
        }
      )
    }
  }
}
