import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: any = window.location.origin
  baseUrl(){
    // if(this.url.includes('localhost') || this.url.includes('beta')){
      // return 'https://pydev.bepopiacompliant.co.za/'
    // } else {
      return 'https://py.bepopiacompliant.co.za/'
    // }
  }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private domains = ['Adscampaign', 'web-x', 'beemsly'];

  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  test(){
    const id = '13'
    const body = JSON.stringify({id});
    return this.httpClient.post(`${this.baseUrl()}api/requesthasdata/`, body, {headers: this.headers});
  }

  getYearlySub(){
    return this.httpClient.get(`${this.baseUrl()}api/yearly`, {headers: this.getAuthHeaders()});
  }
  getMonthlySub(){
    return this.httpClient.get(`${this.baseUrl()}api/monthly`, {headers: this.getAuthHeaders()});
  }  
  getYearlySubID(id:any, yearly_price: any){
    const user_id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/yearly/${id}/${yearly_price}/${user_id}/`, {headers: this.getAuthHeaders()});
  }
  getMonthlySubID(id:any, monthly_price: any){
    const user_id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/monthly/${id}/${monthly_price}/${user_id}/`, {headers: this.getAuthHeaders()});
  }
  getMonthlySubIDTest(id:any, monthly_price: any){
    return this.httpClient.get(`${this.baseUrl()}api/monthlytest/${id}/${monthly_price}/`, {headers: this.getAuthHeaders()});
  } 
  getYearlySubIDTest(id:any, yearly_price: any){
    return this.httpClient.get(`${this.baseUrl()}api/yearlytest/${id}/${yearly_price}/`, {headers: this.getAuthHeaders()});
  }
  checkMonthlySubPayment(){
    return this.httpClient.get(`${this.baseUrl()}api/monthly_pay`, {headers: this.getAuthHeaders()});
  }
  checkYearlySubPayment(){
    return this.httpClient.get(`${this.baseUrl()}api/yearly_pay`, {headers: this.getAuthHeaders()});
  }
  checkPaymentSuccessful(){
    return this.httpClient.get(`${this.baseUrl()}api/paysuccessful`, {headers: this.getAuthHeaders()});
  }  
  checkPaymentUnsuccessful(){
    return this.httpClient.get(`${this.baseUrl()}api/payunsuccessful`, {headers: this.getAuthHeaders()});
  }
  getKeys(id:any){
    return this.httpClient.get(`${this.baseUrl()}api/getkeys/${id}`, {headers: this.getAuthHeaders()});
  }
  getApiKey(id:any){
    return this.httpClient.get(`${this.baseUrl()}api/getapikeys/${id}`, {headers: this.getAuthHeaders()});
  }
  getThirdParties(id:any){
    return this.httpClient.get(`${this.baseUrl()}api/getthirdparties/${id}`, {headers: this.getAuthHeaders()});
  }
  getThirdPartiesForRP(id:any){
    return this.httpClient.get(`${this.baseUrl()}api/getthirdparty/${id}`, {headers: this.headers});
  }
  getThirdPartiesNames(id:any){
    return this.httpClient.get(`${this.baseUrl()}api/getthirdpartynames/${id}`, {headers: this.headers});
  }

  updatePaymentSuccessful(id:any, payment_checks: any){
    const body = JSON.stringify({id, payment_checks});
    return this.httpClient.put(`${this.baseUrl()}api/paysuccessful/${id}`, body, {headers: this.getAuthHeaders()});
  }
  updatePaymentUnuccessful(id:any, payment_checks: any){
    const body = JSON.stringify({id, payment_checks});
    return this.httpClient.put(`${this.baseUrl()}api/payunsuccessful/${id}`, body, {headers: this.getAuthHeaders()});
  }

  testingPHP(){
    return this.httpClient.get('http://bepopiacompliant.co.za/payment.php', {headers: this.getAuthHeaders()});
  }

  getManageDomainDetails(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/getmanagedomaindetails/${id}`, {headers: this.getAuthHeaders()});
  }

  checkIfUserExists(username: any){
    return this.httpClient.get(`${this.baseUrl()}api/checkifuserexists/${username}`, {headers: this.headers});
  }
  
  getDomains(){
    return this.httpClient.get(`${this.baseUrl()}api/domains`, {headers: this.getAuthHeaders()});
  }
  getDeputyDomains(){
    const id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/deputydomains/${id}`, {headers: this.getAuthHeaders()});
  }
  getDomainDetails(domain:any){
    const id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/getdomaindetails/${domain}/${id}/`, {headers: this.getAuthHeaders()});
  }
  getDomainDetailsForRP(domain:any){
    return this.httpClient.get(`${this.baseUrl()}api/getdomaindetailsforrp/${domain}/`, {headers: this.headers});
  }

  getThirdPartyHosts(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/hosts`, {headers: this.getAuthHeaders()});
  }



  getAllThirdPartyHosts(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/allhosts`, {headers: this.getAuthHeaders()});
  }
  getAllThirdPartyPaymentProviders(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/allpaymentproviders/`, {headers: this.getAuthHeaders()});
  }
  getAllThirdPartyDeliveryServices(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/alldeliveryservices/`, {headers: this.getAuthHeaders()});
  }
  getAllThirdPartyITServices(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/allitservices/`, {headers: this.getAuthHeaders()});
  }
  getAllOtherServices(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/allotherservices/`, {headers: this.getAuthHeaders()});
  }
  


  getThirdPartyPayment(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/payment`, {headers: this.getAuthHeaders()});
  }
  getThirdPartyDelivery(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/delivery`, {headers: this.getAuthHeaders()});
  }
  getThirdPartyIT(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/it`, {headers: this.getAuthHeaders()});
  }
  getThirdPartyOther(){
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/other`, {headers: this.getAuthHeaders()});
  }
  getMyThirdParty(){
    const id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/thirdparties/${id}`, {headers: this.getAuthHeaders()});
  }

  getProfile(){    
    const id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/myprofile/${id}`, {headers: this.getAuthHeaders()});
  }

  getDIOProfile(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/myprofile/${id}`, {headers: this.getAuthHeaders()});
  }

  getDIOProfileFromDBTable(username: any){
    return this.httpClient.get(`${this.baseUrl()}api/getdionamefromdbtable/${username}`, {headers: this.getAuthHeaders()});
  }
  
  getUsernameFromID(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/getusernameformid/${id}/`, {headers: this.headers});
  }

  getRefferedUser(){    
    const id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/getreferreduser/${id}`, {headers: this.getAuthHeaders()});
  }
  getResellersPrices(code: any){    
    return this.httpClient.get(`${this.baseUrl()}api/getresellersprices/${code}`, {headers: this.getAuthHeaders()});
  }

  getFaqs(){
    return this.httpClient.get(`${this.baseUrl()}api/getfaqs/`, {headers: this.headers});
  }
  getFaqsAudio(){
    return this.httpClient.get(`${this.baseUrl()}api/getfaqsaudio/`, {headers: this.headers});
  }
  getFaqsType(type: any){
    return this.httpClient.get(`${this.baseUrl()}api/getfaqstype/${type}/`, {headers: this.headers});
  }
  getFaqsAudioType(type: any){
    return this.httpClient.get(`${this.baseUrl()}api/getfaqsaudiotype/${type}/`, {headers: this.headers});
  }

  getWpFree(){
    return this.httpClient.get(`${this.baseUrl()}api/getwpf/`, {headers: this.headers});
  }
  getWpFreeAudio(){
    return this.httpClient.get(`${this.baseUrl()}api/getwpfaudio/`, {headers: this.headers});
  }
  getWpPro(){
    return this.httpClient.get(`${this.baseUrl()}api/getwpp/`, {headers: this.headers});
  }
  getWpProAudio(){
    return this.httpClient.get(`${this.baseUrl()}api/getwppaudio/`, {headers: this.headers});
  }


  getConsentForm(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/domainconsentform/${id}/`, {headers: this.headers});
  }
  forgotPassword(email: any){
    const body = JSON.stringify({email});
    return this.httpClient.post(`${this.baseUrl()}api/password_reset/`, body, {headers: this.headers});
  }
  forgotPasswordNewPassword(password: any, token: any){
    const body = JSON.stringify({password, token});
    return this.httpClient.post(`${this.baseUrl()}api/password_reset/confirm/`, body, {headers: this.headers});
  }
  
  changePassword(old_password: any, new_password: any) {
    const body = JSON.stringify({old_password, new_password});
    return this.httpClient.put(`${this.baseUrl()}api/change-password/`, body, {headers: this.getAuthHeaders()});
  }

  getPrice(){
    return this.httpClient.get(`${this.baseUrl()}api/price/`, {headers: this.headers});
  }
  getUsername(ip: any){
    return this.httpClient.get(`${this.baseUrl()}api/getusername/${ip}/`, {headers: this.headers});
  }
  
  checkUsername(username: any){
    return this.httpClient.get(`${this.baseUrl()}api/checkusername/${username}/`, {headers: this.headers});
  }
  getDomainPrice(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/domainprice/${id}`, {headers: this.headers});
  }

  updateProfile(id: any, data_officer_first_name: any, data_officer_middle_name: any, data_officer_surname: any, data_officer_nickname: any, data_officer_designation_in_organisation: any, data_officer_cell: any, data_officer_office_number: any, data_officer_direct_email: any, data_officer_popia_email: any, info_same_as_data:any, info_officer_first_name: any, info_officer_middle_name: any, info_officer_surname: any, info_officer_nickname: any, info_officer_designation_in_organisation: any, info_officer_cell: any, info_officer_office_number: any, info_officer_direct_email: any, info_officer_popia_email: any, profile_completed: any){
    const body = JSON.stringify({data_officer_first_name, data_officer_middle_name, data_officer_surname, data_officer_nickname, data_officer_designation_in_organisation, data_officer_cell, data_officer_office_number, data_officer_direct_email, data_officer_popia_email, info_same_as_data, info_officer_first_name, info_officer_middle_name, info_officer_surname, info_officer_nickname, info_officer_designation_in_organisation, info_officer_cell, info_officer_office_number, info_officer_direct_email, info_officer_popia_email, profile_completed});
    return this.httpClient.put(`${this.baseUrl()}api/updatemyprofile/${id}/`, body, {headers: this.getAuthHeaders()});
  }
 
  createDomain(company_name: any, company_logo: any, company_domain: any, company_reg: any, trading_name: any, tax_number: any, type_of_body: any, entity_type: any, ph_street_number: any, ph_street_name: any, ph_suburb: any, ph_town_city: any, ph_province: any, ph_postal_code: any, postal_same_as_physical: any, po_street_number: any, po_street_name: any, po_suburb: any, po_town_city: any, po_province: any, po_postal_code: any, req_payment: any, domain_form_complete: any, office_number: any, direct_email: any, retain_records: any, retain_records_reason: any, retain_date: any, retain_months: any, retain_years: any, retain_period: any){
    const body = JSON.stringify({req_payment, company_name, company_logo, company_domain, company_reg, trading_name, tax_number, type_of_body, entity_type, ph_street_number, ph_street_name, ph_suburb, ph_town_city, ph_province, ph_postal_code, postal_same_as_physical, po_street_number, po_street_name, po_suburb, po_town_city, po_province, po_postal_code, domain_form_complete, office_number, direct_email, retain_records, retain_records_reason, retain_date, retain_months, retain_years, retain_period});
    return this.httpClient.post(`${this.baseUrl()}api/domainadd/`, body, {headers: this.getAuthHeaders()});
  }
  addDomain(company_name: any, company_domain: any, req_payment: any){
    const body = JSON.stringify({company_name, company_domain, req_payment});
    return this.httpClient.post(`${this.baseUrl()}api/domainadd/`, body, {headers: this.getAuthHeaders()});
  }

  updateDomain(id: any, company_name: any, company_logo: any, company_domain: any, company_reg: any, trading_name: any, tax_number: any, type_of_body: any, entity_type: any, ph_street_number: any, ph_street_name: any, ph_suburb: any, ph_town_city: any, ph_province: any, ph_postal_code: any, postal_same_as_physical: any, po_street_number: any, po_street_name: any, po_suburb: any, po_town_city: any, po_province: any, po_postal_code: any, domain_form_complete: any, deputy_officers: any, office_number: any, direct_email: any, retain_records: any, retain_records_reason: any, retain_date: any, retain_months: any, retain_years: any, retain_period: any){
    const body = JSON.stringify({id, company_name, company_logo, company_domain, company_reg, trading_name, tax_number, type_of_body, entity_type, ph_street_number, ph_street_name, ph_suburb, ph_town_city, ph_province, ph_postal_code, postal_same_as_physical, po_street_number, po_street_name, po_suburb, po_town_city, po_province, po_postal_code, domain_form_complete, deputy_officers, office_number, direct_email, retain_records, retain_records_reason, retain_date, retain_months, retain_years, retain_period});
    return this.httpClient.put(`${this.baseUrl()}api/domainmanage/${id}`, body, {headers: this.getAuthHeaders()});
  }

  updateCompanyDeputyOfficers(id: any, deputy_officers: any){
    const body = JSON.stringify({deputy_officers});
    return this.httpClient.put(`${this.baseUrl()}api/updatecompanydeputyofficers/${id}`, body, {headers: this.headers});
  }

  getDomainDeputyOfficers(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/getdomaindeputyofficers/${id}`, {headers: this.headers});
  }

  updateConsentForm(id: any, services: any, communication_way: any, service_level_communication: any, mediums_of_communication: any, mediums_of_communication_other: any, marketing_communication: any, mediums_of_marketing: any, mediums_of_marketing_other: any, no_consent_consequences: any, information_types: any, data_obtained: any, man_vol: any, man_vol_reason: any, cross_border: any, cross_border_reason: any, required_reason: any, privacy_policy: any, consent_form_complete: any, consent_extras: any, social_media_tools: any, wordpress_website: any){
    const body = JSON.stringify({id, services, communication_way, service_level_communication, mediums_of_communication, mediums_of_communication_other, marketing_communication,  mediums_of_marketing, mediums_of_marketing_other, no_consent_consequences, information_types, data_obtained, man_vol, man_vol_reason, cross_border, cross_border_reason, required_reason, privacy_policy, consent_form_complete, consent_extras, social_media_tools, wordpress_website});
    return this.httpClient.put(`${this.baseUrl()}api/domainmanage/${id}`, body, {headers: this.getAuthHeaders()});
  }

  getDomain(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/domainmanage/${id}`, {headers: this.getAuthHeaders()});
  }

  listCompaniesWithID(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/listcompanieswithid/${id}`, {headers: this.getAuthHeaders()});
  }

  checkThirdPartyExist(trading_name: any, company_reg: any){ 
    const body = JSON.stringify({trading_name, company_reg});
    return this.httpClient.post(`${this.baseUrl()}api/checkthirdpartyexist/`, body, {headers: this.getAuthHeaders()});
  }

  updateThirdParty(id:any, other_parties: any){
    const body = JSON.stringify({id, other_parties});
    return this.httpClient.put(`${this.baseUrl()}api/addyourthirdparty/${id}`, body, {headers: this.getAuthHeaders()});
  }

  createThirdParty(created:any, company_name: any, company_logo: any, company_domain: any, company_reg: any, trading_name: any, tax_number: any, type_of_body: any, entity_type: any, ph_street_number: any, ph_street_name: any, ph_suburb: any, ph_town_city: any, ph_province: any, ph_postal_code: any, postal_same_as_physical: any, po_street_number: any, po_street_name: any, po_suburb: any, po_town_city: any, po_province: any, po_postal_code: any, office_number: any, direct_email: any, selectable: any, type: any, category: any){
    const body = JSON.stringify({created, company_name, company_logo, company_domain, company_reg, trading_name, tax_number, type_of_body, entity_type, ph_street_number, ph_street_name, ph_suburb, ph_town_city, ph_province, ph_postal_code, postal_same_as_physical, po_street_number, po_street_name, po_suburb, po_town_city, po_province, po_postal_code, office_number, direct_email, selectable, type, category});
    return this.httpClient.post(`${this.baseUrl()}api/addthirdparty/`, body, {headers: this.getAuthHeaders()});
  }

  addBulk(other_parties: any, company_domain: any, consent_form_complete: any, domain_form_complete: any, req_payment: any, user: any, company_name: any, company_reg: any, office_number: any, direct_email: any, company_logo: any, trading_name: any, tax_number: any, type_of_body: any, entity_type: any, retain_records: any, retain_records_reason: any, retain_period: any, retain_date: any, retain_months: any, retain_years: any, ph_street_number: any, ph_street_name: any, ph_suburb: any, ph_town_city: any, ph_province: any, ph_postal_code: any, postal_same_as_physical: any, po_street_number: any, po_street_name: any, po_suburb: any, po_town_city: any, po_province: any, po_postal_code: any, deputy_info_officer: any, communication_way: any, service_level_communication: any, mediums_of_communication: any, mediums_of_communication_other: any, mediums_of_communication_required: any, marketing_communication: any, mediums_of_marketing: any, mediums_of_marketing_other: any, mediums_of_marketing_required: any, no_consent_consequences: any, information_types: any, data_obtained: any, man_vol: any, man_vol_reason: any, cross_border: any, cross_border_reason: any, required_reason: any, privacy_policy: any, services: any, consent_extras: any, social_media_tools: any, wordpress_website: any){
    const body = JSON.stringify({other_parties, company_domain, consent_form_complete, domain_form_complete, req_payment, user, company_name, company_reg, office_number, direct_email, company_logo, trading_name, tax_number, type_of_body, entity_type, retain_records, retain_records_reason, retain_period, retain_date, retain_months, retain_years, ph_street_number, ph_street_name, ph_suburb, ph_town_city, ph_province, ph_postal_code, postal_same_as_physical, po_street_number, po_street_name, po_suburb, po_town_city, po_province, po_postal_code, deputy_info_officer, communication_way, service_level_communication, mediums_of_communication, mediums_of_communication_other, mediums_of_communication_required, marketing_communication, mediums_of_marketing, mediums_of_marketing_other, mediums_of_marketing_required, no_consent_consequences, information_types, data_obtained, man_vol, man_vol_reason, cross_border, cross_border_reason, required_reason, privacy_policy, services, consent_extras, social_media_tools, wordpress_website});
    return this.httpClient.post(`${this.baseUrl()}api/addbulk/`, body, {headers: this.getAuthHeaders()});
  }

  updateSubscription(id: any, is_subscribed: any){
    const body = JSON.stringify({is_subscribed});
    return this.httpClient.put(`${this.baseUrl()}api/updatesubscription/${id}/`, body, {headers: this.getAuthHeaders()});
  }

  loginUser(username: any, password: any) {
    const body = JSON.stringify({username, password});
    return this.httpClient.post(`${this.baseUrl()}auth/`, body, {headers: this.headers});
  }

  registerUser(username: any, email: any, password: any) {
    const body = JSON.stringify({username, password, email});
    return this.httpClient.post(`${this.baseUrl()}api/users/`, body, {headers: this.headers});
  }

  registerNewDataOfficer(from_email: any, to_email: any, password: any, date: any, domain_id: any, invitee_name: any, invitee_surname: any, company_id: any, domain: any) {
    const body = JSON.stringify({from_email, to_email, password, date, domain_id, invitee_name, invitee_surname, company_id, domain});
    return this.httpClient.post(`${this.baseUrl()}api/registernewdataofficer/`, body, {headers: this.headers});
  }

  notifyDataOfficer(from_email: any, to_email: any, date: any, id: any, invitee_name: any, invitee_surname: any, company_id: any, domain: any) {
    const body = JSON.stringify({from_email, to_email, date, id, invitee_name, invitee_surname, company_id, domain});
    return this.httpClient.post(`${this.baseUrl()}api/notifydataofficer/`, body, {headers: this.headers});
  }

  notifyDeputyInformationOfficer(from_email: any, to_email: any, date: any, id: any, invitee_name: any, invitee_surname: any, company_id: any, domain: any) {
    const body = JSON.stringify({from_email, to_email, date, id, invitee_name, invitee_surname, company_id, domain});
    return this.httpClient.post(`${this.baseUrl()}api/notifydeputyinformationofficer/`, body, {headers: this.headers});
  }

  notifyEditor(from_email: any, to_email: any, date: any, id: any, invitee_name: any, invitee_surname: any, company_id: any, domain: any) {
    const body = JSON.stringify({from_email, to_email, date, id, invitee_name, invitee_surname, company_id, domain});
    return this.httpClient.post(`${this.baseUrl()}api/notifyeditor/`, body, {headers: this.headers});
  }

  registerNewDeputyInformationOfficer(from_email: any, to_email: any, password: any, date: any, id: any, invitee_name: any, invitee_surname: any, company_id: any, domain: any) {
    const body = JSON.stringify({from_email, to_email, password, date, id, invitee_name, invitee_surname, company_id, domain});
    return this.httpClient.post(`${this.baseUrl()}api/registernewdeputyinformationofficer/`, body, {headers: this.headers});
  }
  registerEditor(from_email: any, to_email: any, password: any, date: any, id: any, invitee_name: any, invitee_surname: any, company_id: any, domain: any) {
    const body = JSON.stringify({from_email, to_email, password, date, id, invitee_name, invitee_surname, company_id, domain});
    return this.httpClient.post(`${this.baseUrl()}api/registereditor/`, body, {headers: this.headers});
  }


  newUser(user: any, email: any, id_number: any) {
    const body = JSON.stringify({id_number, user});
    return this.httpClient.post(`${this.baseUrl()}api/newuser/${email}/`, body, {headers: this.headers});
  }
  newUserProfile(user: any, email: any) {
    const body = JSON.stringify({user});
    return this.httpClient.post(`${this.baseUrl()}api/newuserprofile/${email}/`, body, {headers: this.headers});
  }
  getUserID(username: any){
    return this.httpClient.get(`${this.baseUrl()}api/getuserid/${username}/`, {headers: this.headers});
  }

  getProfileExternally(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/myprofile/${id}`, {headers: this.getAuthHeaders()});
  }
  getConsentFormFromDomain(domain: any){
    return this.httpClient.get(`${this.baseUrl()}api/domainconsentformfromdomain/${domain}/`, {headers: this.headers});
  }
  checkConsent(consent_domain: any, consent_email: any){
    return this.httpClient.get(`${this.baseUrl()}api/checkconsent/${consent_domain}/${consent_email}`, {headers: this.headers});
  }



  loginUserCheckDomain(){
    return this.httpClient.get(`${this.baseUrl()}api/auth/checkbpc`, {headers: this.getAuthHeaders()});
  }


  getConsent(consent_email: any, secret: any){
    return this.httpClient.get(`${this.baseUrl()}api/getconsent/${consent_email}/${secret}`, {headers: this.headers});
  }

  newIR(com_name: any, domain: any, co_name: any, co_surname: any, co_delegation: any, co_cell: any, co_email: any, co_address: any, urgency: any, languages: any, marketing: any, business_desc: any, system_desc: any){
    const body = JSON.stringify({com_name, domain, co_name, co_surname, co_delegation, co_cell, co_email, co_address, urgency, languages, marketing, business_desc, system_desc});
    return this.httpClient.post(`${this.baseUrl()}api/ir/`, body, {headers: this.headers});
  }

  newWe(type: any, contact_reason: any, domain: any, name: any, surname: any, email: any, cell: any, message: any){
    const body = JSON.stringify({type, contact_reason, domain, name, surname, email, cell, message});
    return this.httpClient.post(`${this.baseUrl()}api/contact/`, body, {headers: this.headers});
  }

  newDispute(name: any, surname: any, cell: any, email: any, dispute: any, domain: any){
    const body = JSON.stringify({name, surname, cell, email, dispute, domain});
    return this.httpClient.post(`${this.baseUrl()}api/dispute/`, body, {headers: this.headers});
  }
  
  getCompanyName(id:any){
    return this.httpClient.get(`${this.baseUrl()}api/getcompanyname/${id}`, {headers: this.headers});
  }

  newEditor(name: any, surname: any, email: any, domain_id: any, added: any, company_name: any){
    const body = JSON.stringify({name, surname, email, domain_id, added, company_name});
    return this.httpClient.post(`${this.baseUrl()}api/neweditor/`, body, {headers: this.headers});
  }
  newEditorBulk(email: any, domain_id: any, added: any, company_name: any){
    const body = JSON.stringify({email, domain_id, added, company_name});
    return this.httpClient.post(`${this.baseUrl()}api/neweditor/`, body, {headers: this.headers});
  }
  newDataOfficer(name: any, surname: any, email: any, domain_id: any, added: any, company_name: any){
    const body = JSON.stringify({name, surname, email, domain_id, added, company_name});
    return this.httpClient.post(`${this.baseUrl()}api/newdataofficer/`, body, {headers: this.headers});
  }
  getEditors(company_id: any){
    return this.httpClient.get(`${this.baseUrl()}api/geteditors/${company_id}`, {headers: this.headers});
  }

  getMyEditorRequests(){
    const email = this.cookieService.get('bpc-email');
    return this.httpClient.get(`${this.baseUrl()}api/myeditorrequests/${email}`, {headers: this.headers});
  }

  getMyDataOfficerRequests(){
    const email = this.cookieService.get('bpc-email');
    return this.httpClient.get(`${this.baseUrl()}api/mydataofficerrequests/${email}`, {headers: this.headers});
  }


  getMyManageableDomains(){
    const email = this.cookieService.get('bpc-email');
    return this.httpClient.get(`${this.baseUrl()}api/mymanageabledomains/${email}`, {headers: this.headers});
  }
  editorRequestResponse(pk: any, status: any, active: any){
    const body = JSON.stringify({status, active});
    return this.httpClient.put(`${this.baseUrl()}api/editorrequestresponse/${pk}/`, body, {headers: this.headers});
  }
  dataOfficerRequestResponse(pk: any, status: any, active: any){
    const body = JSON.stringify({status, active});
    return this.httpClient.put(`${this.baseUrl()}api/dataofficerrequestresponse/${pk}/`, body, {headers: this.headers});
  }

  getDataOfficerPK(domain_id: any, email: any){
    return this.httpClient.get(`${this.baseUrl()}api/getdataofficerpk/${domain_id}/${email}/`, {headers: this.headers});
  }
  getDeputyInformationOfficerPK(domain_id: any, email: any){
    return this.httpClient.get(`${this.baseUrl()}api/getdeputyinformationofficerpk/${domain_id}/${email}/`, {headers: this.headers});
  }
  getEditorPK(domain_id: any, email: any){
    return this.httpClient.get(`${this.baseUrl()}api/geteditorpk/${domain_id}/${email}/`, {headers: this.headers});
  }
  updateCompanyDataOfficer(pk: any, user: any){
    const body = JSON.stringify({user});
    return this.httpClient.put(`${this.baseUrl()}api/updatecompanydataofficer/${pk}/`, body, {headers: this.headers});
  }
  
  // ----------------------- 
  // deputy officer -> START
  // ----------------------- 
  newDeputyOfficer(name: any, surname: any, email: any, domain_id: any, added: any, company_name: any){
    const body = JSON.stringify({name, surname, email, domain_id, added, company_name});
    return this.httpClient.post(`${this.baseUrl()}api/newdeputyofficer/`, body, {headers: this.headers});
  }
  getMyDeputyOfficerRequests(){
    const email = this.cookieService.get('bpc-email');
    return this.httpClient.get(`${this.baseUrl()}api/mydeputyofficerrequests/${email}`, {headers: this.headers});
  }
  deputyOfficerRequestResponse(pk: any, status: any, active: any){
    const body = JSON.stringify({status, active});
    return this.httpClient.put(`${this.baseUrl()}api/deputyofficerrequestresponse/${pk}/`, body, {headers: this.headers});
  }
  // -----------------------
  // deputy officer -> END
  // ----------------------- 

  changeDomainOwnerID(pk: any){
    const user = this.cookieService.get('bpc-id');
    const body = JSON.stringify({user});
    return this.httpClient.put(`${this.baseUrl()}api/changedomainownerid/${pk}/`, body, {headers: this.headers});
  }
  getSelectedThirdParty(id: any){
    return this.httpClient.get(`${this.baseUrl()}api/getselectedthirdparty/${id}`, {headers: this.headers});
  }

  removeEditor(pk: any){
    const active = 0;
    const body = JSON.stringify({active});
    return this.httpClient.put(`${this.baseUrl()}api/removeeditor/${pk}/`, body, {headers: this.headers});
  }

  registerWithRefCode(code: any, email:any){
    return this.httpClient.get(`${this.baseUrl()}api/registerwithrefcode/${code}/${email}/`, {headers: this.headers});
  }

  getMyRefCode(){
    const id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/getmyrefcode/${id}/`, {headers: this.headers});
  }
  getMyReferrals(){
    const id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/getmyreferrals/${id}/`, {headers: this.headers});
  }
  listOfReferrals(){
    const ref = this.cookieService.get('bpc-ref');
    return this.httpClient.get(`${this.baseUrl()}api/listofreferrals/${ref}/`, {headers: this.headers});
  }

  newReferral(referred: any, time: any, code: any){
    const referrer = this.cookieService.get('bpc-id');
    const body = JSON.stringify({referrer, referred, time, code});
    return this.httpClient.post(`${this.baseUrl()}api/newreferral/`, body, {headers: this.headers});
  }

  finishedRegistrationAfterReferral(pk: any, status: any){
    const body = JSON.stringify({status});
    return this.httpClient.put(`${this.baseUrl()}api/finishedregistrationafterreferral/${pk}/`, body, {headers: this.headers});
  }

  getInitialMessage(){
    const id = this.cookieService.get('bpc-id');
    return this.httpClient.get(`${this.baseUrl()}api/initialmessagemanage/${id}/`, {headers: this.headers});
  }

  changeInitialMessage(pk: any){
    const show_default_message = 0;
    const body = JSON.stringify({show_default_message});
    return this.httpClient.put(`${this.baseUrl()}api/updateinitialmessagemanage/${pk}/`, body, {headers: this.headers});
  }

  getWPDomainID(domain: any){
    return this.httpClient.get(`${this.baseUrl()}api/getwpdomainid/${domain}/`, {headers: this.headers});
  }
  updateConsent(id: any, consent_domain: any, consent_full_name: any, consent_id_number: any, consent_email: any, signature: any, signed_at: any,  marketing_comm: any, service_comm: any, uploaded_date: any, company_name: any, company_url: any, c_phone: any, c_sms: any, c_whatsapp: any, c_messenger: any, c_telegram: any, c_email: any, c_custom1: any, c_custom2: any, c_custom3: any, m_phone: any, m_sms: any, m_whatsapp: any, m_messenger: any, m_telegram: any, m_email: any, m_custom1: any, m_custom2: any, m_custom3: any, consent_user_id: any, timestamp: any){
    const body = JSON.stringify({consent_domain, consent_full_name, consent_id_number, consent_email, signature, signed_at, marketing_comm, service_comm, uploaded_date, company_name, company_url, c_phone, c_sms, c_whatsapp, c_messenger, c_telegram, c_email, c_custom1, c_custom2, c_custom3, m_phone, m_sms, m_whatsapp, m_messenger, m_telegram, m_email, m_custom1, m_custom2, m_custom3, consent_user_id, timestamp});
    return this.httpClient.put(`${this.baseUrl()}api/updateconsent/${id}`, body, {headers: this.headers});
  }
  updateConsentChangedArray(pk: any, consentsChanged: any){
    const body = JSON.stringify({consentsChanged});
    return this.httpClient.put(`${this.baseUrl()}api/updateconsentchangedarray/${pk}/`, body, {headers: this.headers});
  }

  newConsent(consent_domain_name: any, consent_domain: any, consent_full_name: any, consent_id_number: any, consent_email: any, signature: any, signed_at: any, marketing_comm: any, service_comm: any, uploaded_date: any, company_name: any, company_url: any, c_phone: any, c_sms: any, c_whatsapp: any, c_messenger: any, c_telegram: any, c_email: any, c_custom1: any, c_custom2: any, c_custom3: any, m_phone: any, m_sms: any, m_whatsapp: any, m_messenger: any, m_telegram: any, m_email: any, m_custom1: any, m_custom2: any, m_custom3: any, consent_user_id: any, timestamp: any){
    const body = JSON.stringify({consent_domain_name, consent_domain, consent_full_name, consent_id_number, consent_email, signature, signed_at, marketing_comm, service_comm, uploaded_date, company_name, company_url, c_phone, c_sms, c_whatsapp, c_messenger, c_telegram, c_email, c_custom1, c_custom2, c_custom3, m_phone, m_sms, m_whatsapp, m_messenger, m_telegram, m_email, m_custom1, m_custom2, m_custom3, consent_user_id, timestamp});
    return this.httpClient.post(`${this.baseUrl()}api/newconsent/`, body, {headers: this.headers});
  }

  newConsentNewAccount(consent_domain_name: any, consent_domain: any, consent_full_name: any, consent_id_number: any, consent_email: any, signature: any, signed_at: any, marketing_comm: any, service_comm: any, uploaded_date: any, company_name: any, company_url: any, c_phone: any, c_sms: any, c_whatsapp: any, c_messenger: any, c_telegram: any, c_email: any, c_custom1: any, c_custom2: any, c_custom3: any, m_phone: any, m_sms: any, m_whatsapp: any, m_messenger: any, m_telegram: any, m_email: any, m_custom1: any, m_custom2: any, m_custom3: any, consent_user_id: any, timestamp: any){
    const body = JSON.stringify({consent_domain_name, consent_domain, consent_full_name, consent_id_number, consent_email, signature, signed_at, marketing_comm, service_comm, uploaded_date, company_name, company_url, c_phone, c_sms, c_whatsapp, c_messenger, c_telegram, c_email, c_custom1, c_custom2, c_custom3, m_phone, m_sms, m_whatsapp, m_messenger, m_telegram, m_email, m_custom1, m_custom2, m_custom3, consent_user_id, timestamp});
    return this.httpClient.post(`${this.baseUrl()}api/newconsentnewaccount/`, body, {headers: this.headers});
  }
  newUserConsent(user: any, data_officer_direct_email: any, id_number: any) {
    const body = JSON.stringify({id_number, user, data_officer_direct_email});
    return this.httpClient.post(`${this.baseUrl()}api/newuserprofile/${id_number}/`, body, {headers: this.headers});
  }

  getStats(){
    return this.httpClient.get(`${this.baseUrl()}api/getstats/`, {headers: this.headers});
  }


  getAuthHeaders() {
    const token = this.cookieService.get('bpc-token');
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    });
  }

}