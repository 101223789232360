import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../api.service';
import { CookieService } from 'ngx-cookie-service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  device = 0;
  success = false;
  failed = false;
  res:any;
  user_email:any;
  password_changed = false;
  email = this.cookieService.get('bpc-email');
  consent_required = false;

  @Input() profile: any
    profileForm = new FormGroup({
      id: new FormControl(''),
      do_first_name: new FormControl(''),
      do_middle_name: new FormControl(''),
      do_surname: new FormControl(''),
      do_nickname: new FormControl(''),
      do_designation_in_org: new FormControl(''),
      do_cell: new FormControl(''),
      do_office_number: new FormControl(''),
      do_direct_email: new FormControl(''),
      do_popia_email: new FormControl(''),

      info_same_as_data: new FormControl(''),
      
      dio_first_name: new FormControl(''),
      dio_middle_name: new FormControl(''),
      dio_surname: new FormControl(''),
      dio_nickname: new FormControl(''),
      dio_designation_in_org: new FormControl(''),
      dio_cell: new FormControl(''),
      dio_office_number: new FormControl(''),
      dio_direct_email: new FormControl(''),
      dio_popia_email: new FormControl(''),
    });
    changePasswordForm = new FormGroup({
      old_password: new FormControl(''),
      new_password: new FormControl(''),
    });

  constructor(private apiService: ApiService, private cookieService: CookieService, private _vps: ViewportScroller, private router: Router) { }

  ngOnInit() {    
    const bpcConsent = this.cookieService.get('bpc-consent');
    if(bpcConsent == '0'){
      this.consent_required = true;
    } else{
      this.consent_required = false;
    }
    
    this.user_email = this.cookieService.get('bpc-email');
    this.apiService.checkConsent(80, this.user_email).subscribe(
      (res: any) => {
        if(res.length > 0){
          this.cookieService.set('bpc-consent', '1');
          this.consent_required = false;
        } else {
          this.consent_required = true;
        }
      },
      err => console.log(err)
    );
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.apiService.getProfile().subscribe(
      (result: any) => {
        if(result.length > 0){
          for(let child of result){
            this.profileForm.patchValue({
              id: child.id,
              do_first_name: child.data_officer_first_name,
              do_middle_name: child.data_officer_middle_name,
              do_surname: child.data_officer_surname,
              do_nickname: child.data_officer_nickname,
              do_designation_in_org: child.data_officer_designation_in_organisation,
              do_cell: child.data_officer_cell,
              do_office_number: child.data_officer_office_number,
              do_direct_email: child.data_officer_direct_email,
              do_popia_email: child.data_officer_popia_email,
    
              info_same_as_data: child.info_same_as_data,
    
              dio_first_name: child.info_officer_first_name,
              dio_middle_name: child.info_officer_middle_name,
              dio_surname: child.info_officer_surname,
              dio_nickname: child.info_officer_nickname,
              dio_designation_in_org: child.info_officer_designation_in_organisation,
              dio_cell: child.info_officer_cell,
              dio_office_number: child.info_officer_office_number,
              dio_direct_email: child.info_officer_direct_email,
              dio_popia_email: child.info_officer_popia_email,
            });
          }
        }else {
          const email = this.cookieService.get('bpc-email');
          const id = this.cookieService.get('bpc-id');
          this.apiService.newUserProfile(id, email).subscribe(
            (res: any) => {
              this.apiService.getProfile().subscribe(
                (result: any) => {
                    for(let child of result){
                      this.profileForm.patchValue({
                        id: child.id,
                        do_first_name: child.data_officer_first_name,
                        do_middle_name: child.data_officer_middle_name,
                        do_surname: child.data_officer_surname,
                        do_nickname: child.data_officer_nickname,
                        do_designation_in_org: child.data_officer_designation_in_organisation,
                        do_cell: child.data_officer_cell,
                        do_office_number: child.data_officer_office_number,
                        do_direct_email: child.data_officer_direct_email,
                        do_popia_email: child.data_officer_popia_email,
              
                        info_same_as_data: child.info_same_as_data,
              
                        dio_first_name: child.info_officer_first_name,
                        dio_middle_name: child.info_officer_middle_name,
                        dio_surname: child.info_officer_surname,
                        dio_nickname: child.info_officer_nickname,
                        dio_designation_in_org: child.info_officer_designation_in_organisation,
                        dio_cell: child.info_officer_cell,
                        dio_office_number: child.info_officer_office_number,
                        dio_direct_email: child.info_officer_direct_email,
                        dio_popia_email: child.info_officer_popia_email,
                      });
                    }
                  },
                  error => console.log(error)
                );
              },
            error => console.log(error)
          );
        }
        
      },
      error => console.log(error)
    );
  }

  saveForm(){
    if(this.profileForm.value.info_same_as_data){
      this.profileForm.value.dio_first_name = this.profileForm.value.do_first_name;
      this.profileForm.value.dio_middle_name = this.profileForm.value.do_middle_name;
      this.profileForm.value.dio_surname = this.profileForm.value.do_surname;
      this.profileForm.value.dio_nickname = this.profileForm.value.do_nickname;
      this.profileForm.value.dio_designation_in_org = this.profileForm.value.do_designation_in_org;
      this.profileForm.value.dio_cell = this.profileForm.value.do_cell;
      this.profileForm.value.dio_office_number = this.profileForm.value.do_office_number;
      this.profileForm.value.dio_direct_email = this.profileForm.value.do_direct_email;
      this.profileForm.value.dio_popia_email = this.profileForm.value.do_popia_email;
    }
    
    this.apiService.updateProfile(this.profileForm.value.id, this.profileForm.value.do_first_name, this.profileForm.value.do_middle_name, this.profileForm.value.do_surname, this.profileForm.value.do_nickname, this.profileForm.value.do_designation_in_org, this.profileForm.value.do_cell, this.profileForm.value.do_office_number, this.profileForm.value.do_direct_email, this.profileForm.value.do_popia_email, this.profileForm.value.info_same_as_data, this.profileForm.value.dio_first_name, this.profileForm.value.dio_middle_name, this.profileForm.value.dio_surname, this.profileForm.value.dio_nickname, this.profileForm.value.dio_designation_in_org, this.profileForm.value.dio_cell, this.profileForm.value.dio_office_number, this.profileForm.value.dio_direct_email, this.profileForm.value.dio_popia_email, 1).subscribe(
      () => {
        this.router.navigate(['/portal']);
      },
      error => {
        this.failed = true;
        this._vps.scrollToAnchor('profileHeading');
      }
    );
  }
  changePassword(){
    this.apiService.loginUser(this.user_email, this.changePasswordForm.value.old_password).subscribe(
      (result: any) => {
        this.apiService.changePassword(this.changePasswordForm.value.old_password, this.changePasswordForm.value.new_password).subscribe(
          (result: any) => {
            this.password_changed = true;
          },
          error => console.log(error)
        );
      },
      error => console.log(error)
    );
  }
}
