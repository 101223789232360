<div [ngClass]="this.device == 1 ? 'layout_mobile' : ''">
  <button type="button" class="btn btn-danger" (click)="goBack()" *ngIf="!domain">Back</button>
  <h1 style="text-align:center;">PAYMENT</h1>

  <div [ngClass]="this.device == 1 ? 'optionsWrapper_mobile' : 'optionsWrapper'">
    <div class="optionOne options box">
      <h4 class="optionHeadings">Monthly</h4>
      <ul>
        <li>Setup recurring payment</li>
        <li>Service shall be suspended if payment fails</li>
        <li>Reactivation fee of R100 required if services is suspended</li>
        <li>While suspended it reverts back to free, if you have not setup the free version you will not be POPIA compliant</li>
      </ul>
      
      <h6 class="optionPrice" *ngIf="main_monthly != 200"><del style="color: red;"><span style="color: black;">R{{original_cost_monthly}} / month</span></del></h6><br>    
      <h6 class="optionPrice" *ngIf="saving_percentage != 0"><span>{{saving_percentage | number : '1.0-2'}}% discount!!</span></h6>
      <h6 class="optionPrice">R{{ monthlyCost }} / month</h6><br>
      <div>
        <form ngNoForm [action]="url" method="post" class="selectOption">
          <input type="hidden" name="merchant_id" value="11316958">
          <input type="hidden" name="merchant_key" value="4kilpnkhq4z01">
          <input type="hidden" name="return_url" value="http://bepopiacompliant.co.za/#/portal/payment/success">
          <input type="hidden" name="cancel_url" value="http://bepopiacompliant.co.za/#/portal">
          <input type="hidden" name="notify_url" value="https://py.bepopiacompliant.co.za/notify.php?id={{id}}&p={{monthlyCost}}&m=1"> 
          <input type="hidden" name="amount" value="{{ monthlyCost }}.00">
          <input type="hidden" name="item_name" value="BPC Monthly Subscription"> 
          <input type="hidden" name="subscription_type" value="1">
          <input type="hidden" name="frequency" value="3">
          <input type="hidden" name="cycles" value="0">
          <input type="hidden" name="m_payment_id" value="{{id}}">

          <input type="hidden" name="name_first" value="{{name_first}}">
          <input type="hidden" name="name_last" value="{{name_last}}">
          <input type="hidden" name="email_address" value="{{email}}">
          <input type="hidden" name="cell_number" value="{{cell}}"> 

          <input type="hidden" name="signature" value="{{ monthly_security }}">
      
          <input type="submit" class="btn btn-danger" value="Pay Now">
        </form>
      </div><br>
    </div>
    <div class="optionTwo options box">
      <div class="ribbon ribbon-top-right"><span>BEST VALUE</span></div>
      <h4 class="optionHeadings">Yearly</h4>
      <br><br>
      <ul>
        <li>Setup annual recurring payment</li>
        <!-- <li>Setup annual recurring payment or</li> -->
        <!-- <li>pay with instant EFT</li> -->
        <li>Less chance of suspension</li>
        <li>You get two months <strong>FREE!</strong></li>
      </ul><br><br><br>
      <h6 class="optionPrice" *ngIf="main_yearly != 2000"><del style="color: red;"><span style="color: black;">R{{original_cost_yearly}} / year</span></del></h6><br>
      <h6 class="optionPrice" *ngIf="saving_percentage != 0"><span>{{saving_percentage | number : '1.0-2'}}% discount!!</span></h6>
      <h6 class="optionPrice">R{{ yearlyCost }} / year</h6><br>
      <div>
        <form ngNoForm [action]="url" method="post" class="selectOption">

          <input type="hidden" name="merchant_id" value="11316958">
          <input type="hidden" name="merchant_key" value="4kilpnkhq4z01">
          <input type="hidden" name="return_url" value="http://bepopiacompliant.co.za/#/portal/payment/success">
          <input type="hidden" name="cancel_url" value="http://bepopiacompliant.co.za/#/portal">
          <input type="hidden" name="notify_url" value="https://py.bepopiacompliant.co.za/notify.php?id={{id}}&p={{yearlyCost}}&y=1"> 
          <input type="hidden" name="item_name" value="BPC Yearly Subscription">
          <input type="hidden" name="amount" value="{{ yearlyCost }}.00">
          <input type="hidden" name="subscription_type" value="1">
          <input type="hidden" name="frequency" value="6">
          <input type="hidden" name="cycles" value="0">
          <input type="hidden" name="m_payment_id" value="{{id}}">

          <input type="hidden" name="name_first" value="{{name_first}}">
          <input type="hidden" name="name_last" value="{{name_last}}">
          <input type="hidden" name="email_address" value="{{email}}">
          <input type="hidden" name="cell_number" value="{{cell}}"> 

          <input type="hidden" name="signature" value="{{ yearly_security }}">

          <input type="submit" class="btn btn-danger" value="Pay Now">
        </form>
      </div><br>
    </div>
  </div>
</div>