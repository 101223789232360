<div [ngClass]="this.device == 1 ? 'mobileApp' : ''">		
	<div class="alert alert-success centerText" *ngFor="let item of deputy_domain_manage_requests">
		You have a new request to be an deputy information officer for <strong>{{item.company_name}}</strong><br><a class="acceptBtn" (click)="responseDeputyOfficer(item.id, 1, item.domain_id)">Accept</a><a class="denyBtn" (click)="responseDeputyOfficer(item.id, 0, item.domain_id)">Deny</a>
	</div>
	<div class="alert alert-success centerText" *ngFor="let item of data_officer_requests">
		You have a new request to be the data officer for <strong>{{item.company_name}}</strong><br><a class="acceptBtn" (click)="responseDataOfficer(item.id, 1, item.domain_id)">Accept</a><a class="denyBtn" (click)="responseDataOfficer(item.id, 0, item.domain_id)">Deny</a>
	</div>
	<h1>MY DOMAINS</h1>
	<h6 id="pluginBtn" *ngIf="this.device == 0"><a class="downloadPlugin" href="https://wordpress.org/plugins/be-popia-compliant/" target="_blank"><button class="btn btn-danger">Download WordPress plugin</button></a></h6>
	<!-- <div class="alert alert-warning centerText" *ngIf="has_domains == false">
		When adding domains, ensure that the owner is the same for all entities and that the data officer is also the same for all entities; If there is a variation in any of the companies, please create a new account for those companies.
	</div> -->
	<div class="alert alert-warning centerText" *ngIf="show_default_message">
		<i style="float: right;cursor: pointer;" class="icon-times" (click)="removeInitialMessage()"></i>
		Welcome to Be POPIA Compliant!<br>
		The most stable versions of the WP Plugins will always be available on the WordPress Plugins Directory, available on your WordPress Website Admin Panel. After adding your domain here and making payment, your domain keys will be exposed. Insert your keys into your WordPress plugin on the POPIA Compliance tab towards the top left of your Admin menu under Dashboard. Also ensure you complete your profile and then the consent and third parties info for each domain you add. You will be able to view the consent that has been provided right in your website under 'Users' or click on "Manage Consent' in the POPIA Compliance Menu.
	</div>
	<div *ngIf="this.device == 0">
		<div *ngFor="let domain of domains"  class="domain-item">
			<h4>{{ domain.company_name }}</h4>
			<h4><a href="{{ domain.company_domain }}">{{ domain.company_domain }}</a></h4>
			
			<button (click)="payClick(this.domain)" placement="top" ngbTooltip="Pay Now" class="pay-now-button domainBtns" style="width: 55px!important;" *ngIf="(domain.renew_date < currentDate || domain.renew_date == null) && domain.is_subscribed == 0"><i class="icon-card"></i></button>
			
			<button (click)="manageClick(this.domain)" placement="top" ngbTooltip="Manage Domain" id="manage" class="manage-button domainBtns"><i class="icon-wi-app-gear-dark"></i></button>
			
			<button (click)="thirdClick(this.domain)" placement="top" ngbTooltip="Third Parties" class="manage-button domainBtns"><i class="icon-users"></i></button>
		
			<button (click)="consentClick(this.domain)" placement="top" ngbTooltip="Consent Information" class="manage-button domainBtns"><i class="icon-info-circle"></i></button>
			
			<ng-container *ngIf="domain.renew_date >= currentDate && domain.is_subscribed == 1">
				<button (click)="keysClick(modalIncomplete, this.domain)" placement="top" ngbTooltip="Keys" class="no-pay-button domainBtns" [ngClass]="!domain.domain_form_complete || !domain.other_parties || !domain.consent_form_complete ? 'greyOut' : ''" ><i class="icon-key"></i></button>
			</ng-container>
		
			<button (click)="consentFormClick(this.domain)" placement="top" ngbTooltip="Consent Form" class="no-pay-button domainBtns"><i class="icon-document-text"></i></button>
			
			<button (click)="addEditorClick(this.domain)" placement="top" ngbTooltip="Add Editor" class="no-pay-button domainBtns"><i class="icon-user-plus"></i></button>
			
		</div><br><br>
		<button class="add-domain btn btn-danger" (click)="triggerModal(modalData)">Add Domain</button>
	</div>
	
	<div *ngIf="this.device == 1">
		<div *ngFor="let domain of domains" class="domain-item_mobile">
			<h4 class="centerText">{{ domain.company_name }}</h4>
			<h4 class="centerText"><a href="{{ domain.company_domain }}">{{ domain.company_domain }}</a></h4>
	
			<div>
				<ul>
					<li>
						<button (click)="payClick(this.domain)" class="pay-now-button mobileButtons" *ngIf="(domain.renew_date < currentDate || domain.renew_date == null) && domain.is_subscribed == 0">Pay Now</button>
						<ng-container *ngIf="domain.renew_date >= currentDate && domain.is_subscribed == 1">
							<button (click)="keysClick(modalIncomplete, this.domain)" class="no-pay-button mobileButtons" [ngClass]="!domain.domain_form_complete || !domain.other_parties || !domain.consent_form_complete ? 'greyOut' : ''">Keys</button>
						</ng-container>
					</li>
					<li>
						<button (click)="manageClick(this.domain)" class="manage-button mobileButtons">Manage</button>
					</li>
					<li>
						<button (click)="thirdClick(this.domain)" class="manage-button mobileButtons">Third Parties</button>
					</li>
					<li>
						<button (click)="consentClick(this.domain)" class="manage-button mobileButtons">Consent Details</button>
					</li>
					<li>
						<button (click)="consentFormClick(this.domain)" class="no-pay-button mobileButtons">Consent Form</button>
					</li>
					<li>
						<button (click)="addEditorClick(this.domain)" class="no-pay-button mobileButtons">Add Editor</button>
					</li>
				</ul>
			</div>			
		</div><br><br>
		
		<button class="add-domain btn btn-danger" (click)="triggerModal(modalData)">Add Domain</button>
	</div><br><br>
	<div *ngIf="deputy_domains.length > 0">
		<h1>DOMAINS THAT I MANAGE</h1>
		<div *ngIf="this.device == 0">
			<div *ngFor="let domain of deputy_domains"  class="domain-item">
				<h4>{{ domain.company_name }}</h4>
				<h4><a href="{{ domain.company_domain }}">{{ domain.company_domain }}</a></h4>
				
				<button (click)="payClick(this.domain)" placement="top" ngbTooltip="Pay Now" class="pay-now-button domainBtns" style="width: 55px!important;" *ngIf="(domain.renew_date < currentDate || domain.renew_date == null) && domain.is_subscribed == 0"><i class="icon-card"></i></button>
				
				<button (click)="manageClick(this.domain)" placement="top" ngbTooltip="Manage Domain" id="manage" class="manage-button domainBtns"><i class="icon-wi-app-gear-dark"></i></button>
				
				<button (click)="thirdClick(this.domain)" placement="top" ngbTooltip="Third Parties" class="manage-button domainBtns"><i class="icon-users"></i></button>
			
				<button (click)="consentClick(this.domain)" placement="top" ngbTooltip="Consent Information" class="manage-button domainBtns"><i class="icon-info-circle"></i></button>
				<ng-container *ngIf="domain.renew_date >= currentDate && domain.is_subscribed == 1">
					<button (click)="keysClick(modalIncomplete, this.domain)" placement="top" ngbTooltip="Keys" class="no-pay-button domainBtns" [ngClass]="!domain.domain_form_complete || !domain.other_parties || !domain.consent_form_complete ? 'greyOut' : ''"><i class="icon-key"></i></button>
				</ng-container>
				<button (click)="consentFormClick(this.domain)" placement="top" ngbTooltip="Consent Form" class="no-pay-button domainBtns"><i class="icon-document-text"></i></button>
				
				<button (click)="addEditorClick(this.domain)" placement="top" ngbTooltip="Add Editor" class="no-pay-button domainBtns"><i class="icon-user-plus"></i></button>
			
			</div><br><br>
		</div>
		
		<div *ngIf="this.device == 1">
			<div *ngFor="let domain of deputy_domains" class="domain-item_mobile">
				<h4 class="centerText">{{ domain.company_name }}</h4>
				<h4 class="centerText"><a href="{{ domain.company_domain }}">{{ domain.company_domain }}</a></h4>
		
				<div>
					<ul>
						<li>
							<button (click)="payClick(this.domain)" class="pay-now-button mobileButtons" *ngIf="(domain.renew_date < currentDate || domain.renew_date == null) && domain.is_subscribed == 0">Pay Now</button>
							<ng-container *ngIf="domain.renew_date >= currentDate && domain.is_subscribed == 1">
								<button (click)="keysClick(modalIncomplete, this.domain)" class="no-pay-button mobileButtons" [ngClass]="!domain.domain_form_complete || !domain.other_parties || !domain.consent_form_complete ? 'greyOut' : ''">Keys</button>
							</ng-container>
						</li>
						<li>
							<button (click)="manageClick(this.domain)" class="manage-button mobileButtons">Manage</button>
						</li>
						<li>
							<button (click)="thirdClick(this.domain)" class="manage-button mobileButtons">Third Parties</button>
						</li>
						<li>
							<button (click)="consentClick(this.domain)" class="manage-button mobileButtons">Consent Details</button>
						</li>
						<li>
							<button (click)="consentFormClick(this.domain)" class="no-pay-button mobileButtons">Consent Form</button>
						</li>
						<li>
							<button (click)="addEditorClick(this.domain)" class="no-pay-button mobileButtons">Add Editor</button>
						</li>
					</ul>
				</div>				
			</div><br><br>
		</div>
	</div>
	<div class="alert alert-success centerText" *ngFor="let item of domain_manage_requests">
		You have a new request to be an editor for <strong>{{item.company_name}}</strong><br><a class="acceptBtn" (click)="response(item.id, 1)">Accept</a><a class="denyBtn" (click)="response(item.id, 0)">Deny</a>
	</div>
	<div *ngIf="managed_domains.length > 0">
		<h1>DOMAINS THAT I EDIT</h1>
		<div *ngIf="this.device == 0">
			<div *ngFor="let domain of managed_domains"  class="domain-item">
				<h4>{{ domain.company_name }}</h4>
				<h4><a href="{{ domain.company_domain }}">{{ domain.company_domain }}</a></h4>
				
				<button (click)="payClick(this.domain)" placement="top" ngbTooltip="Pay Now" class="pay-now-button domainBtns" style="width: 55px!important;" *ngIf="(domain.renew_date < currentDate || domain.renew_date == null) && domain.is_subscribed == 0"><i class="icon-card"></i></button>
				
				<button (click)="manageClick(this.domain)" placement="top" ngbTooltip="Manage Domain" id="manage" class="manage-button domainBtns"><i class="icon-wi-app-gear-dark"></i></button>
				
				<button (click)="thirdClick(this.domain)" placement="top" ngbTooltip="Third Parties" class="manage-button domainBtns"><i class="icon-users"></i></button>
			
				<button (click)="consentClick(this.domain)" placement="top" ngbTooltip="Consent Information" class="manage-button domainBtns"><i class="icon-info-circle"></i></button>
				<ng-container *ngIf="domain.renew_date >= currentDate && domain.is_subscribed == 1">
					<button (click)="keysClick(modalIncomplete, this.domain)" placement="top" ngbTooltip="Keys" class="no-pay-button domainBtns" [ngClass]="!domain.domain_form_complete || !domain.other_parties || !domain.consent_form_complete ? 'greyOut' : ''"><i class="icon-key"></i></button>
				</ng-container>
				<button (click)="consentFormClick(this.domain)" placement="top" ngbTooltip="Consent Form" class="no-pay-button domainBtns"><i class="icon-document-text"></i></button>
				
			</div><br><br>
		</div>
		
		<div *ngIf="this.device == 1">
			<div *ngFor="let domain of managed_domains" class="domain-item_mobile">
				<h4 class="centerText">{{ domain.company_name }}</h4>
				<h4 class="centerText"><a href="{{ domain.company_domain }}">{{ domain.company_domain }}</a></h4>
		
				<div>
					<ul>
						<li>
							<button (click)="payClick(this.domain)" class="pay-now-button mobileButtons" *ngIf="(domain.renew_date < currentDate || domain.renew_date == null) && domain.is_subscribed == 0">Pay Now</button>
							<ng-container *ngIf="domain.renew_date >= currentDate && domain.is_subscribed == 1">
								<button (click)="keysClick(modalIncomplete, this.domain)" class="no-pay-button mobileButtons" [ngClass]="!domain.domain_form_complete || !domain.other_parties || !domain.consent_form_complete ? 'greyOut' : ''">Keys</button>
							</ng-container>
						</li>
						<li>
							<button (click)="manageClick(this.domain)" class="manage-button mobileButtons">Manage</button>
						</li>
						<li>
							<button (click)="thirdClick(this.domain)" class="manage-button mobileButtons">Third Parties</button>
						</li>
						<li>
							<button (click)="consentClick(this.domain)" class="manage-button mobileButtons">Consent Details</button>
						</li>
						<li>
							<button (click)="consentFormClick(this.domain)" class="no-pay-button mobileButtons">Consent Form</button>
						</li>
					</ul>
				</div>				
			</div><br><br>
		</div>
	</div>
</div>	

<ng-template #modalData let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">Add Domain</h4>
	  <button type="button" class="close" #closeModals aria-label="Close" (click)="modal.dismiss('Cross click')">
		<span aria-hidden="true">×</span>
	  </button>
	</div>
	<div class="modal-body" style="padding: 2rem 3rem 3rem 3rem!important;">
		<div class="alert alert-danger centerText" id="failed" *ngIf="this.failed">
			Company name and domain is required.
		</div>
		<div class="alert alert-danger centerText" id="failed" *ngIf="this.err">
			Please ensure that this domain is unique.
		</div>
		<form [formGroup]="addDomainForm">
			<div>
				<label>Company Name</label>
				<input type="text" formControlName="company_name" class="roundedInputs">
			</div>
			<div>
				<label>Domain</label>
				<input type="url" formControlName="company_domain" class="roundedInputs">
			</div>
		</form>
	</div>
	<div class="modal-footer">	
		<button class="btn btn-danger" [disabled]="this.addDomainForm.value.company_name == '' || this.addDomainForm.value.company_domain == ''" (click)="save();">Add another domain</button>	
	  	<button type="button" class="btn btn-danger" [disabled]="this.addDomainForm.value.company_name == '' || this.addDomainForm.value.company_domain == ''" (click)="finish();">Proceed to checkout</button>
	</div>
</ng-template>

<ng-template #modalDataPay let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">Payment</h4>
	  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		<span aria-hidden="true">×</span>
	  </button>
	</div>
	<div class="modal-body" style="padding: 3rem 5rem 1rem 5rem!important;">
		<app-quick-pay [domains]="new_domains"></app-quick-pay>
	</div>
	<div class="modal-footer">
	  	<button type="button" class="btn btn-danger" (click)="modal.close('Save click');">Close</button>
	</div>
</ng-template>

<ng-template #modalIncomplete let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">Incomplete Forms</h4>

	  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		<span aria-hidden="true">×</span>
	  </button>
	</div>
	<div class="modal-body" style="padding: 3rem 5rem 1rem 5rem!important;">
		<h5 class="centerText">Please complete the following to gain access to your keys:</h5><br>
		<div *ngIf="!dfc" class="centerText">
			<button class="btn btn-danger" (click)="manageClick(this.sd);this.modalReference.close();">Complete Company Details</button>
		</div><br>
		<div *ngIf="!op" class="centerText">
			<button class="btn btn-danger" (click)="thirdClick(this.sd);this.modalReference.close();">Select Other Parties</button>
		</div><br>
		<div *ngIf="!cfc" class="centerText">
			<button class="btn btn-danger" (click)="consentClick(this.sd);this.modalReference.close();">Complete Consent Form Setup</button>
		</div><br>
	</div>
</ng-template>
