import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  device: any = 0;
  original_price_monthly: any = 200;
  original_price_yearly: any = 2000;

  price_monthly: any = 200;
  price_yearly: any = 2000;
  little_as: any = 167;

  active_plugins: any;
  active_pro_users: any;
  active_consents: any;
  active_requests: any;
  
  discount:any = 0;

  constructor(private _vps: ViewportScroller, private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
    
    this.apiService.getStats().subscribe(
      (res: any) => {
        this.active_plugins = Number(res.active_plugins) + 63;
        this.active_pro_users = Number(res.active_pro_users) + 24;
        this.active_consents = Number(res.active_consents) + 227;
        this.active_requests = Number(res.active_requests) + 6;
      },
      error => console.log(error)
    );
    this._vps.scrollToPosition([0, 0]);
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.apiService.getPrice().subscribe(
      (result: any) => {
        for(let child of result){
          this.price_monthly = child.value;
          this.price_yearly = this.price_monthly * 10
          this.discount = 1.00 - (this.price_monthly/this.original_price_monthly);
          this.discount = (this.discount*100);
          this.little_as = (this.price_monthly * 10) / 12
        }
      },
      error => console.log(error)
    );
  }
  

}

