import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-add-editor',
  templateUrl: './add-editor.component.html',
  styleUrls: ['./add-editor.component.scss']
})

export class AddEditorComponent implements OnInit {
  device = 0;
  company_id: any;
  company: any;
  company_domain: any;
  editors: any = [];
  errors = false;
  success = false;
  editorRemoved = false;
  currentDate = formatDate(new Date(), 'dd-MM-yyyy', 'en-ZA');

  inviteForm = new FormGroup({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required)
  });

  @ViewChild('modalDataPay') modalDataPay: any;
  @ViewChild('closeModals') closeModals: any;
  modalReference : any;
  modalContent : any;
  closeModal: any;
  def_name: any;
  def_surname: any;

  constructor(private router: Router, private modalService: NgbModal, private route: ActivatedRoute, private apiService: ApiService, private cookieService: CookieService) { }

  ngOnInit(): void {    
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.route.params.subscribe(event => {
      this.company_id = event.id;
      this.apiService.getCompanyName(this.company_id).subscribe(
        (res: any) => {
          this.company = res[0];
          this.company_domain = res[0].company_domain
        },
        error => console.log(error)
      );
      this.apiService.getEditors(this.company_id).subscribe(
        (res: any) => {
          this.editors = res;
        },
        error => console.log(error)
      );
    });
    
    this.apiService.getProfile().subscribe(
      (result: any) => {
        const res = result[0];
        this.def_name = res.data_officer_first_name;
        this.def_surname = res.data_officer_surname;
      },
      error => console.log(error)
    );
  }
  
  goBack(){
    this.router.navigate(['/portal/']);
  }

  triggerModal(content:any) {
    this.modalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'})
    this.modalReference.result.then((res:any) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res:any) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }
  triggerModal2(content:any, editor: any) {
    this.modalContent = editor;
    this.modalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'})
    this.modalReference.result.then((res:any) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res:any) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getRandomString() {
    var randomChars = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz23456789';
    var result = '';
    for ( var i = 0; i < 8; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  saveForm(){ 
    const email = this.cookieService.get('bpc-email');   
    if(this.inviteForm.invalid){
      this.errors = true;
    } else {
      if(this.inviteForm.value.email != email){
        this.apiService.newEditor(this.inviteForm.value.name, this.inviteForm.value.surname, this.inviteForm.value.email, this.company_id, this.currentDate, this.company.trading_name).subscribe(
          res => {
            this.apiService.checkIfUserExists(this.inviteForm.value.email).subscribe(
              (res: any) => {
                if(res && res.length > 0){
                  this.apiService.notifyEditor(email, this.inviteForm.value.email, this.currentDate, res[0].id, this.def_name, this.def_surname, this.company_id, this.company_domain).subscribe(
                    () => {
                    },
                    error => {
                      this.errors = true;
                    }
                  )
                } else {
                  let password = this.getRandomString();
                  this.apiService.registerUser(this.inviteForm.value.email, this.inviteForm.value.email, password).subscribe(
                    (res: any) => {
                      this.apiService.registerEditor(email, this.inviteForm.value.email, password, this.currentDate, res.id, this.def_name, this.def_surname, this.company_id, this.company_domain).subscribe(
                        () => {
                          this.apiService.forgotPassword(this.inviteForm.value.email).subscribe(
                            () => {
                            },
                            error => console.log(error)
                          );
                        },
                        error => {
                          this.errors = true;
                        }
                      )
                    },
                    error => {
                      this.errors = true;
                    }
                  )
                }
              },
              error => {
                this.errors = true;
              }
            ) 
            this.success = true;
            this.modalReference.close();
          },
          error => {
            this.errors = true;
          }
        )
      } else{
        this.errors = true;
      }
    }
  }
  removeEditor(id: any){
    this.apiService.removeEditor(id).subscribe(
      (res: any) => {
        this.editorRemoved = true;
        this.modalReference.close();
        this.apiService.getEditors(this.company_id).subscribe(
          (res: any) => {
            this.editors = res;
          },
          error => console.log(error)
        );
      },
      error => console.log(error)
    );
  }
}
