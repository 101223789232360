import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'; 
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  url = '';
  logo = '';
  isNavbarCollapsed=true;
  device = 0;
  tab: any;
  is_admin = false;

  constructor(private cookieService: CookieService, private router:Router) { }
  
  ngOnInit() {       
    const admin = this.cookieService.get('bpc-admin');
    if(admin == '1'){
      this.is_admin = true;
    }
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.activeTabs()
  }
  activeTabs(){
    let url = window.location.href;
    if(url.includes('wordpress')){
      this.tab = 'wp';
    }
    if(url.includes('main')){
      this.tab = 'about';
    }
    if(url.includes('faq')){
      this.tab = 'faq';
    }
    if(url.includes('custom_dev')){
      this.tab = 'cd';
    }
    if(url.includes('popi_act')){
      this.tab = 'popi';
    }
    if(url.includes('auth')){
      this.tab = 'lr';
    }
  }
  navbar(){
    if(this.cookieService.get('bpc-token')){
      return 1;
    } else {
      return 2;
    }
  }

  logout() {
    this.cookieService.deleteAll('/');
    this.router.navigate(['/main']);
  }
  
  login() {
    this.router.navigate(['/auth']);
  }
  
  home() {
    this.router.navigate(['/main']);
  }
  domains() {
    this.router.navigate(['/portal']);
  }
  goBeta(){
    
  }
}
