<div [ngClass]="this.device == 1 ? 'layout_mobile' : ''">
    <h1 style="text-align:center;" *ngIf="!success">BULK COMPANY ADD</h1>
    <div id="manage-domain" *ngIf="!success">
        <form [formGroup]="bulkForm" style="margin: 0% 20%;">
            <h2 style="text-align: center;color: #B61F21;margin: 5% 0%;">General Information</h2>
            <div [ngClass]="this.device == 1 ? 'manageWrap_mobile' : 'manageWrap'">
                <div>
                    <label>Price</label><br>
                    <input type="text" formControlName="req_payment"  class="roundedInputs"/><br>
                </div>
                <div>
                    <label>User ID</label><br>
                    <input type="text" formControlName="user_id" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>List Of Companies To Add</label><br>
                    <textarea formControlName="urls" class="roundedInputs" style="margin-top: 0px; margin-bottom: 10px; height: 50px;"></textarea>   
                </div>
                <div>
                    <label>Deputy Information Officer IDs</label><br>
                    <input type="text" formControlName="deputy_info_officer" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Creator Name</label><br>
                    <input type="text" formControlName="invitee_name" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Creator Surname</label><br>
                    <input type="text" formControlName="invitee_surname" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>List Of Editor Emails</label><br>
                    <textarea formControlName="editors" class="roundedInputs" style="margin-top: 0px; margin-bottom: 10px; height: 50px;"></textarea>   
                </div>
            </div>
            <h2 style="text-align: center;color: #B61F21;margin: 5% 0%;">Company Details</h2>
            <div>
                <label>Company Logo</label><br>
                <img alt="" id="img" width="200" src={{bulkForm.value.company_logo}}>
                <input type="file" name="company_logo" id="company_logo" (change)="onUploadChange($event)" accept=".png, .jpg, .jpeg, .pdf"  />
            </div>
            <div [ngClass]="this.device == 1 ? 'manageWrap_mobile' : 'manageWrap'">
                <div>
                    <label>Full registered name of Body</label><br>
                    <input type="text" formControlName="company_name"  class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Trading name of Body</label><br>
                    <input type="text" formControlName="trading_name" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Company Registration Number</label><br>
                    <input type="text" formControlName="company_reg" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Tax Number</label><br>
                    <input type="text" formControlName="tax_number" class="roundedInputs"/><br>   
                </div>
                <div>
                    <label>Office Number</label><br>
                    <input type="text" formControlName="office_number" class="roundedInputs"/><br>                  
                </div>
                <div>
                    <label>Direct Email</label><br>
                    <input type="text" formControlName="direct_email" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Type Of Body</label>
                    <select formControlName="type_of_body" class="roundedInputs selects" style="height: 45px;font-size: 15px;padding-left: 5px;width: 100%;" [ngClass]="this.bulkForm.value.type_of_body == '' ? 'colorGrey' : 'colorBlack'">
                        <option hidden value='' disabled selected>Select Body Type</option>
                        <option *ngFor="let choice of body_type_options" [ngValue]="choice.id" class="colorBlack">{{choice.name}}</option>
                    </select>
                </div>
                <div>
                    <label>Entity Type</label>
                    <select formControlName="entity_type" class="roundedInputs selects" style="height: 45px;font-size: 15px;padding-left: 5px;width: 100%;" [ngClass]="this.bulkForm.value.type_of_body == '' ? 'colorGrey' : 'colorBlack'">
                        <option hidden value='' disabled selected>Select Entity Type</option>
                        <option *ngFor="let choice of entity_type_options" [ngValue]="choice.id" class="colorBlack">{{choice.name}}</option>
                    </select>
                </div>
                
                
                <div>
                    <label>{{this.bulkForm.value.retain_records}} Is your company required by law to retain records?</label><br>
                    
                    <button class="btn left-btn" id="btn1" (click)="retainRecordsChange('yes')" [ngClass]="this.bulkForm.value.retain_records == 'yes' ? 'btn-selected' : 'btn-not-selected'">Yes</button>
                    <button class="btn right-btn" id="btn2" (click)="retainRecordsChange('no')" [ngClass]="this.bulkForm.value.retain_records == 'no' ? 'btn-selected' : 'btn-not-selected'">No</button>
                </div>
                <div *ngIf="this.bulkForm.value.retain_records == 'yes'">
                    <label>Provide the reason for the retention of the data.</label><br>
                    <input type="text" class="roundedInputs" formControlName="retain_records_reason" placeholder="This is because the FIC Act, Act 38 of 2001 requires us to retain data for a 5 year period">
                </div>
                <div *ngIf="this.bulkForm.value.retain_records == 'yes'">
                    <label>Should the data be retained for a fixed term or until a specific date?<span style="color: red;">*</span></label><br>
                    
                    <button class="btn left-btn" id="btn1" style="width: 125px;" (click)="retainPeriodChange('fixed')" [ngClass]="this.bulkForm.value.retain_period == 'fixed' ? 'btn-selected' : 'btn-not-selected'">Fixed Term</button>
                    <button class="btn right-btn" id="btn2" style="width: 125px;" (click)="retainPeriodChange('specific')" [ngClass]="this.bulkForm.value.retain_period == 'specific' ? 'btn-selected' : 'btn-not-selected'">Specific Date</button>
                </div>
                <div *ngIf="this.bulkForm.value.retain_period == 'fixed' && this.bulkForm.value.retain_records == 'yes'">
                    <label>Retain data for a specific period</label><br>
                    <div style="display: inline-flex;">
                        <div>
                            <label>Years</label><br>
                            <input type="number" class="roundedInputs" style="width: 70%;" placeholder="0" formControlName="retain_years">
                        </div>
                        <div>
                            <label>Months</label><br>
                            <input type="number" class="roundedInputs" style="width: 70%;" placeholder="0" formControlName="retain_months">
                        </div>
                    </div>
                </div>
                <div *ngIf="this.bulkForm.value.retain_period == 'specific' && this.bulkForm.value.retain_records == 'yes'">
                    <label>Retain data until a specific date</label><br>
                    <input type="date" class="roundedInputs" style="width: 58%;" formControlName="retain_date">
                </div>
            </div><br>
            <h3 style="margin-top: 40px;">Physical Address</h3>
            <div [ngClass]="this.device == 1 ? 'manageWrap_mobile' : 'manageWrap'">
                <div>
                    <label>Street Number</label><br>
                    <input type="text" formControlName="ph_street_number" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Street Name</label><br>
                    <input type="text" formControlName="ph_street_name" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Suburb</label><br>
                    <input type="text" formControlName="ph_suburb" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Town/City</label><br>
                    <input type="text" formControlName="ph_town_city" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Province</label><br>
                    <input type="text" formControlName="ph_province" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Postal Code</label><br>
                    <input type="text" formControlName="ph_postal_code" class="roundedInputs"/><br>
                </div>
            </div><br>
            <div style="width: 100%;display: inline-flex;margin: 20px 0px;">
                <input type="checkbox" formControlName="postal_same_as_physical" style="width: 20px;"/><br>
                <label style="margin-top: -7px;">Postal Address Same As Physical Address</label>
            </div><br>
            <div *ngIf="!this.bulkForm.value.postal_same_as_physical">
                <h3>Postal Address</h3>
                <div [ngClass]="this.device == 1 ? 'manageWrap_mobile' : 'manageWrap'">
                    <div>
                        <label>Street Number</label><br>
                        <input type="text" formControlName="po_street_number" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Street Name</label><br>
                        <input type="text" formControlName="po_street_name" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Suburb</label><br>
                        <input type="text" formControlName="po_suburb" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Town/City</label><br>
                        <input type="text" formControlName="po_town_city" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Province</label><br>
                        <input type="text" formControlName="po_province" class="roundedInputs"/><br>
                    </div>
                    <div>
                        <label>Postal Code</label><br>
                        <input type="text" formControlName="po_postal_code" class="roundedInputs"/><br>
                    </div>
                </div>
            </div>
            <h2 style="text-align: center;color: #B61F21;margin: 5% 0%;">Third Parties</h2>
            <div>
                <label>Third Party IDs</label><br>
                <input type="text" formControlName="other_parties" class="roundedInputs"/><br>
            </div>
            <h2 style="text-align: center;color: #B61F21;margin: 5% 0%;">Company Consent Details</h2>
            <div class="formDivs">
                <label>We require service level communication with our clients, in order to fulfill our duties.</label><br>
                
                <div>
                    <button class="btn left-btn" id="btn1" (click)="serviceLevelCommunicationChange('yes')" [ngClass]="this.bulkForm.value.service_level_communication == 'yes' ? 'btn-selected' : 'btn-not-selected'">Yes</button>
                    <button class="btn right-btn" id="btn2" (click)="serviceLevelCommunicationChange('no')" [ngClass]="this.bulkForm.value.service_level_communication == 'no' ? 'btn-selected' : 'btn-not-selected'">No</button>
                </div>
                <div *ngIf="this.bulkForm.value.service_level_communication == 'yes'"><br>
                    <label style="margin: 10px 0px 10px 0px;">Please select all mediums that you use for communication</label><br>
                    <div>
                        <button class="btn left-btn" id="btn3" (click)="addCommunicationMediums('SMS')" [ngClass]="this.communication_mediums.includes('SMS') ? 'btn-selected' : 'btn-not-selected'">SMS</button>
                    
                        <button class="btn left-btn" id="btn4" (click)="addCommunicationMediums('Telephone')" [ngClass]="this.communication_mediums.includes('Telephone') ? 'btn-selected' : 'btn-not-selected'">Telephone</button>
                                          
                        <button class="btn left-btn" id="btn5" (click)="addCommunicationMediums('Email')" [ngClass]="this.communication_mediums.includes('Email') ? 'btn-selected' : 'btn-not-selected'">Email</button>
                    
                        <button class="btn left-btn" id="btn6" (click)="addCommunicationMediums('Whatsapp')" [ngClass]="this.communication_mediums.includes('Whatsapp') ? 'btn-selected' : 'btn-not-selected'">Whatsapp</button>
                    
                        <button class="btn left-btn" id="btn7" (click)="addCommunicationMediums('Telegram')" [ngClass]="this.communication_mediums.includes('Telegram') ? 'btn-selected' : 'btn-not-selected'">Telegram</button>
                   
                        <button class="btn left-btn" id="btn8" (click)="addCommunicationMediums('Messenger')" [ngClass]="this.communication_mediums.includes('Messenger') ? 'btn-selected' : 'btn-not-selected'">Messenger</button>
                    </div><br>
                    <div class="inlineGrid2" style="width: 195px;">
                        <input type="text" formControlName="mediums_of_communication_other" class="roundedInputs" placeholder="Other" (keyup)="onKeyUpCommunication($event)" style="height: 30px;margin-bottom: 10px;">
                    </div>
                    <ng-multiselect-dropdown formControlName="mediums_of_communication_required" [placeholder]="'SELECT up to 3 that is required'" [settings]="dropdownSettings" [data]="communication_mediums" (onSelect)="onItemSelectCommunications($event)" (onDeSelect)="onItemDeSelectCommunications($event)"></ng-multiselect-dropdown>
                </div>            
            </div>        
            <div class="formDivs">
                <label>We send out marketing communications to our clients.</label><br>
                
                <div>
                    <button class="btn left-btn" id="btn9" (click)="marketingCommunicationChange('yes')" [ngClass]="this.bulkForm.value.marketing_communication == 'yes' ? 'btn-selected' : 'btn-not-selected'">Yes</button>
                    <button class="btn right-btn" id="btn10" (click)="marketingCommunicationChange('no')" [ngClass]="this.bulkForm.value.marketing_communication == 'no' ? 'btn-selected' : 'btn-not-selected'">No</button>
                </div>
                <div *ngIf="this.bulkForm.value.marketing_communication == 'yes'">
                    <label style="margin: 10px 0px 10px 0px;">Please select all mediums that you use for marketing</label><br>
                    <div>
                        <button class="btn left-btn" id="btn11" (click)="addMarketingMediums('SMS')" [ngClass]="this.marketing_mediums.includes('SMS') ? 'btn-selected' : 'btn-not-selected'">SMS</button>
                    
                        <button class="btn left-btn" id="btn12" (click)="addMarketingMediums('Telephone')" [ngClass]="this.marketing_mediums.includes('Telephone') ? 'btn-selected' : 'btn-not-selected'">Telephone</button>
                                           
                        <button class="btn left-btn" id="btn13" (click)="addMarketingMediums('Email')" [ngClass]="this.marketing_mediums.includes('Email') ? 'btn-selected' : 'btn-not-selected'">Email</button>
                    
                        <button class="btn left-btn" id="btn14" (click)="addMarketingMediums('Whatsapp')" [ngClass]="this.marketing_mediums.includes('Whatsapp') ? 'btn-selected' : 'btn-not-selected'">Whatsapp</button>
                    
                        <button class="btn left-btn" id="btn15" (click)="addMarketingMediums('Telegram')" [ngClass]="this.marketing_mediums.includes('Telegram') ? 'btn-selected' : 'btn-not-selected'">Telegram</button>
                    
                        <button class="btn left-btn" id="btn16" (click)="addMarketingMediums('Messenger')" [ngClass]="this.marketing_mediums.includes('Messenger') ? 'btn-selected' : 'btn-not-selected'">Messenger</button>
                    </div><br>
                    <div class="inlineGrid2" style="width: 195px;">
                        <input type="text" formControlName="mediums_of_marketing_other" class="roundedInputs" placeholder="Other" (keyup)="onKeyUpMarketing($event)" style="height: 30px;margin-bottom: 10px;">
                    </div>
                    <!-- <ng-multiselect-dropdown [placeholder]="'SELECT up to 3 that is required'" [settings]="dropdownSettings" [data]="dropdownListMarketing" (onSelect)="onItemSelectMarketing($event)" (onDeSelect)="onItemDeSelectMarketing($event)"></ng-multiselect-dropdown> -->
                </div>            
            </div><br>
            <div>
                <label>List the services you offer through this entity, please separate each with a comma</label>
                <input type="text" formControlName="services" class="roundedInputs">
            </div>
            
            <div class="formDivs">
                <label>What will happen for users that do not provide consent?</label>
                <select formControlName="no_consent_consequences" class="roundedInputs" style="height: 45px;font-size: 15px;padding-left: 5px;width: 100%;">
                    <option [ngValue]="null" disabled></option>
                    <!-- <option value="1">We will not be able to fullfill your order unless consent is provided.</option>
                    <option value="2">We will not be able to process your request unless consent is provided</option>
                    <option value="3">We shall fullfill your order and therafter delete your account from our system, unless consent is provided.</option> -->
                    <option value="4">We shall retain your data indefinitely, and you shall not be able to request data from our site due to authentication issues unless you first provide consent.</option>
                </select> 
            </div>
            
            <div class="formDivs">
                <label>List the TYPES OF INFORMATION that you collect. (ID, Company Registration Number, Full names, Cellphone Number, etc.)</label>
                <input type="text" formControlName="information_types" class="roundedInputs">
            </div>

            <div class="formDivs">
                <label>How is the data obtained?</label>
                <select formControlName="data_obtained" class="roundedInputs" style="height: 45px;font-size: 15px;padding-left: 5px;width: 100%;">
                    <option [ngValue]="null" disabled></option>
                    <option value="1">Supplied by the DATA SUBJECT.</option>
                    <option value="2">Provided by third parties</option>
                    <option value="3">Both supplied by DATA SUBJECT and third parties.</option>
                </select> 
            </div>
            
            <div class="formDivs">
                <label>Is the data that is being collected Mandatory/Voluntarily?</label><br>
                <div>
                    <select formControlName="man_vol" class="roundedInputs" style="height: 45px;font-size: 15px;padding-left: 5px;width: 100%;">
                        <option [ngValue]="null" disabled> </option>
                        <option value="vol">The data is voluntarily provided to aid us with fullfilling our services.</option>
                        <option value="man">The data is mandatory since we are unable to fullfill our services without it.</option>
                    </select>
                    
                    <div *ngIf="this.bulkForm.value.man_vol != ''">
                        <label>Explain why</label>
                        <input type="text" formControlName="man_vol_reason" class="roundedInputs">
                    </div>
                </div>
            </div>

            <div class="formDivs">
                <label>Explain why this info is required with regards to the contractual agreement you have with the client.</label>
                <select formControlName="required_reason" class="roundedInputs" style="height: 45px;font-size: 15px;padding-left: 5px;width: 100%;">
                    <option [ngValue]="null" disabled></option>
                    <option value="1">Without this information we are not able to fullfill your request.</option>
                    <option value="2">With out this information we are not able to process your order.</option>
                </select> 
            </div>        
            
            <div class="formDivs">
                <label>Does any of the data you collect cross the borders of South Africa</label><br>
                <div>
                    <button class="btn left-btn" id="btn17" (click)="crossBorderChange('yes')" [ngClass]="this.bulkForm.value.cross_border == 'yes' ? 'btn-selected' : 'btn-not-selected'">Yes</button>
                    <button class="btn right-btn" id="btn18" (click)="crossBorderChange('no')" [ngClass]="this.bulkForm.value.cross_border == 'no' ? 'btn-selected' : 'btn-not-selected'">No</button>
                </div>
                <div *ngIf="this.bulkForm.value.cross_border == 'yes'">
                    <label>Declare for what reason and if the country it is transferred through or too also comply with the requirements of the POPI Act.</label>
                    <input type="text" formControlName="cross_border_reason" class="roundedInputs">
                </div>
            </div>

            <div class="formDivs">
                <label>Active Tasks - There are some tasks that you have to monitor daily, to stay compliant and to avoid hefty fines or imprisonment. But don't worry, we are taking care of most of it!</label>
                <p>The only thing you need to do is the following: What to do when data gets breached? Visit  our website, log in to your account and select the "Data Breach" section from the menu. Please answer the questions and we will make sure that the nocises get send out to the affected parties. In the event that your website has been compromised or it becomes evident that any of your employees collected or distributed any of your clients data, or any other event happened where data got lost you should send out a notice to all affected parties. If you are not sure who was affected you should send a notice to your entire database notifying them that their data might have been compromised.</p>
            </div>

            <div class="formDivs">
                
                <label>Provide any extras for the consent form.</label>
                <textarea formControlName="consent_extras" class="roundedInputs" ></textarea>
            </div>
            
            <div class="formDivs">
                <label>Do you use Social Media Tools such as Facebook Like buttons, feeds,  Twitter feeds or any other sharing tools?</label><br>
                <div>
                    <button class="btn left-btn" id="btn19" (click)="socialMediaToolsChange('yes')" [ngClass]="this.bulkForm.value.social_media_tools == 'yes' ? 'btn-selected' : 'btn-not-selected'">Yes</button>
                    <button class="btn right-btn" id="btn20" (click)="socialMediaToolsChange('no')" [ngClass]="this.bulkForm.value.social_media_tools == 'no' ? 'btn-selected' : 'btn-not-selected'">No</button>
                </div>
            </div>

            <div class="formDivs">
                <label>Is this particular website a WordPress site?</label><br>
                <div>
                    <button class="btn left-btn" id="btn21" (click)="wordpressWebsiteChange('yes')" [ngClass]="this.bulkForm.value.wordpress_website == 'yes' ? 'btn-selected' : 'btn-not-selected'">Yes</button>
                    <button class="btn right-btn" id="btn22" (click)="wordpressWebsiteChange('no')" [ngClass]="this.bulkForm.value.wordpress_website == 'no' ? 'btn-selected' : 'btn-not-selected'">No</button>
                </div>
            </div>
            <br>      
            <br>      
            <br>      
            <br>      
            <br>      
            <br>      
            <div class="submitButton">
                <button type="button" class="btn btn-danger snbtnrbgd" (click)="goSave()" ng-disabled="bulkForm.$invalid">Save</button>
            </div> 
            <br>      
            <br>      
            <br>      
            <br>      
            <br>      
            <br> 
        </form>
    </div>
    <div *ngIf="success" class="centerText">
        <h2 style="margin-top:20px;">Successfully Added Bulk Companies</h2>
        <i class="icon-check-circle-o" [ngClass]="this.device == 1 ? 'success_mobile' : 'success'"></i>
    </div>
</div>

