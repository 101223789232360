import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

import { AuthComponent } from './auth.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActionComponent } from './action/action.component';
import { RequestsComponent } from './requests/requests.component';


const routes: Routes = [
  {path: 'auth', component: AuthComponent},
  {path: 'auth/:reg', component: AuthComponent},
  {path: 'auth/:reg/:ref', component: AuthComponent},
  {path: 'reset_password_confirm/:token', component: ResetPasswordComponent},

  {path: 'auth/:choice/:type/:id/:company_id', component: ActionComponent},
  {path: 'data/deletion/:choice/:id', component: RequestsComponent},
  {path: 'data/request/:choice/:id', component: RequestsComponent},
];

@NgModule({
  declarations: [AuthComponent, ResetPasswordComponent, ActionComponent, RequestsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    CookieService
  ]
})
export class AuthModule { }