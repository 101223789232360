import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  domain:any;
  domainDetails:any = [];
  disabled = true;
  thirdParties:any = [];
  thirdPartyDetails:any = [];
  bepopiacomplaint:any;
  bepopiacomplainthosting:any;
  bepopiacomplaintpayment:any;
  showContactDetails:any = [];

  constructor(private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(event => {
      this.domain = event.domain;
      this.apiService.getDomainDetailsForRP(this.domain).subscribe(
        (result: any) => {
          for(let child of result){
            this.domainDetails = child;
            this.thirdParties = this.domainDetails.other_parties.split(",");
            for(let third of this.thirdParties){
              this.apiService.getThirdPartiesForRP(third).subscribe(
                (result: any) => {
                  for(let res of result){
                    this.thirdPartyDetails.push(res);
                  }
                },
                error => console.log(error)
              );
            }
          }
        },
        error => console.log(error)
      );
    });
    this.apiService.getThirdPartiesForRP(1).subscribe(
      (result: any) => {
        for(let child of result){
          this.bepopiacomplaint = child
        }
      },
      error => console.log(error)
      );
    this.apiService.getThirdPartiesForRP(2).subscribe(
      (result: any) => {
        for(let child of result){
          this.bepopiacomplainthosting = child
        }
      },
      error => console.log(error)
    );
    this.apiService.getThirdPartiesForRP(7).subscribe(
      (result: any) => {
        for(let child of result){
          this.bepopiacomplaintpayment = child
        }
      },
      error => console.log(error)
    );
  }
  show(id: any){
    if(this.showContactDetails.includes(id)){   
      this.showContactDetails = this.showContactDetails.filter((item:any) => item !== id);
    } else {
      this.showContactDetails.push(id);
    }
  }
}
