<!-- <div id="contact" class="forms" [ngClass]="this.device == 1 ? 'fullBackground_mobile' : 'fullBackground'"><br> -->
<div id="contact" class="forms" [ngClass]="{'fullBackground_mobile':this.device == 1, 'fullBackground': this.device == 0, 'contact_page':tab == 'contact'}" ><br>
    <form [formGroup]="contactForm" *ngIf="this.contact_success == false">
        <h1 *ngIf="tab == 'contact'" class="centerText">Contact Us</h1>
        <h3 class="centerText" style="margin-bottom: 50px;">For Help and Suggestions, please contact us using the form below.</h3>
        
        <div>
            <label>Please select why you are contacting us:</label>
            <select formControlName="contact_reason" class="roundedInputs" style="height: 45px;font-size: 15px;padding-left: 5px;" [ngClass]="this.contactForm.value.contact_reason == '' ? 'colorGrey' : 'colorBlack'">
                <option hidden value='' disabled selected>Select Reason</option>
                <option *ngFor="let choice of contact_reason" [ngValue]="choice.id" class="colorBlack">{{choice.name}}</option>
            </select> 
        </div>
        <div>
            <label>Domain</label>
            <input type="text" formControlName="domain" class="roundedInputs" placeholder="https://bepopiacompliant.co.za">
        </div>
        <div>
            <label>First name</label>
            <input type="text" formControlName="name" class="roundedInputs" placeholder="First name">
        </div>
        <div>
            <label>Surname</label>
            <input type="text" formControlName="surname" class="roundedInputs" placeholder="Surname">
        </div>
        <div>
            <label>Email</label>
            <input type="text" formControlName="email" class="roundedInputs" placeholder="email@example.com">
        </div>
        <div>
            <label>Cell</label>
            <input type="text" formControlName="cell" class="roundedInputs" placeholder="Cell">
        </div>
        <div>
            <label>Comment/Message</label>
            <textarea formControlName="message" class="roundedInputs" placeholder="Comment/Message" style="margin-top: 0px; margin-bottom: 10px; height: 200px;"></textarea>
        </div>
        <div class="alert alert-danger centerText" id="failed" *ngIf="this.formIncomplete">
            <i style="float: right;cursor: pointer;" class="icon-times" (click)="this.formIncomplete = false"></i>
            Please ensure you complete the form
        </div>
        <button class="btn btn-danger" (click)="submitForm()">Submit</button>
    </form>
    <div *ngIf="this.contact_success" class="centerText" style="margin-bottom: -110px;">
        <h3>Contact Form Successfully Submitted</h3>
        <h5>We will get back to you as soon as possible.</h5>
        <i class="icon-check-circle-o" [ngClass]="this.device == 1 ? 'success_mobile' : 'success'"></i>
    </div>
    <br>
</div>
<app-footer *ngIf="tab == 'contact'"></app-footer>