import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  successful:any = [];
  unsuccessful:any = [];
  device = 0

  constructor(private apiService: ApiService, private router: Router) { }
  
  ngOnInit(): void {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.apiService.checkPaymentSuccessful().subscribe(
      (result: any) => {
        for(let child of result){
          this.successful.push(child);
          this.apiService.updatePaymentSuccessful(child.id, 0).subscribe(
            () => {
            },
            error => console.log(error)
          )
        }
      },
      error => console.log(error)
    )
    this.apiService.checkPaymentUnsuccessful().subscribe(
      (result: any) => {
        for(let child of result){
          this.unsuccessful.push(child);
          this.apiService.updatePaymentUnuccessful(child.id, 0).subscribe(
            () => {
            },
            error => console.log(error)
          )
        }
      },
      error => console.log(error)
    )
  }

  goToDomains(){
    this.router.navigate(['/portal']);
  }
}
