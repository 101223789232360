import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  choice = 0;
  active = 0;
  
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(event => {
      if(event.choice == 'accept'){
        this.choice = 1;
      }
      if(event.choice == 'decline'){
        this.choice = 0;
        this.active = 0;
      }
      if (this.router.url.indexOf('deletion') > -1) {
      }
      if (this.router.url.indexOf('request') > -1) {
      }
      
    });
  }

}
