import { ApiService } from 'src/app/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  device = 0;
  contact_success = false;
  contact_reason_fin: any;
  form_type: any;
  tab = '';
  formIncomplete = false;

  @Input() form: any;

  contactForm = new FormGroup({
    contact_reason: new FormControl('', Validators.required),
    domain: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    cell: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  })
  
  contact_reason = [
    { id: 1, name: "I require help" },
    { id: 2, name: "I have a suggestion" },
    { id: 3, name: "I want to find out about your SLA packages" },
    { id: 4, name: "I want to find out about a Company Registration" },
    { id: 5, name: "I want to find out about Local Hosting" },
    { id: 6, name: "I want to request my data" },
    { id: 6, name: "I want to request and delete my data" },
    { id: 7, name: "I want to delete my data" }
  ];

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {    
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    let url = window.location.href;
    if(url.includes('contact')){
      this.tab = 'contact';
    }
  }

  submitForm(){
    if(this.contactForm.invalid){
      this.formIncomplete = true;
    } else {
      this.contact_reason_fin = this.contact_reason[this.contactForm.value.contact_reason - 1].name;
      
      if(this.form == "cd"){
        this.form_type = 1;
      } else if(this.form == "wp"){
        this.form_type = 2;
      }
  
      this.apiService.newWe(this.form_type, this.contact_reason_fin, this.contactForm.value.domain, this.contactForm.value.name, this.contactForm.value.surname, this.contactForm.value.email, this.contactForm.value.cell, this.contactForm.value.message).subscribe(
        () => {
          this.contact_success = true;
        },
        error => console.log(error)
      )
    }
  }

}
