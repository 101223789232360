<div [ngClass]="this.device == 1 ? 'faqMain_mobile' : 'faqMain'">
    <div class="centerText">
        <div>
            <button class="btn" (click)="changeContent('all')" [ngClass]="this.chosenBtn == 'all' ? 'btn-selected' : 'btn-not-selected'">ALL</button>
            <button class="btn ml20" (click)="changeContent('popia')" [ngClass]="this.chosenBtn == 'popia' ? 'btn-selected' : 'btn-not-selected'">POPIA</button>
            <button class="btn ml20" (click)="changeContent('bpc')" [ngClass]="this.chosenBtn == 'bpc' ? 'btn-selected' : 'btn-not-selected'">BPC</button>
        </div><br>
        <h1>Questions and Answers about 
            <span *ngIf="this.chosenBtn == 'all'">POPIA & Be POPIA Compliant</span>
            <span *ngIf="this.chosenBtn == 'popia'">POPIA</span>
            <span *ngIf="this.chosenBtn == 'bpc'">Be POPIA Compliant</span>
        </h1>
        <h3>Below you will find the most asked questions with answers to each question.</h3>
    </div> 
    <div class="faq" *ngIf="faqs_audio == null">
        <div *ngFor="let faq of faqs" class="faq_content">
            <h5>{{faq.question}}</h5>
            <div [innerHtml]="faq.answer"></div>
        </div>
    </div>
    <div class="faq" *ngIf="faqs_audio != null">
        <div *ngFor="let faq of faqs" class="faq_content">
            <h5>{{faq.question}}
                <br *ngIf="this.device == 1">
                <span *ngFor="let aud of faqs_audio">
                    <audio *ngIf="aud.audio && aud.id == faq.id" id="player" controls [ngClass]="this.device == 1 ? 'audio_mobile' : 'audio'">
                        <source src="data:audio/ogg;base64,{{aud.audio}}" type="audio/ogg">
                    </audio>
                </span>
            </h5>
            <div [innerHtml]="faq.answer"></div>
        </div>
    </div>
</div>
<app-footer></app-footer>