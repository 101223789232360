import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  failed = false;
  username_exists = false;
  username_error = false;
  password_error = false;
  ip: any;
  show_sent_email = false;
  email_not_set = false;
  fieldTextType: any;
  ref: any;

  authForm = new FormGroup({
    id_number: new FormControl(''),
    email: new FormControl('', Validators.email),
    password: new FormControl(''),
    ref_code: new FormControl('')
  });
  registerMode = false;

  constructor( private apiService: ApiService, private cookieService: CookieService, private router: Router, private http: HttpClient, private route: ActivatedRoute ) { }

  ngOnInit() {
    this.ref = this.cookieService.get('bpc-ref');
    if(this.ref){
      this.authForm.patchValue({
        ref_code: this.ref,
      })
    }

    const bpcToken = this.cookieService.get('bpc-token');
    if (bpcToken) {
      this.router.navigate(['/portal']);
    }
    
    this.route.params.subscribe(event => {
      if(event.reg == 1){
        this.registerMode = true;
      }
      
      if(event.ref){
        const myDate: Date = new Date();
        myDate.setHours( myDate.getHours() + 4380 );
        this.cookieService.set('bpc-ref', event.ref, myDate);
        this.ref = this.cookieService.get('bpc-ref');
      }
    });
    
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ip = res.ip;
      this.getUsernameFunc()
    });
  }
  getUsernameFunc(){
    this.apiService.getUsername(this.ip).subscribe(
      (result: any) => {
        if(result != ''){
          var res = result[result.length-1] 
          var resDate:any = new Date(res.created).getTime()
          var dateNow:any = new Date().getTime()
          var resDate2 = '' + resDate;
          var resDate3:any = '' + resDate2[0] + resDate2[1] + resDate2[2] + resDate2[3] + resDate2[4] + resDate2[5] + resDate2[6] + resDate2[7] + resDate2[8] + resDate2[9];
          var dateNow2 = '' + dateNow;
          var dateNow3:any = '' + dateNow2[0] + dateNow2[1] + dateNow2[2] + dateNow2[3] + dateNow2[4] + dateNow2[5] + dateNow2[6] + dateNow2[7] + dateNow2[8] + dateNow2[9];
          var timePassed: any = dateNow3 - resDate3

          if(Number(timePassed) < 300){
            this.apiService.checkUsername(res.email).subscribe(
              (data: any) => {
                if(data == ''){
                  this.registerMode = true;
                  this.authForm.patchValue({
                    email: res.email
                  })
                }
              },
              error => console.log(error)
            );
          }
        }
      },
      error => console.log(error)
    );
  }
  saveForm() {
    if(this.authForm.invalid){
      // this.failed = true;
    } else {
      if (!this.registerMode) {
        this.loginUser();
      } else {
        this.apiService.registerUser(this.authForm.value.email, this.authForm.value.email, this.authForm.value.password).subscribe(
          (result: any) => {
            this.apiService.newUser(result.id, this.authForm.value.email, this.authForm.value.id_number).subscribe(
              () => {        
                this.apiService.registerWithRefCode(this.authForm.value.ref_code, this.authForm.value.email).subscribe(
                  (result: any) => {
                    this.apiService.finishedRegistrationAfterReferral(result[0].id, 1).subscribe(
                      () => {

                      },
                      error => console.log(error)
                    );
                  },
                  error => console.log(error)
                );
                this.loginUser();
              },
              error => console.log(error)
            );
            this.loginUser();
          },
          error => {            
            this.apiService.checkIfUserExists(this.authForm.value.email).subscribe(
              (result: any) => {
                if(result.length > 0){
                  this.username_exists = true;
                }
              },
              error => console.log(error)
            );
          }
        );
      }
    }
  }
  loginUser() {
    this.password_error = false;
    this.username_error = false;
    this.failed = false;
    this.username_exists = false;
    this.apiService.loginUser(this.authForm.value.email, this.authForm.value.password).subscribe(
      (result: any) => {
        this.cookieService.set('bpc-token', result.token);
        this.cookieService.set('bpc-id', result.id);
        this.cookieService.set('bpc-email', this.authForm.value.email);
        if(this.authForm.value.email == 'test@test.com'){
          this.cookieService.set('bpc-admin', '1');
        }
        this.apiService.checkConsent(80, this.authForm.value.email).subscribe(
          (res: any) => {
            if(res.length > 0){
              this.cookieService.set('bpc-consent', '1');
            } else {
              this.cookieService.set('bpc-consent', '0');
            }
          },
          err => console.log(err)
        );
        this.apiService.getMyRefCode().subscribe(
          (res: any) => {
            const code = res[0];
            this.cookieService.set('bpc-ref', code.ref_code);
            if(isNaN(code.ref_code[0])){
              this.cookieService.set('bpc-reseller', '1');
            }
          },
          error => console.log(error)
        );
        this.router.navigate(['/portal']);
      },
      error => {        
        this.apiService.checkIfUserExists(this.authForm.value.email).subscribe(
          (result: any) => {
            if(result.length > 0){
              this.username_exists = true;
            }
          },
          error => console.log(error)
        );
        if(error.error.error){
          this.failed = true;
        }
        if(error.error.username){
          this.username_error = true;
        }
        if(error.error.password){
          this.password_error = true;
        }
        if(this.authForm.value.email == '' && this.authForm.value.password == ''){
          this.password_error = false;
          this.username_error = false;
          this.failed = true;
        }

        this.cookieService.delete('bpc-token');
        this.cookieService.delete('bpc-id');
        this.cookieService.delete('bpc-email');
      }
    );
  }
  forgotPassword() {
    if(this.authForm.value.email){
      this.apiService.forgotPassword(this.authForm.value.email).subscribe(
        (result: any) => {
          this.email_not_set = false;
          this.show_sent_email = true;
        },
        error => console.log(error)
      );
    } else {
      this.show_sent_email = false;
      this.email_not_set = true;
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}