import { ApiService } from 'src/app/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-stats',
  templateUrl: './show-stats.component.html',
  styleUrls: ['./show-stats.component.scss']
})
export class ShowStatsComponent implements OnInit {
  device = 0;
  active_plugins: any;
  active_pro_users: any;
  active_consents: any;
  active_requests: any;

  constructor( private apiService: ApiService) { }

  ngOnInit(): void {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.device = 1;
    }
    this.apiService.getStats().subscribe(
      (res: any) => {
        this.active_plugins = Number(res.active_plugins);
        this.active_pro_users = Number(res.active_pro_users);
        this.active_consents = Number(res.active_consents);
        this.active_requests = Number(res.active_requests);
      },
      error => console.log(error)
    );
  }

}
