<div [ngClass]="this.device == 1 ? 'layout_mobile' : ''">
    <button type="button" class="btn btn-danger" (click)="goBack()" *ngIf="!domain">Back</button>
    <h2 style="text-align:center;">Select which domains to pay</h2>
    <p style="text-align:center;">We noticed you have a few unpaid domains, select domains which you'd like to pay</p>
    <div class="selectAll">
        <label for="selectInput">Select All</label>
        <input class="selectInput" id="selectInput" name="selectInput" type="checkbox" (change)="checkAll()" [checked]="all_selected">
    </div>

    <div *ngFor="let domain of renew_domains "  class="domain-item">
        <span></span>
        <h4>{{ domain.company_name }}</h4>
        <span class="selectDomainToPay">
            <input type="checkbox" (change)="onChange(domain.id)" [checked]="toggleCheckBox(domain.id)" [value]="domain.id">
        </span>
    </div><br><br>

    <button type="submit" class="btn btn-danger submitButton" (click)="goPay()" [disabled]="domainArray.length == 0">
        Pay
    </button>
</div>