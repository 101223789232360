<div [ngClass]="this.device == 1 ? 'layout_mobile' : ''">
    <button type="button" class="btn btn-danger" (click)="goBack()" *ngIf="!domain">Back</button>
    <h1 style="text-align:center;">THIRD PARTIES</h1>
    <div class="alert alert-warning centerText" id="failed">
        Select all the service providers by clicking on the Premium Providers or by selecting  multiple providers from the dropdown in their relevant categories. If you do not see your providers, please select "ADD CUSTOM ** PROVIDER" from the dropdown menu in the relevant category to submit their information.
    </div>    
    <div id="manage-domain">
        <!-- HOSTING START -->
        <h3 [ngClass]="this.device == 1 ? 'centerText' : ''">Hosting Companies</h3><br><br>
        <div [ngClass]="this.device == 1 ? 'third-party-wrap_mobile' : 'third-party-wrap'">
            <div *ngFor="let thirdParty of thirdPartyHosts">
                <div (click)="addHost(thirdParty.id)" class="third-party-item" [ngClass]="this.thirdParties.includes(thirdParty.id) ? 'checkedClass' : 'uncheckedClass'">
                    <img class="third-party-image" width="50" height="50" src="{{ thirdParty.company_logo }}">
                    <h5 class="third-party-name">{{ thirdParty.trading_name | uppercase}}</h5>
                </div>
            </div>
        </div><br>
        <div class="multiselect_wrap">
            <div class="multiselect" *ngIf="allHosts.length > 0">
                <ng-multiselect-dropdown
                [placeholder]="'OTHER HOSTING PROVIDERS'"
                [settings]="dropdownSettings"
                [data]="hostList"
                [(ngModel)]="selectedHostingProvider"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
                >
                </ng-multiselect-dropdown>
            </div>
            <button class="btn btn-danger" style="margin-left: 20px;" (click)="open(mymodal, 1)">ADD CUSTOM HOSTING PROVIDER</button>
        </div>
        <!-- HOSTING END -->
        <br>
        <!-- PAYMENT START -->

        <h3 [ngClass]="this.device == 1 ? 'centerText' : ''">Payment Providers</h3><br><br>
        <div [ngClass]="this.device == 1 ? 'third-party-wrap_mobile' : 'third-party-wrap'">
            <div *ngFor="let thirdParty of thirdPartyPayment">
                <div (click)="addHost(thirdParty.id)" class="third-party-item" [ngClass]="this.thirdParties.includes(thirdParty.id) ? 'checkedClass' : 'uncheckedClass'">
                    <img class="third-party-image" width="50" height="50" src="{{ thirdParty.company_logo }}">
                    <h5 class="third-party-name">{{ thirdParty.trading_name | uppercase}}</h5>
                </div>
            </div>
        </div><br>
        <div class="multiselect_wrap">
            <div class="multiselect" *ngIf="allPaymentProviders.length > 0">
                <ng-multiselect-dropdown
                [placeholder]="'OTHER PAYMENT PROVIDERS'"
                [settings]="dropdownSettings"
                [data]="paymentList"
                [(ngModel)]="selectedPaymentProvider"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
                >
                </ng-multiselect-dropdown>
            </div>
            <button class="btn btn-danger" style="margin-left: 20px;" (click)="open(mymodal, 2)">ADD CUSTOM PAYMENT PROVIDER</button>
        </div>
        <!-- PAYMENT END -->
        <br>
        <!-- DELIVERY START -->

        <h3 [ngClass]="this.device == 1 ? 'centerText' : ''">Delivery Services</h3><br><br>
        <div [ngClass]="this.device == 1 ? 'third-party-wrap_mobile' : 'third-party-wrap'">
            <div *ngFor="let thirdParty of thirdPartyDelivery">
                <div (click)="addHost(thirdParty.id)" class="third-party-item" [ngClass]="this.thirdParties.includes(thirdParty.id) ? 'checkedClass' : 'uncheckedClass'">
                    <img class="third-party-image" width="50" height="50" src="{{ thirdParty.company_logo }}">
                    <h5 class="third-party-name">{{ thirdParty.trading_name | uppercase}}</h5>
                </div>
            </div>
            </div>
        </div><br>
        <div class="multiselect_wrap">
            <div class="multiselect" *ngIf="allDeliveryServices.length > 0">
                <ng-multiselect-dropdown
                [placeholder]="'OTHER DELIVERY SERVICES'"
                [settings]="dropdownSettings"
                [data]="deliveryList"
                [(ngModel)]="selectedDeliveryServices"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
                >
                </ng-multiselect-dropdown>
            </div>
            <button class="btn btn-danger" style="margin-left: 20px;" (click)="open(mymodal, 3)">ADD CUSTOM DELIVERY SERVICE</button>
        </div>
        <!-- DELIVERY END -->
        <br>
        <!-- IT START -->

        <h3 [ngClass]="this.device == 1 ? 'centerText' : ''">IT Services</h3><br><br>
        <div [ngClass]="this.device == 1 ? 'third-party-wrap_mobile' : 'third-party-wrap'">
            <div *ngFor="let thirdParty of thirdPartyIT">
                <div *ngIf="thirdParty.id != 1" (click)="addHost(thirdParty.id)" class="third-party-item" [ngClass]="this.thirdParties.includes(thirdParty.id) ? 'checkedClass' : 'uncheckedClass'">
                    <img class="third-party-image" width="50" height="50" src="{{ thirdParty.company_logo }}">
                    <h5 class="third-party-name">{{ thirdParty.trading_name | uppercase}}</h5>
                </div>
                <div *ngIf="thirdParty.id == 1" class="third-party-item checkedClass">
                    <img class="third-party-image" width="50" height="50" src="{{ thirdParty.company_logo }}">
                    <h5 class="third-party-name">{{ thirdParty.trading_name | uppercase}}</h5>
                </div>
            </div>
        </div><br>
        <div class="multiselect_wrap">
            <div class="multiselect" *ngIf="allITServices.length > 0">
                <ng-multiselect-dropdown
                [placeholder]="'OTHER IT SERVICES'"
                [settings]="dropdownSettings"
                [data]="itList"
                [(ngModel)]="selectedITServices"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
                >
                </ng-multiselect-dropdown>
            </div>
            <button class="btn btn-danger" style="margin-left: 20px;" (click)="open(mymodal, 4)">ADD CUSTOM IT SERVICE</button>
        </div>
        <!-- IT END -->
        <br>
        <!-- OTHER START -->
        <h3 [ngClass]="this.device == 1 ? 'centerText' : ''">Other Services</h3><br><br>
        <div [ngClass]="this.device == 1 ? 'third-party-wrap_mobile' : 'third-party-wrap'">
            <div *ngFor="let thirdParty of thirdPartyOther">
                <div *ngIf="thirdParty.id != 1" (click)="addHost(thirdParty.id)" class="third-party-item" [ngClass]="this.thirdParties.includes(thirdParty.id) ? 'checkedClass' : 'uncheckedClass'">
                    <img class="third-party-image" width="50" height="50" src="{{ thirdParty.company_logo }}">
                    <h5 class="third-party-name">{{ thirdParty.trading_name | uppercase}}</h5>
                </div>
                <div *ngIf="thirdParty.id == 1" class="third-party-item checkedClass">
                    <img class="third-party-image" width="50" height="50" src="{{ thirdParty.company_logo }}">
                    <h5 class="third-party-name">{{ thirdParty.trading_name | uppercase}}</h5>
                </div>
            </div>
        </div><br>
        <div class="multiselect_wrap">
            <div class="multiselect" *ngIf="allOtherServices.length > 0">
                <ng-multiselect-dropdown
                [placeholder]="'OTHER SERVICES'"
                [settings]="dropdownSettings"
                [data]="otherList"
                [(ngModel)]="selectedOtherServices"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
                >
                </ng-multiselect-dropdown>
            </div>
            <button class="btn btn-danger" style="margin-left: 20px;" (click)="open(mymodal, 5)">ADD CUSTOM OTHER SERVICE</button>
        </div>
        <!-- OTHER END -->
        <br>
        <div class="submitButton" style="margin-bottom: 150px;margin-top: 30px;">
            <button type="button" class="btn btn-danger mr20bbtn" (click)="goBack()" *ngIf="!domain">Back</button>
            
            <button type="button" class="btn btn-danger snbtnrbgd" (click)="goNext()" *ngIf="!showSaveButton && external == 0" ng-disabled="domainForm.$invalid">Next</button>
            <button type="button" class="btn btn-danger snbtnrbgd" (click)="goSave()" *ngIf="showSaveButton || external == 1" ng-disabled="domainForm.$invalid">Save</button>
        </div>
        <br>
        <br>
        <br>
        <br>
    <ng-template #mymodal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Other Third Party</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); closedModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="thirdForm" style="background: white;" class="addOtherForm">
                <div>
                    <label>Full registered name</label><br>
                    <input type="text" formControlName="company_name" class="roundedInputs" /><br>
                    <div *ngIf="thirdForm.value.company_name == '' && errors == 1" class="alert alert-danger">
                        Company name is required.
                    </div>
                </div>
                <div>
                    <label>Trading name of Body</label><br>
                    <input type="text" formControlName="trading_name" class="roundedInputs"/><br>
                    <div *ngIf="thirdForm.value.trading_name == '' && errors == 1" class="alert alert-danger">
                        Trading name is required.
                    </div>
                </div>            
                <div>
                    <label>Company Domain</label><br>
                    <input type="text" formControlName="company_domain" class="roundedInputs"/><br>
                    <div *ngIf="thirdForm.value.company_domain == '' && errors == 1"
                        class="alert alert-danger">
                            Company domain is required.
                    </div>
                    <div *ngIf="nohttp == 1" class="alert alert-danger">
                        The domain needs to include http/https.
                    </div>
                </div>
                <div>
                    <label>Company Reg</label><br>
                    <input type="text" formControlName="company_reg" class="roundedInputs"/><br>
                    <div *ngIf="thirdForm.value.company_reg == '' && errors == 1" class="alert alert-danger">
                        Company registration is required.
                    </div>
                </div>
                <div>
                    <label>Company Logo</label><br>
                    <img alt="" id="img" width="200" src={{thirdForm.value.company_logo}}>
                    <input type="file" name="company_logo" (change)="onUploadChange($event)" accept=".png, .jpg, .jpeg, .pdf"  />
                    <!-- <div *ngIf="thirdForm.value.company_logo == '' && errors == 1" class="alert alert-danger">
                        Company logo is required.
                    </div> -->
                </div>
                <div>
                    <label>Tax Number</label><br>
                    <input type="text" formControlName="tax_number" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Office Number</label><br>
                    <input type="text" formControlName="office_number" class="roundedInputs"/><br>
                    <div *ngIf="thirdForm.value.office_number == '' && errors == 1" class="alert alert-danger">
                        Trading name is required.
                    </div>
                </div>
                <div>
                    <label>Email</label><br>
                    <input type="text" formControlName="direct_email" class="roundedInputs"/><br>
                    <div *ngIf="thirdForm.value.direct_email == '' && errors == 1" class="alert alert-danger">
                        Email is required.
                    </div>
                </div>
                <div>
                    <h5>Type Of Body</h5>
                    <div class="type_of_body_wrap">
                        <div class="type_of_body">
                            Public Body
                            <input id="public_body" class="radioButtons" type="radio" value="1" name="type_of_body" formControlName="type_of_body">
                        </div>
                        <div class="type_of_body">
                            Private Body
                            <input type="radio" id="private_body" class="radioButtons" name="type_of_body" value="2" formControlName="type_of_body">
                        </div>
                    </div>
                </div>
                <div>
                    <h5>Entity Type</h5>
                    <div class="entity_type_wrap">
                        <div class="entity_type">
                            Sole Proprietorship
                            <input id="sole_proprietorship" class="radioButtons" type="radio" value="1" name="entity_type" formControlName="entity_type" >
                        </div>
                        <div class="entity_type">
                            Partnership
                            <input id="partnership" class="radioButtons" type="radio" value="2" name="entity_type" formControlName="entity_type">    
                        </div>
                        <div class="entity_type">
                            (PTY) Ltd
                            <input id="pty_ltd" class="radioButtons" type="radio" value="3" name="entity_type" formControlName="entity_type">
                        </div>
                        <div class="entity_type">
                            Public Company
                            <input id="public_company" class="radioButtons" type="radio" value="4" name="entity_type" formControlName="entity_type">
                        </div>
                        <div class="entity_type">
                            Non-Profit
                            <input id="non_profit" class="radioButtons" type="radio" value="5" name="entity_type" formControlName="entity_type">
                        </div>
                    </div>
                    <br>
                </div>
                
                <div *ngIf="is_other">
                    <label>Service Type/Industry</label><br>
                    <input type="text" formControlName="category" class="roundedInputs"/><br>
                </div>
                <div class="spanWidth">
                    <h3>Physical Address</h3>
                </div>
                <div>
                    <label>Street Number</label><br>
                    <input type="text" formControlName="ph_street_number" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Street Name</label><br>
                    <input type="text" formControlName="ph_street_name" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Suburb</label><br>
                    <input type="text" formControlName="ph_suburb" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Town/City</label><br>
                    <input type="text" formControlName="ph_town_city" class="roundedInputs"/><br>
                </div>
                <div>
                    <label>Province</label><br>
                    <input type="text" formControlName="ph_province" class="roundedInputs"/><br>
                </div>
                <div> 
                    <label>Postal Code</label><br>
                    <input type="text" formControlName="ph_postal_code" class="roundedInputs"/><br>
                </div>
                <div class="spanWidth">
                    <label>Postal Address Same As Physical Address</label>
                    <input type="checkbox" formControlName="postal_same_as_physical"/><br>
                </div>
                <div class="spanWidth" *ngIf="!this.thirdForm.value.postal_same_as_physical">
                    <h3>Postal Address</h3>
                </div>
                <div *ngIf="!this.thirdForm.value.postal_same_as_physical">
                    <label>Street Number</label><br>
                    <input type="text" formControlName="po_street_number" class="roundedInputs"/><br>
                </div>
                <div *ngIf="!this.thirdForm.value.postal_same_as_physical">
                    <label>Street Name</label><br>
                    <input type="text" formControlName="po_street_name" class="roundedInputs"/><br>
                </div>
                <div *ngIf="!this.thirdForm.value.postal_same_as_physical">
                    <label>Suburb</label><br>
                    <input type="text" formControlName="po_suburb" class="roundedInputs"/><br>
                </div>
                <div *ngIf="!this.thirdForm.value.postal_same_as_physical">
                    <label>Town/City</label><br>
                    <input type="text" formControlName="po_town_city" class="roundedInputs"/><br>
                </div>
                <div *ngIf="!this.thirdForm.value.postal_same_as_physical">
                    <label>Province</label><br>
                    <input type="text" formControlName="po_province" class="roundedInputs"/><br>
                </div>
                <div *ngIf="!this.thirdForm.value.postal_same_as_physical">
                    <label>Postal Code</label><br>
                    <input type="text" formControlName="po_postal_code" class="roundedInputs"/><br>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger submitButton" (click)="modal.close('Save click'); saveForm()">Save</button>
            <!-- <button type="button" class="btn btn-danger submitButton" (click)="saveForm()">Next</button> -->
        </div>
    </ng-template>
</div>