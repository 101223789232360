<div class="layout">
    <div *ngIf="this.selectedDomain == null && this.selectedConsent == null && this.selectedThird == null && this.needsToPay == null && this.needsToPayMoreShow == null && this.selectedKeys == null && this.selectedConsentForm == null">
        <app-domains [domains]="domains" [managed_domains]="managed_domains" [deputy_domains]="deputy_domains" (selectConsent)="selectConsent($event)" (selectThird)="selectThird($event)" (selectPay)="selectPay($event)" (quickDomainCreated)="quickDomainCreated($event)" (selectDomain)="selectDomain($event)" (selectKeys)="selectKeys($event)" (selectConsentForm)="selectConsentForm($event)" (createDomain)="createDomain()"></app-domains>
    </div>
    <app-manage *ngIf="this.selectedDomain != null" [domain]="this.selectedDomain" (domainCreated)="domainCreated($event)" (domainUpdated)="domainUpdated($event)" (goBackEm)="goBackEm($event)" (goNextEm)="goNextEm($event)"></app-manage>
    <app-third-parties *ngIf="this.selectedThird != null" [domain]="this.selectedThird" [selected]="this.isSelected" (goBackEm)="goBackEm($event)" (goNextThirdEm)="goNextThirdEm($event)"></app-third-parties>
    <app-consent *ngIf="this.selectedConsent != null" [domain]="this.selectedConsent" [selected]="this.isSelected" (goBackEm)="goBackEm($event)" (goPay)="goPay($event)"></app-consent>

    <app-payment *ngIf="this.needsToPay != null" [domain]="this.needsToPay" [domainsWanted]="this.domainsWanted" (goBackEm)="goBackEm($event)"></app-payment>
    <app-paymore *ngIf="this.needsToPayMoreShow != null" [domain]="this.needsToPayMoreShow" (goBackEm)="goBackEm($event)" (goPayDomains)="goPayDomains($event)"></app-paymore>

    <app-company-keys *ngIf="this.selectedKeys != null" [domain]="this.selectedKeys" (goBackEm)="goBackEm($event)"></app-company-keys>
    <app-consent-form *ngIf="this.selectedConsentForm != null"  [domain]="this.selectedConsentForm" (goBackEm)="goBackEm($event)" ></app-consent-form>
</div>
